const getTimeDifference = (timeStart, timeEnd) => {
  const timeStartSplit = timeStart.split(":");
  const timeEndSplit = timeEnd.split(":");
  timeStartSplit[0] = parseInt(timeStartSplit[0]);
  timeStartSplit[1] = parseInt(timeStartSplit[1]);
  timeEndSplit[0] = parseInt(timeEndSplit[0]);
  timeEndSplit[1] = parseInt(timeEndSplit[1]);

  const calculatedTimeStart = timeStartSplit[0] + (timeStartSplit[1] / 60);
  const calculatedTimeEnd = timeEndSplit[0] + (timeEndSplit[1] / 60);

  return calculatedTimeEnd - calculatedTimeStart;
};

export default getTimeDifference;
