import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { firebase } from "~/src/api";

import {
  FormLayout,
  Button,
  Link,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  description: yup.string(),
});

function TeacherFormDescription(props) {
  const {
    teacher, back, forward,
  } = props;

  return (
    <Formik
      initialValues={{
        description: teacher.description || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const { description } = values;

        firebase.teacher(teacher.id).set(
          {
            description,
          },
          { merge: true },
        ).then(() => {
          Router.push(forward.route, forward.routeAs);
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormLayout>
            <Field
              label="Description"
              name="description"
              component={TextField}
              multiline
              autoFocus
            />
            <FormLayout.Footer>
              {back && back.route && (
                <Link href={back.route} as={back.routeAs} unstyled>
                  <Button>{back.label}</Button>
                </Link>
              )}

              <Button
                color="primary"
                loading={isSubmitting}
                type="submit"
              >
                {forward.label}
              </Button>
            </FormLayout.Footer>
          </FormLayout>
        </Form>
      )}
    </Formik>
  );
}

export default TeacherFormDescription;
