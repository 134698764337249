import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import * as yup from "yup";

import {
  FormControl,
  FormGroup,
} from "@material-ui/core";
import SKILLS from "~/src/constants/skills";

import { firebase } from "~/src/api";

import {
  FormLayout,
  Button,
  Link,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
});

function FormStudioStyles(props) {
  const {
    studio, back, forward,
  } = props;

  const stylesArrayToBooleanObject = {};
  for (const skill of SKILLS) {
    stylesArrayToBooleanObject[skill.name] = !!(studio.styles && studio.styles.includes(skill.name));
  }

  return (
    <Formik
      initialValues={{
        styles: stylesArrayToBooleanObject,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Convert boolean object to array
        const booleanObjectToStylesArray = [];
        for (const skill of SKILLS) {
          if (values.styles[skill.name]) {
            booleanObjectToStylesArray.push(skill.name);
          }
        }

        firebase.studio(studio.id).update({
          styles: booleanObjectToStylesArray,
        }).then(() => {
          Router.push(forward.route, forward.routeAs);
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormLayout>
            <FormControl>
              <FormGroup row>
                {SKILLS.map((SKILL, index) => (
                  <Field
                    Label={{ label: SKILL.labelEmoji }}
                    name={`styles.${SKILL.name}`}
                    component={CheckboxWithLabel}
                    key={index}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormLayout.Footer>
              {back && back.route && (
                <Link href={back.route} unstyled>
                  <Button>{back.label}</Button>
                </Link>
              )}

              <Button
                color="primary"
                loading={isSubmitting}
                type="submit"
              >
                {forward.label}
              </Button>
            </FormLayout.Footer>
          </FormLayout>
        </Form>
      )}
    </Formik>
  );
}

export default FormStudioStyles;
