import React from "react";
import {
  InputLabel,
  FormControl,
  FormHelperText,
  Select as MuiSelect,
  MenuItem,
} from "@material-ui/core";

const InputSelect = (props) => {
  const {
    field, form, label, options, ...other
  } = props;
  const currentError = form.errors[field.name];

  return (
    <FormControl error={Boolean(currentError)} variant="outlined">
      <InputLabel>{label}</InputLabel>

      <MuiSelect
        displayEmpty
        native={false}
        variant="outlined"
        name={field.name}
        value={field.value}
        error={Boolean(currentError)}
        disabled={form.isSubmitting}
        onError={(error) => {
          // handle as a side effect
          if (error !== currentError) {
            form.setFieldError(field.name, error);
          }
        }}
        // if you are using custom validation schema you probably want to pass `true` as third argument
        onChange={(event) => form.setFieldValue(field.name, event.target.value, true)}
        {...other}
      >
        {options.map((option) => (
          <MenuItem value={option.value || ""} key={option.value || "blank"}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>

      {currentError && <FormHelperText>{currentError}</FormHelperText>}
    </FormControl>
  );
};

export default InputSelect;
