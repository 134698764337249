import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import {
  Typography,
  Box,
} from "@material-ui/core";

import {
  Avatar,
  Flex,
} from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  media: {
    display: "flex",
  },
  link: {
    position: "relative",
    display: "flex",
    color: "inherit",
    textDecoration: "none",
    zIndex: 1,
    "&:hover": {
      "& $title": {
        textDecoration: "underline",
      },
      "& $linkBackground": {
        backgroundColor: theme.palette.action.selected,
      },
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  linkBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.hover,
    zIndex: -1,
  },
}));

function Media({
  title,
  subtitle,
  image,
  link,
  onClick,
  small,
}) {
  const classes = useStyles();

  const titleMarkup = link ? (
    <Typography color="primary" className={classes.title} noWrap>{title}</Typography>
  ) : (
    <Typography className={classes.title} noWrap>{title}</Typography>
  );

  const avatarMarkup = (
    <Avatar src={image} size={small ? "small" : "medium"} />
  );

  const markup = (
    <Flex align="start">
      {avatarMarkup}

      <Flex.Item fill>

      <Box pl={1}>
        {titleMarkup}
        <Typography color="textSecondary">{subtitle}</Typography>
      </Box>
      </Flex.Item>
    </Flex>
  );

  return link ? (
    <Link to={link} className={classes.link} onClick={onClick}>
      <Box pl={subtitle ? 1 : 0.5} pr={1} py={0.5}>
        {markup}
      </Box>
      <div className={classes.linkBackground} />
    </Link>
  ) : (
    <div>
      {markup}
    </div>
  );
}

Media.defaultProps = {
  title: null,
  subtitle: null,
  image: null,
  link: null,
};

export default Media;
