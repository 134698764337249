import React from "react";
import { getThumbnailURL } from "~/src/api";
import { ListItem } from "~/src/components/global";
import { StudioDisplayCategories } from "~/src/components/studios";

function ListItemStudio(props) {
  const {
    studio,
    onClick,
    disabled,
    ListItemOverrides,
    dataCy,
  } = props;

  if (!studio) return null;

  return (
    <ListItem
      imageSrc={getThumbnailURL({ url: studio.photo })}
      primary={studio.name}
      secondary={<StudioDisplayCategories categories={studio.styles} minimal />}
      href="/studios/[id]"
      as={`/studios/${studio.id}`}
      onClick={onClick}
      disabled={disabled}
      ListItemOverrides={ListItemOverrides}
      dataCy={dataCy}
    />
  );
}

export default ListItemStudio;
