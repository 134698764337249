import React from "react";
import { Card, Divider } from "~/src/components/global";
import { InputStudioAutocomplete } from "~/src/components/studios";

import LocationInput from "./LocationInput";

export default function StudioCard({
  values, studios, setFieldValue, isSubmitting,
}) {
  const selectedStudio = studios.find((_studio) => _studio.id === values.studio);

  // Set the email field, if we haven't set it to not email type.
  const handleStudioChange = (value) => {
    if (value && value.value && values.source.type === "email") {
      const studio = studios.find((_studio) => _studio.id === value.value);
      if (studio && studio.email) {
        setFieldValue("source.link", studio.email);
      }
    }
  };

  return (
    <Card title="Studio">
      <InputStudioAutocomplete values={values} studios={studios} setFieldValue={setFieldValue} isSubmitting={isSubmitting} onChange={handleStudioChange} />

      {selectedStudio && (
        <>
          <Divider />

          <LocationInput studios={studios} values={values} isSubmitting={isSubmitting} />
        </>
      )}
    </Card>
  );
}
