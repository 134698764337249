import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  Avatar, Flex, Dialog, Link,
} from "~/src/components/global";
import { getMonthAndYearRange } from "~/src/components/shared";
import { QualificationIcon } from "~/src/icons";
import { getThumbnailURL } from "~/src/api";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "block",
    marginTop: 2,
  },
  photoWrapper: {
    margin: theme.spacing(-2),
  },
  photo: {
    display: "block",
    width: "100%",
  },
}));

function TeacherDisplayQualification({ qualification }) {
  if (!qualification) return null;

  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const dateMarkup = getMonthAndYearRange({ ...qualification });
  const secondaryMarkup = qualification.photo ? (
    <>
      {dateMarkup}

      <div className={classes.link}>
        <Link onClick={toggleModal}>
          <Flex spaceChildren="tight" justify="start">
            <Avatar
              alt={qualification.name}
              src={getThumbnailURL({
                url: qualification.photo,
                size: 128,
              })}
              variant="rounded"
              size="small"
              outlined
            />
            <div>View certificate</div>
          </Flex>
        </Link>
      </div>
    </>
  ) : dateMarkup;

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <QualificationIcon />
        </ListItemIcon>
        <ListItemText
          primary={qualification.name}
          secondary={secondaryMarkup}
          secondaryTypographyProps={{
            component: "div",
          }}
        />
      </ListItem>

      {qualification.photo && (
        <Dialog
          onClose={toggleModal}
          id="view-qualification"
          open={modalOpen}
          title={qualification.name}
          subtitle={dateMarkup}
          maxWidth="sm"
        >
          <div className={classes.photoWrapper}>
            <img className={classes.photo} src={qualification.photo} alt={qualification.name} />
          </div>
        </Dialog>
      )}
    </>
  );
}

export default TeacherDisplayQualification;
