import React from "react";
import { Button, Link } from "~/src/components/global";

function ClaimButton(props) {
  const {
    claim, job,
  } = props;

  if (!claim || !job || !job.source || job.source.type !== "email") return null;

  const claimLink = `${process.env.URL_PREFIX}/jobs/${job.id}/claim/${claim}`;

  const message = `Hi ${job.source.firstname || "there"}!\n
I’m the founder of Bolstr, a free new platform connecting studios and gyms with health and fitness instructors. I’ve cross-posted your job listing to Bolstr, and it looks like you’ve received some applications already! \n
I don’t want you to get spammed with applications, so once you’ve found your ideal candidate, you can mark the job as “filled” using the following link:
${claimLink}\n
You can also post job ads yourself at any time, for free, using the following link: https://bolstr.app/jobs/add\n
Cheers,
Morgan
Founder of Bolstr`;

  const subject = `Your job “${job.name}” has been cross-posted to Bolstr!`;
  const mailtoLink = `mailto:${job.source.link}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;

  return (
    <Link href={mailtoLink} unstyled external>
      <Button color="primary">
        Send Claim Email
      </Button>
    </Link>
  );
}

export default ClaimButton;
