import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {
  Emoji,
  Flex,
  Link,
  Spacing,
  Strong,
  DescriptionShort,
} from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: "pointer",
    display: "block",
    height: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  card: {
    display: "flex",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadiusLarge,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    height: "100%",
    transition: "box-shadow 0.25s",
    "&:hover": {
      boxShadow: theme.shadows[2],
      "& $title": {
        textDecoration: "underline",
      },
    },
  },
  imageDesktop: {
    flexShrink: 0,
    marginRight: theme.spacing(2),
    display: "none",
  },
  body: {
    width: 0,
    flexGrow: 1,
  },
  flex: {
    display: "flex",
  },
  heading: {
    flexGrow: 1,
  },
  imageMobile: {
    flexShrink: 0,
    marginLeft: theme.spacing(2),
  },
  title: {},
  teacher: {
    "& $heading": {
      order: 2,
    },
    "& $imageMobile": {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      "& $imageMobile": {
        display: "none",
      },
      "& $imageDesktop": {
        display: "block",
      },
    },
  },
}));

function GridItem(props) {
  const {
    title, secondary, tertiary, badge, description, location, image, teacher, onClick, href, as, GridItemOverrides, dataCy,
  } = props;
  const classes = useStyles();

  const cardClassnames = classNames(
    classes.card,
    teacher && classes.teacher,
  );

  const handleClick = () => {
    onClick();
  };

  const descriptionMarkup = description ? (
    <>
      <Spacing extraTight />
      <DescriptionShort text={description} />
    </>
  ) : null;

  const locationMarkup = location ? (
    <>
      <Spacing extraTight />
      <Typography noWrap>
        <Emoji>🌏</Emoji>
        {location}
      </Typography>
    </>
  ) : null;

  const badgeMarkup = GridItemOverrides && GridItemOverrides.badge ? GridItemOverrides.badge : badge;


  const markup = (
    <div className={cardClassnames}>
      {image && (
        <div className={classes.imageDesktop}>
          {image}
        </div>
      )}
      <div className={classes.body}>
        <div className={classes.flex}>
          <div className={classes.heading}>
            <Flex justify="start" spaceChildren="tight">
              <Typography className={classes.title} variant="h6" color="primary">
                <Strong>{title}</Strong>
              </Typography>
              {badgeMarkup}
            </Flex>
            {secondary && (
              <Typography>{secondary}</Typography>
            )}
            <Typography color="textSecondary">{GridItemOverrides && GridItemOverrides.tertiary ? GridItemOverrides.tertiary : tertiary}</Typography>
          </div>
          {image && (
            <div className={classes.imageMobile}>
              {image}
            </div>
          )}
        </div>

        {descriptionMarkup}
        {locationMarkup}
      </div>
    </div>
  );

  return onClick ? (
    <a role="button" onClick={handleClick} className={classes.button} tabIndex="0" data-cy={dataCy}>
      {markup}
    </a>
  ) : (
    <Link href={GridItemOverrides && GridItemOverrides.href ? GridItemOverrides.href : href} as={GridItemOverrides && GridItemOverrides.as ? GridItemOverrides.as : as} unstyled target="_blank" rel="noopener">
      {markup}
    </Link>
  );
}

export default GridItem;
