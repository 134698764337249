import React from "react";
import Router from "next/router";
import { Grid } from "@material-ui/core";
import { getTeachers, getTeacherData } from "~/src/api";
import { Banner, Loading } from "~/src/components/global";
import { Filter, FilterWrapper } from "~/src/components/shared";
import { TeacherGridItem } from "~/src/components/teachers";

import SKILLS from "~/src/constants/skills";
import SAFETY_CHECKS from "~/src/constants/safetyChecks";
import LOCATIONS from "~/src/constants/locations";

const disciplineOptions = SKILLS.map((skill) => ({
  value: skill.name,
  label: skill.label,
  labelEmoji: skill.labelEmoji,
}));

const safetyCheckOptions = SAFETY_CHECKS.map((safetyCheck) => ({
  value: safetyCheck.name,
  label: safetyCheck.label,
  detail: safetyCheck.detail,
}));

const locationOptions = LOCATIONS.map((location) => ({
  value: location.name,
  label: location.label,
}));

class TeacherGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      skillValue: props.skillValue || "",
      safetyCheckValues: props.safetyCheckValues || [],
      locationValue: props.locationValue || "",
      teachers: props.teachers,
    };
  }

  composeURL = () => {
    const { skillValue, safetyCheckValues, locationValue } = this.state;

    const query = [];
    if (skillValue) query.push(`discipline=${skillValue}`);
    if (safetyCheckValues) {
      safetyCheckValues.forEach((safetyCheck) => {
        query.push(`safety_${safetyCheck}=true`);
      });
    }
    if (locationValue) query.push(`location=${locationValue}`);

    return query;
  }

  reloadData = async () => {
    const { pushNewURL } = this.props;
    const { skillValue, safetyCheckValues, locationValue } = this.state;

    if (pushNewURL) {
      Router.push(`/teachers?${this.composeURL().join("&")}`, `/teachers?${this.composeURL().join("&")}`, { shallow: true });
    }

    this.setState({
      loading: true,
    });

    const teachers = await getTeachers({
      location: locationValue,
      discipline: skillValue,
      safetyChecks: safetyCheckValues,
    });

    const promises = teachers.docs.map((teacher) => getTeacherData({ teacher }));

    this.setState({
      teachers: await Promise.all(promises),
      loading: false,
    });
  }

  handleChangeSkill = async (event) => {
    this.setState({
      skillValue: event.target.value,
    },
    function () {
      this.reloadData();
    });
  }

  handleChangeSafetyCheck = (event) => {
    const { safetyCheckValues } = this.state;
    const { value } = event.currentTarget;

    const newValues = [].concat(safetyCheckValues);
    const indexOf = safetyCheckValues.indexOf(value);

    if (indexOf >= 0) {
      newValues.splice(indexOf, 1);
    } else {
      newValues.push(value);
    }

    this.setState({
      safetyCheckValues: newValues,
    },
    function () {
      this.reloadData();
    });
  }

  handleChangeLocation = async (event) => {
    this.setState({
      locationValue: event.target.value,
    },
    function () {
      this.reloadData();
    });
  }

  render() {
    const {
      TeacherGridItemProps, excludeTeachers,
    } = this.props;
    const {
      teachers, loading, skillValue, safetyCheckValues, locationValue,
    } = this.state;

    // Filter out excluded teachers.
    const filteredTeachers = excludeTeachers ? teachers.filter((teacher) => excludeTeachers.indexOf(teacher.id) === -1) : teachers;

    // Put teachers with videos first.
    const sortedTeachers = []
      .concat(filteredTeachers)
      .filter((teacher) => teacher.username)
      .sort((a, b) => {
        if (a.pro && !b.pro) return -1;
        if (b.pro && !a.pro) return 1;
        if (a.video && !b.video) return -1;
        if (b.video && !a.video) return 1;
        if (a.photo && !b.photo) return -1;
        if (b.photo && !a.photo) return 1;
        return 0;
      });

    const markup = loading ? (
      <Loading />
    ) : sortedTeachers && sortedTeachers.length ? (
      <Grid container>
        {sortedTeachers && sortedTeachers.map((teacher) => (
          <Grid item xs={12} md={6} key={teacher.id}>
            <TeacherGridItem
              teacher={teacher}
              {...TeacherGridItemProps}
            />
          </Grid>
        ))}
      </Grid>
    ) : (
      <Banner>No teachers found. Try changing your filters.</Banner>
    );

    return (
      <Grid container>
        <Grid item xs={12} sm={4} md={3}>
          <FilterWrapper>
            <Filter
              value={skillValue}
              options={disciplineOptions}
              onChange={this.handleChangeSkill}
              title="Discipline"
              titlePlural="disciplines"
            />
            <Filter
              value={locationValue}
              options={locationOptions}
              onChange={this.handleChangeLocation}
              title="Location"
              titlePlural="locations"
            />
            <Filter
              value={safetyCheckValues}
              options={safetyCheckOptions}
              onChange={this.handleChangeSafetyCheck}
              title="Filters"
              titlePlural="filters"
              multiple
            />
          </FilterWrapper>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          {markup}
        </Grid>
      </Grid>
    );
  }
}

export default TeacherGrid;
