import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import {
  Button, Dialog, FormLayout, Link, Strong,
} from "~/src/components/global";
import PitchPreview from "./PitchPreview";
import IncompleteProfileBanner from "./IncompleteProfileBanner";

function ApplyViaFacebookPost(props) {
  const {
    selfTeacher,
    pitchUrl,
    textareaRef,
    message,
    modalOpen,
    toggleModal,
    handleChangeMessage,
    job,
  } = props;

  const [emailAddress, setEmailAddress] = React.useState(job.source.link);
  const [subject, setSubject] = React.useState(`Application for ${job.name}`);

  const handleChangeSubject = (event) => {
    setSubject(event.target.value);
  };

  const handleChangeEmailAddress = (event) => {
    setEmailAddress(event.target.value);
  };

  const mailtoLink = `mailto:${job.source.link}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;

  return (
    <Dialog
      onClose={toggleModal}
      id="apply-for-job"
      open={modalOpen}
      title="Apply for Job"
      subtitle={`By sending an email to ${job.source.link}.`}
      maxWidth="md"
    >
      <IncompleteProfileBanner teacher={selfTeacher} />
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography>
            <Strong>Application Preview</Strong>
          </Typography>
          <PitchPreview url={pitchUrl} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormLayout>
            <TextField
              label="Email Address"
              value={emailAddress}
              onChange={handleChangeEmailAddress}
            />
            <TextField
              label="Subject"
              value={subject}
              onChange={handleChangeSubject}
            />
            <TextField
              label="Message"
              value={message}
              onChange={handleChangeMessage}
              inputRef={textareaRef}
              multiline
            />
            <FormLayout.Footer>
              <Link href={mailtoLink} external unstyled>
                <Button color="primary">
                  Send Email...
                </Button>
              </Link>
            </FormLayout.Footer>
          </FormLayout>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ApplyViaFacebookPost;
