import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import InterestIcon from "@material-ui/icons/Mood";
import { Description, Spacing, Divider } from "~/src/components/global";
import {
  TeacherDisplaySafetyChecks, TeacherDisplayQualification, TeacherDisplayLocation, TeacherDisplaySkills, TeacherDisplayExperience,
} from "~/src/components/teachers";

const TeacherDetailBody = ({ teacher }) => {
  const locationMarkup = teacher.location ? (
    <>
      <Divider extraLoose />

      <Typography variant="h5" component="h2">Location</Typography>

      <Spacing tight />

      <TeacherDisplayLocation location={teacher.location} />
    </>
  ) : null;

  const descriptionMarkup = teacher.description && teacher.description.length ? (
    <>
      <Divider extraLoose />

      <Description text={teacher.description} />
    </>
  ) : null;

  const qualificationsMarkup = teacher.qualifications && teacher.qualifications.length ? (
    <>
      <Divider extraLoose />

      <Typography variant="h5" component="h2">Qualifications</Typography>

      <Spacing tight />

      <List>
        {teacher.qualifications.map((qualification) => (
          <TeacherDisplayQualification qualification={qualification} key={qualification.id} />
        ))}
      </List>
    </>
  ) : null;

  const experiencesMarkup = teacher.experiences && teacher.experiences.length ? (
    <>
      <Divider extraLoose />

      <Typography variant="h5" component="h2">Work Experience</Typography>

      <Spacing tight />

      <List>
        {teacher.experiences.map((experience) => (
          <TeacherDisplayExperience experience={experience} key={experience.id} />
        ))}
      </List>
    </>
  ) : null;

  const interestsMarkup = teacher.interests && teacher.interests.length ? (
    <>
      <Divider extraLoose />

      <Typography variant="h5" component="h2">Interests</Typography>

      <Spacing tight />

      <List>
        {teacher.interests.map((interest) => (
          <ListItem key={interest}>
            <ListItemIcon>
              <InterestIcon />
            </ListItemIcon>
            <ListItemText
              primary={interest}
            />
          </ListItem>
        ))}
      </List>
    </>
  ) : null;

  return (
    <>
      <TeacherDisplaySkills teacher={teacher} includeLocation />

      {descriptionMarkup}

      <Divider extraLoose />

      <TeacherDisplaySafetyChecks safetyChecks={teacher.safetyChecks} />

      {qualificationsMarkup}

      {experiencesMarkup}

      {interestsMarkup}

      {locationMarkup}

      <Spacing tight />
    </>
  );
};

export default TeacherDetailBody;
