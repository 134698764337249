import React from "react";
import { Field } from "formik";
import { RadioGroup } from "formik-material-ui";
import { FormControl, FormControlLabel, Radio } from "@material-ui/core";
import { FormLayout, InputLocation } from "~/src/components/global";

function LocationInput(props) {
  const {
    studios, values, isSubmitting,
  } = props;

  const locationOptions = [{
    value: "no",
    label: "Choose location",
  }];

  const studio = studios.find((studio) => studio.id === values.studio);
  studio && studio.locations && studio.locations.forEach((location) => { // eslint-disable-line no-unused-expressions
    locationOptions.push({
      value: location.id,
      label: location.label,
    });
  });

  return (
    <FormLayout>
      {locationOptions.length > 1 && (
        <FormControl>
          <Field name="useStudioLocation" label="Radio Group" component={RadioGroup}>
            {locationOptions.map((option) => (
              <FormControlLabel
                value={option.value}
                control={<Radio disabled={isSubmitting} />}
                label={option.label}
                disabled={isSubmitting}
                key={option.value}
              />
            ))}
          </Field>
        </FormControl>
      )}

      {values.useStudioLocation === "no" && <InputLocation label="Location" disabled={isSubmitting} />}
    </FormLayout>
  );
}

export default LocationInput;
