import React from "react";
import {
  Table, TableBody, TableContainer, Paper,
} from "@material-ui/core";
import Row from "./Row";

export default function AdminTable({ rows }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="admin table">
        <TableBody>
          {rows.map((row, index) => (
            <Row row={row} key={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
