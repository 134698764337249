import {
  format,
} from "date-fns";

import DAYS_OF_THE_WEEK from "~/src/constants/days";

function getDayAndTime(props) {
  const {
    day, date, timeStart, timeEnd, repeats,
  } = props;
  if (!timeStart || !timeEnd) return null;

  let dayText = "blah blah";
  if (repeats) {
    dayText = DAYS_OF_THE_WEEK[day].labelPlural;
  } else {
    dayText = date && date.seconds ? format(new Date(date.seconds * 1000), "EEE, d MMM") : null;
  }

  const timeStartSplit = timeStart.split(":");
  const timeEndSplit = timeEnd.split(":");

  timeStartSplit[0] = parseInt(timeStartSplit[0]);
  timeStartSplit[1] = parseInt(timeStartSplit[1]);
  timeEndSplit[0] = parseInt(timeEndSplit[0]);
  timeEndSplit[1] = parseInt(timeEndSplit[1]);

  let timeStartText = timeStartSplit[0] > 12 ? timeStartSplit[0] % 12 : timeStartSplit[0];

  if (timeStartSplit[1] !== 0) { // If not on the hour
    timeStartText = `${timeStartText}:${timeStartSplit[1]}`;
  }

  let timeEndText = timeEndSplit[0] > 12 ? timeEndSplit[0] % 12 : timeEndSplit[0];
  if (timeEndSplit[1] !== 0) { // If not on the hour
    timeEndText = `${timeEndText}:${timeEndSplit[1]}`;
  }

  if (timeStartSplit[0] < 12 && timeEndSplit[0] >= 12) { // ie. separate am and pm
    timeStartText += "am";
    timeEndText += "pm";
  } else if (timeEndSplit[0] < 12) {
    timeEndText += "am";
  } else {
    timeEndText += "pm";
  }

  return `${dayText} at ${timeStartText}–${timeEndText}`;
}

export default getDayAndTime;
