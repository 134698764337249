import React from "react";
import { List } from "@material-ui/core";
import { TeacherDisplaySafetyCheck } from "~/src/components/teachers";
import SAFETY_CHECKS from "~/src/constants/safetyChecks";

function TeacherDisplaySafetyChecks({ safetyChecks }) {
  if (!safetyChecks || (!safetyChecks.insurance && !safetyChecks.firstAid && !safetyChecks.cpr)) return null;

  const renderSafetyCheck = (SAFETY_CHECK) => {
    const safetyCheckValues = safetyChecks && safetyChecks[SAFETY_CHECK.name];
    const safetyCheck = {
      ...SAFETY_CHECK,
      ...safetyCheckValues,
    };

    return safetyCheckValues && safetyCheckValues.active ? (
      <TeacherDisplaySafetyCheck safetyCheck={safetyCheck} key={SAFETY_CHECK.name} />
    ) : null;
  };

  return (
    <List>
      {SAFETY_CHECKS.map((SAFETY_CHECK) => (
        renderSafetyCheck(SAFETY_CHECK)
      ))}
    </List>
  );
}

export default TeacherDisplaySafetyChecks;
