import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";

import { firebase, createTeacher, uploadPhoto } from "~/src/api";

import {
  Banner,
  FormLayout,
  Button,
  Link,
  InputImageUploader,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  firstname: yup.string().required("You must have a first name."),
  lastname: yup.string().required("You must have a last name."),
  photo: yup.string(),
  username: yup.string().matches(/^[a-z0-9]+$/, { message: "Lowercase letters and numbers only", excludeEmptyString: true }).required("Username is required"),
});

class TeacherFormCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  saveTeacher = async (values) => {
    const { authUser, forward } = this.props;
    const { username, firstname, lastname } = values;

    const photo = values.newPhoto ? await uploadPhoto({
      photo: values.newPhoto,
      subdirectory: "teachers",
      setInfo: this.setInfo,
      setError: this.setError,
    }) : null;

    await createTeacher({
      authUser,
      firstname,
      lastname,
      username,
      photo,
    });

    Router.push(forward.route);
  }

  setInfo = (value) => {
    this.setState({ info: value });
  }

  setError = (value) => {
    this.setState({ error: value });
  }

  checkUsername = async (values, setSubmitting) => {
    const { username } = values;

    const teacher = await firebase.teachers().where("username", "==", username).get();

    if (teacher.docs && teacher.docs.length) {
      this.setState({ error: { message: "That username is taken. Please try a different one." } });
      setSubmitting(false);
    } else {
      this.saveTeacher(values);
    }
  }

  render() {
    const { back, forward } = this.props;

    return (
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          photo: "",
          newPhoto: null,
          username: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          this.setState({ error: null });
          this.checkUsername(values, setSubmitting);
        }}
      >
        {({
          isSubmitting, values, setFieldValue,
        }) => (
          <Form>
            <FormLayout>
              {this.state.info && <Banner status="info">{this.state.info}</Banner>}
              {this.state.error && <Banner status="danger">{this.state.error.message}</Banner>}

              <FormLayout.Group>
                <Field
                  label="First Name"
                  name="firstname"
                  component={TextField}
                  autoFocus
                />
                <Field
                  label="Last Name"
                  name="lastname"
                  component={TextField}
                />
              </FormLayout.Group>

              <Field
                label="Teacher Username"
                name="username"
                component={TextField}
                inputProps={{
                  spellCheck: "false",
                  autoCorrect: "off",
                  autoCapitalize: "none",
                  autocompletetype: "username",
                }}
              />

              <InputImageUploader
                oldImage={values.photo}
                newImage={values.newPhoto}
                setFieldValue={setFieldValue}
                label="Profile Photo"
                name="newPhoto"
                disabled={isSubmitting}
              />

              <FormLayout.Footer>
                {back && back.route && (
                  <Link href={back.route} unstyled>
                    <Button>{back.label}</Button>
                  </Link>
                )}

                <Button
                  color="primary"
                  loading={isSubmitting}
                  type="submit"
                >
                  {forward.label}
                </Button>
              </FormLayout.Footer>
            </FormLayout>
          </Form>
        )}
      </Formik>
    );
  }
}

export default TeacherFormCreate;
