import React from "react";
import {
  ListItem, ListItemAvatar, ListItemText, Typography,
} from "@material-ui/core";
import { Avatar, Link, Strong } from "~/src/components/global";
import { getMonthAndYearRange } from "~/src/components/shared";
import { getThumbnailURL } from "~/src/api";

function TeacherDisplayExperience({ experience }) {
  if (!experience) return null;

  const { studio } = experience;
  const dateMarkup = getMonthAndYearRange({ ...experience });

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            alt={studio.name}
            src={getThumbnailURL({
              url: studio.photo,
              size: 128,
            })}
          />
        </ListItemAvatar>
        <ListItemText
          primary={(
            <Link href="/studios/[id]" as={`/studios/${studio.id}`} target="_blank" rel="noopener">
              <Strong>{studio.name}</Strong>
            </Link>
          )}
          secondary={(
            <>
              <Typography color="textPrimary">
                {experience.title}
              </Typography>
              {dateMarkup}
            </>
          )}
          secondaryTypographyProps={{
            component: "div",
          }}
        />
      </ListItem>
    </>
  );
}

export default TeacherDisplayExperience;
