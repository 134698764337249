import React from "react";
import Router from "next/router";
import {
  Banner, Button, Dialog, FormLayout,
} from "~/src/components/global";
import { firebase, createStudio, uploadPhoto } from "~/src/api";
import JobPreview from "./JobPreview";

export default function SubmitDialog({
  values, studios, toggleModal, modalOpen, getLocationToUse,
}) {
  const [info, setInfo] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const saveJob = async (values, studioID) => {
    const {
      name, type, description, source,
    } = values;
    const location = getLocationToUse(values);

    const job = firebase.GeoFirestore.collection("jobs").doc();
    await job.set({
      name,
      type,
      description,
      source,
      studio: studioID,
      location,
      timestamp: firebase.fieldValue.serverTimestamp(),
      closed: false,
      inLastWeek: true,
      inLastFortnight: true,
      coordinates: new firebase.firestore.GeoPoint(location.latitude, location.longitude),
    });

    Router.push("/jobs/[id]", `/jobs/${job.id}`);
  };

  const saveStudio = async (values) => {
    const {
      newStudioName, newStudioWebsite, newStudioDescription, newStudioPhoto, source,
    } = values;

    // First upload photo
    const photo = newStudioPhoto ? await uploadPhoto({
      photo: newStudioPhoto,
      subdirectory: "studios",
      setInfo,
      setError,
    }) : null;

    // Then create studio
    let studio;
    try {
      studio = await createStudio({
        name: newStudioName,
        website: newStudioWebsite,
        email: source.type === "email" ? source.link : null,
        description: newStudioDescription,
        photo,
        styles: [values.type],
      });
    } catch (e) {
      setError(e);
      setLoading(false);
    }

    // Then add location
    try {
      const locationRef = await firebase.studioLocations(studio.id).doc();
      await locationRef.set({
        ...getLocationToUse(values),
        primary: true,
      });
    } catch (e) {
      setError(e);
      setLoading(false);
    }

    // Then save the job with this new studio
    saveJob(values, studio.id);
  };

  const handleSubmit = () => {
    setLoading(true);
    const { studio } = values;
    if (studio === "new") {
      saveStudio(values);
    } else {
      // TODO: Update existing studios,
      // Adding new job type to the studio's styles.
      // And new location to studio's locations.
      saveJob(values, studio);
    }
  };

  return (
    <Dialog
      onClose={toggleModal}
      id="post-job"
      open={modalOpen}
      title="Job Preview"
      subtitle="If it looks right, post it!"
    >
      <FormLayout>
        {info && <Banner status="info">{info}</Banner>}
        {error && <Banner status="danger">{error.message}</Banner>}

        <JobPreview
          values={values}
          studios={studios}
          location={getLocationToUse(values)}
        />

        <FormLayout.Footer>
          <Button disabled={loading} onClick={toggleModal}>
            Cancel
          </Button>
          <Button color="primary" loading={loading} onClick={handleSubmit} type="submit">
            Post Job
          </Button>
        </FormLayout.Footer>
      </FormLayout>
    </Dialog>
  );
}
