import React from "react";
import { connect } from "react-redux";
import Link from "next/link";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import {
  Typography,
  Divider,
} from "@material-ui/core";
import * as ROUTES from "~/src/constants/routes";

import {
  Container,
  Spacing,
} from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: fade(theme.palette.text.primary, 0.1),
  },
  flex: {
    display: "flex",
    alignItems: "center",
    "& > * + *": {
      marginLeft: theme.spacing(3),
    },
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.text.primary,
    },
  },
}));

function Footer({ authUser }) {
  const classes = useStyles();

  const extraLinks = authUser ? (
    <>
      <Typography variant="caption" color="textSecondary">
        <a className={classes.link} href="https://www.facebook.com/bolstrapp" target="_blank" rel="noopener noreferrer">Facebook</a>
      </Typography>

      <Typography variant="caption" color="textSecondary">
        <a className={classes.link} href="https://www.instagram.com/bolstr.app" target="_blank" rel="noopener noreferrer">Instagram</a>
      </Typography>
    </>
  ) : (
    <>
      <Typography variant="caption" color="textSecondary">
        <Link href={ROUTES.LOG_IN}>
          <a className={classes.link}>Log In</a>
        </Link>
      </Typography>

      <Typography variant="caption" color="textSecondary">
        <Link href={ROUTES.SIGN_UP}>
          <a className={classes.link}>Sign Up</a>
        </Link>
      </Typography>
    </>
  );

  return (
    <footer className={classes.footer}>
      <Container size="lg">
        <div className={classes.flex}>
          <Typography variant="caption" color="textSecondary">
            <Link href={ROUTES.HOME}>
              <a className={classes.link}>Home</a>
            </Link>
          </Typography>

          <Typography variant="caption" color="textSecondary">
            <a className={classes.link} href={ROUTES.CONTACT} target="_blank" rel="noopener noreferrer">Support</a>
          </Typography>

          {extraLinks}
        </div>

        <Spacing>
          <Divider />
        </Spacing>

        <div className={classes.flex}>
          <Typography variant="caption" color="textSecondary">
            <Link href={ROUTES.TERMS_AND_CONDITIONS}>
              <a className={classes.link}>Terms & Conditions</a>
            </Link>
          </Typography>

          <Typography variant="caption" color="textSecondary">
            <Link href={ROUTES.PRIVACY_POLICY}>
              <a className={classes.link}>Privacy Policy</a>
            </Link>
          </Typography>

          <Typography variant="caption" color="textSecondary">
            <Link href={ROUTES.BLOG}>
              <a className={classes.link}>Blog</a>
            </Link>
          </Typography>
        </div>

        <Spacing>
          <Divider />
        </Spacing>

        <div className={classes.flex}>
          <Typography variant="caption" color="textSecondary">
            2021 © Morgan Carter. All Rights Reserved
          </Typography>
        </div>
      </Container>
    </footer>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Footer);
