import React from "react";

import {
  MenuItem as MuiMenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import { Link } from "~/src/components/global";

function MenuItem(props) {
  const {
    href, onClick, icon, label, external,
  } = props;

  const iconMarkup = icon ? (
    <ListItemIcon>
      {icon}
    </ListItemIcon>
  ) : null;

  return href ? (
    <Link href={href} unstyled external={external}>
      <MuiMenuItem onClick={onClick}>
        {iconMarkup}
        <ListItemText primary={label} />
      </MuiMenuItem>
    </Link>
  ) : (
    <MuiMenuItem onClick={onClick}>
      {iconMarkup}
      <ListItemText primary={label} />
    </MuiMenuItem>
  );
}

export default MenuItem;
