import React from "react";
import * as ROUTES from "~/src/constants/routes";

import {
  Banner,
  Flex,
  Spacing,
  Link,
  Button,
} from "~/src/components/global";

import {
  BannerEmailVerification,
  BannerCreateProfile,
  ActionButtonsMessageButton,
} from "~/src/components/shared";

const ActionButtonsStudio = ({ studio, authUser }) => {
  let primaryButtonMarkup;
  let secondaryButtonMarkup;
  let bannerMarkup;

  if (studio.website) {
    primaryButtonMarkup = (
      <Link href={studio.website} external unstyled>
        <Button color="primary">
          Visit Website ↗
        </Button>
      </Link>
    );
  }

  // Loading
  if (authUser === null) {
    secondaryButtonMarkup = <Button loading>Message</Button>;
  }

  // Logged out
  if (authUser === false) {
    bannerMarkup = (
      <Banner status="info">
        <Link href={ROUTES.SIGN_UP}>Sign up</Link> to message this studio.
      </Banner>
    );
  }

  // Email not verified
  if (authUser && !authUser.emailVerified) {
    bannerMarkup = <BannerEmailVerification />;
  }

  // No teacher or studio profile
  if (authUser && !authUser.teacher && !authUser.studio) {
    bannerMarkup = <BannerCreateProfile />;
  }

  // Not registered
  // if (!studio.registered) {
  //   bannerMarkup = (
  //     <Banner status="info">
  //       Do you own this studio? TODO: Claim it.
  //     </Banner>
  //   );
  // }

  // Registered, and allowed to message
  if (authUser && authUser.emailVerified && (authUser.teacher || authUser.studio)) {
    secondaryButtonMarkup = (
      <ActionButtonsMessageButton
        authID={studio.authID}
        email={studio.email}
      />
    );
  }

  // Owned -> Edit Profile
  if (authUser && authUser.authID === studio.authID) {
    secondaryButtonMarkup = (
      <Link href={ROUTES.PROFILE_STUDIO} unstyled>
        <Button>
          Edit Profile
        </Button>
      </Link>
    );
  }

  const atLeastOneButton = primaryButtonMarkup || secondaryButtonMarkup;

  return (
    <>
      {atLeastOneButton && (
        <Flex spaceChildren justify="start">
          {primaryButtonMarkup}
          {secondaryButtonMarkup}
        </Flex>
      )}

      {atLeastOneButton && bannerMarkup && <Spacing extraTight />}

      {bannerMarkup}
    </>
  );
};

export default ActionButtonsStudio;
