import React from "react";
import Router from "next/router";

import { firebase } from "~/src/api";
import * as ROUTES from "~/src/constants/routes";

const defaultCondition = (authUser) => !!authUser;
const defaultRoute = ROUTES.LOG_IN;

const withAuthorization = (condition, route) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      const useCondition = condition || defaultCondition;
      const useRoute = route || defaultRoute;

      this.listener = firebase.onAuthUserListener(
        (authUser) => {
          if (!useCondition(authUser)) {
            Router.push(useRoute);
          }
        },
        () => {
          Router.push(useRoute);
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <Component {...this.props} />
      );
    }
  }

  return WithAuthorization;
};

export default withAuthorization;
