import React from "react";

import {
  Typography,
} from "@material-ui/core";

import * as ROUTES from "~/src/constants/routes";

import { logSentryError } from "~/src/api";

import {
  AppWithAuthentication,
  Head,
  Layout,
  Banner,
  Button,
  Spacing,
  Link,
} from "~/src/components/global";

function Error({ statusCode = 404, err }) {
  if (err) logSentryError(err);

  return (
    <AppWithAuthentication>
      <Head />

      <Layout>
        <Typography align="center" component="div">
          <Banner status="danger">
            {statusCode === 404 ? "We couldn’t find the page you were after."
              : "Something went wrong. Our team has been alerted. Please try again later."}
          </Banner>

          <Spacing />

          <Link unstyled href={ROUTES.HOME}>
            <Button color="primary">Go Home</Button>
          </Link>
        </Typography>
      </Layout>
    </AppWithAuthentication>
  );
}

export default Error;

export const getStaticProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  return {
    props: {
      statusCode,
      err: err || null,
    },
  };
};
