import React from "react";
import { Chip } from "@material-ui/core";
import { getTeacherPhoto } from "~/src/api";
import { Container, Spacing } from "~/src/components/global";
import { DetailHero, BannerReplyTime } from "~/src/components/shared";
import { TeacherActionButtons, TeacherDetailBody, TeacherGallery } from "~/src/components/teachers";

const TeacherDetail = (props) => {
  const {
    teacher, authUser, hideTitle, ActionButtonsOverride,
  } = props;

  const teacherName = teacher.pro ? `${teacher.firstname} ${teacher.lastname}` : teacher.firstname;

  const titleMarkup = hideTitle ? null : (
    <DetailHero
      image={getTeacherPhoto({
        teacher,
        size: 128,
      })}
      title={teacherName}
      badge={teacher.pro ? <Chip label="Pro" /> : null}
    />
  );

  return (
    <>
      <TeacherGallery teacher={teacher} />

      <Container size="sm" disableMargins disableGutters>
        {titleMarkup}

        {ActionButtonsOverride || (
          <TeacherActionButtons
            teacher={teacher}
            authUser={authUser}
          />
        )}

        <BannerReplyTime name={teacher.firstname} avgReplyTime={teacher.avgReplyTime} marginTop />

        <Spacing />

        <TeacherDetailBody teacher={teacher} />
      </Container>
    </>
  );
};

export default TeacherDetail;
