import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Container,
  Header,
  Footer,
} from "~/src/components/global";

import { AdminHeader } from "~/src/components/admin";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    flexGrow: 1,
  },
}));

export default function Layout(props) {
  const classes = useStyles();
  const {
    children, admin, noHeader, size,
  } = props;

  const headerMarkup = admin ? (
    <AdminHeader />
  ) : noHeader ? null : (
    <Header />
  );

  const footerMarkup = noHeader ? null : <Footer />;

  return (
    <div className={classes.wrapper}>
      {headerMarkup}
      <main className={classes.main}>
        <Container size={size}>
          {children}
        </Container>
      </main>
      {footerMarkup}
    </div>
  );
}
