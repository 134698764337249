import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import {
  Button, Dialog, FormLayout, Link, Strong,
} from "~/src/components/global";
import PitchPreview from "./PitchPreview";
import IncompleteProfileBanner from "./IncompleteProfileBanner";

function ApplyViaFacebookPost(props) {
  const {
    selfTeacher,
    pitchUrl,
    textareaRef,
    message,
    messageCopied,
    modalOpen,
    toggleModal,
    handleChangeMessage,
    copyToClipboard,
    job,
  } = props;

  const subtitle = job.source.type === "facebookPost" ? `By sending a Facebook message to ${job.source.name || job.studio.name}.` : "On the following website.";
  const label = job.source.type === "facebookPost" ? "Message" : "Cover Letter";

  return (
    <Dialog
      onClose={toggleModal}
      id="apply-for-job"
      open={modalOpen}
      title="Apply for Job"
      subtitle={subtitle}
      maxWidth="md"
    >
      <IncompleteProfileBanner teacher={selfTeacher} />
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography>
            <Strong>Application Preview</Strong>
          </Typography>
          <PitchPreview url={pitchUrl} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormLayout>
            <TextField
              label={label}
              value={message}
              onChange={handleChangeMessage}
              inputRef={textareaRef}
              helperText={`Paste this ${job.source.type === "facebookPost" ? "in your Facebook message." : "as your cover letter on the following website."}`}
              multiline
            />
            <FormLayout.Footer>
              <Button type="submit" color={messageCopied ? undefined : "primary"} onClick={copyToClipboard}>
                {messageCopied ? "✓ Copied!" : `Copy ${label}`}
              </Button>

              <Link href={job.source.link} external unstyled>
                <Button color={messageCopied ? "primary" : undefined}>
                  Go to Ad →
                </Button>
              </Link>
            </FormLayout.Footer>
          </FormLayout>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ApplyViaFacebookPost;
