import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    listStyle: "none",
  },
  step: {
    flexGrow: 1,
    height: 4,
    opacity: 0.25,
    background: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    "& + $step": {
      marginLeft: theme.spacing(0.5),
    },
  },
  active: {
    opacity: 1,
  },
}));

const SteppedProgress = ({ numSteps, activeStep }) => {
  const classes = useStyles();

  const renderStep = (index) => {
    const stepClasses = classNames(
      classes.step,
      index < activeStep && classes.active,
    );

    return <li className={stepClasses} key={index} />;
  };

  return (
    <div className={classes.wrapper}>
      {Array.from(Array(numSteps), (e, index) => (
        renderStep(index)
      ))}
    </div>
  );
};

export default SteppedProgress;
