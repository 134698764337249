import React from "react";
import StudioIcon from "@material-ui/icons/StoreMallDirectoryOutlined";
import { getThumbnailURL } from "~/src/api";
import { renderLocation } from "~/src/utils";
import { Avatar, GridItem } from "~/src/components/global";
import { StudioDisplayCategories } from "~/src/components/studios";

function StudioGridItem(props) {
  const {
    studio,
    onClick,
    disabled,
    GridItemOverrides,
    dataCy,
  } = props;

  if (!studio) return null;

  const imageMarkup = studio.photo ? (
    <Avatar
      alt={studio.name}
      src={getThumbnailURL({
        alt: studio.name,
        url: studio.photo,
        size: 128,
      })}
    />
  ) : (
    <Avatar alt={studio.name}>
      <StudioIcon />
    </Avatar>
  );

  return (
    <GridItem
      image={imageMarkup}
      title={studio.name}
      tertiary={<StudioDisplayCategories categories={studio.styles} minimal />}
      description={studio.description}
      location={renderLocation(studio.location)}
      href="/studios/[id]"
      as={`/studios/${studio.id}`}
      onClick={onClick}
      disabled={disabled}
      GridItemOverrides={GridItemOverrides}
      dataCy={dataCy}
    />
  );
}

export default StudioGridItem;
