import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { firebase } from "~/src/api";

import {
  FormLayout,
  Button,
  Link,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  website: yup.string(),
});

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 18,
    display: "inline",
    verticalAlign: "text-top",
  },
}));

export default function FormStudioWebsite({ studio, back, forward }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        website: studio.website || "https://",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const { website } = values;

        firebase.studio(studio.id).set(
          {
            website,
          },
          { merge: true },
        ).then(() => {
          Router.push(forward.route);
        });
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <FormLayout>
            <Field
              label="Website"
              name="website"
              component={TextField}
              placeholder="eg. https://bolstr.app"
              helperText={
                values.website.length > 10
                && (
<Link external href={values.website}>
                  Test website link <OpenInNewIcon className={classes.icon} />
                </Link>
                )
              }
              inputProps={{
                spellCheck: "false",
                autoCorrect: "off",
                autoCapitalize: "none",
                autocompletetype: "username",
              }}
            />

            <FormLayout.Footer>
              {back && back.route && (
                <Link href={back.route} unstyled>
                  <Button>{back.label}</Button>
                </Link>
              )}

              <Button
                color="primary"
                loading={isSubmitting}
                type="submit"
              >
                {forward.label}
              </Button>
            </FormLayout.Footer>
          </FormLayout>
        </Form>
      )}
    </Formik>
  );
}
