import React from "react";
import TimeAgo from "react-timeago";
import { Chip } from "@material-ui/core";
import { getThumbnailURL } from "~/src/api";
import { renderLocation } from "~/src/utils";
import { ListItem } from "~/src/components/global";
import SKILLS from "~/src/constants/skills";

function JobListItem(props) {
  const {
    job,
    onClick,
    disabled,
    dataCy,
  } = props;

  if (!job) return null;

  const { teacher, studio } = job;

  const openBadge = job.closed ? <Chip label="Filled" /> : null;
  const jobType = SKILLS.find((skill) => skill.name === job.type);

  return (
    <ListItem
      imageSrc={studio || teacher ? getThumbnailURL({ url: studio ? studio.photo : teacher.photo }) : null}
      primary={job.name}
      secondary={renderLocation(job.location)}
      tertiary={(
        <span>
          {jobType && jobType.label} · <TimeAgo date={job.timestamp && job.timestamp.seconds && new Date(job.timestamp.seconds * 1000)} />
        </span>
      )}
      caption={openBadge}
      href="/jobs/[id]"
      as={`/jobs/${job.id}`}
      onClick={onClick}
      disabled={disabled}
      dataCy={dataCy}
    />
  );
}

export default JobListItem;
