import React from "react";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

const InputAutocomplete = (props) => {
  const {
    newName, initialDisplayValue, field, form, label, options, disabled, onChange, ...other
  } = props;
  const { setFieldValue } = useFormikContext();
  const currentError = form.errors[field.name];

  // This is used to display the visible input value.
  // It does not correspond to the value in Formik.
  const [displayValue, setDisplayValue] = React.useState(initialDisplayValue);

  return (
    <FormControl error={Boolean(currentError)} variant="outlined">
      <Autocomplete
        freeSolo
        fullWidth
        autoComplete
        autoHighlight
        openOnFocus
        selectOnFocus
        clearOnBlur
        disabled={disabled}
        value={displayValue}
        onChange={(event, newValue) => {
          // Press enter
          if (typeof newValue === "string") {
            setDisplayValue({ label: newValue });
            setFieldValue(field.name, newValue);

          // Click "Add XXX"
          } else if (newValue && newValue.inputValue) {
            setDisplayValue({ label: newValue.inputValue });
            setFieldValue(field.name, "new");
            setFieldValue(newName, newValue.inputValue);

          // Select existing option
          } else {
            setDisplayValue(newValue);
            setFieldValue(field.name, newValue ? newValue.value : null);
          }
          if (onChange) {
            onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              label: `Add “${params.inputValue}”`,
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          // "Add XXX"
          if (option.inputValue) {
            return option.inputValue;
          }

          return option.label || option;
        }}
        options={options}
        getOptionDisabled={(option) => option.disabled}
        renderOption={(option) => option.label}
        renderInput={(params) => <TextField {...params} label={label} />}
        {...other}
      />

      {currentError && <FormHelperText>{currentError}</FormHelperText>}
    </FormControl>
  );
};

export default InputAutocomplete;
