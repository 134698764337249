import React from "react";
import { FieldArray, useFormikContext } from "formik";

import {
  IconButton,
  FormLabel,
  Tooltip,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import FavoriteIcon from "@material-ui/icons/Favorite";
import NotFavoriteIcon from "@material-ui/icons/FavoriteBorder";

import {
  Button,
  FormLayout,
  Flex,
  Spacing,
  InputLocation,
} from "~/src/components/global";

import LOCATION_INITIAL_VALUES from "~/src/constants/initialValues/location";

const EMPTY_LOCATION_STRUCTURE = {
  ...LOCATION_INITIAL_VALUES,
  primary: false,
};

function InputLocationsArray(props) {
  const {
    array, name, label, disabled, types,
  } = props;
  const { values, setFieldValue } = useFormikContext();

  const setPrimaryLocation = (index) => {
    // Find current primary and unprimary it.
    const currentPrimary = values.locations.findIndex((location) => location.primary);

    setFieldValue(`locations[${currentPrimary}].primary`, false);

    // Then make this one the new primary
    setFieldValue(`locations[${index}].primary`, true);
  };

  const renderFavoriteButton = (index) => {
    if (values.locations.length <= 1) return null;

    const isPrimary = values.locations[index].primary;

    return isPrimary ? (
      <IconButton disabled={disabled}>
        <FavoriteIcon color="secondary" />
      </IconButton>
    ) : (
      <Tooltip title="Make your primary location">
        <IconButton aria-label="make-primary" onClick={() => setPrimaryLocation(index)} disabled={disabled}>
          <NotFavoriteIcon />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div>
          <FormLabel>{label}</FormLabel>

          <Spacing extraTight />

          {array && array.length > 0 ? (
            <>
              <FormLayout tight>
                {array.map((value, index) => (
                  <Flex key={index} align="start">
                    {renderFavoriteButton(index)}

                    <Flex.Item fill>
                      <InputLocation disabled={disabled} arrayIndex={index} types={types} />
                    </Flex.Item>

                    <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)} disabled={disabled}>
                      <CloseIcon />
                    </IconButton>
                  </Flex>
                ))}
              </FormLayout>

              <Spacing extraTight />
            </>
          ) : null}

          <Button color="primary" variant="text" disabled={disabled} onClick={() => arrayHelpers.push(EMPTY_LOCATION_STRUCTURE)}>
            Add Location
          </Button>
        </div>
      )}
    />
  );
}

InputLocationsArray.defaultProps = {
  types: ["geocode"],
};

export default InputLocationsArray;
