import React from "react";
import { compose } from "recompose";

import * as ROUTES from "~/src/constants/routes";

import { withAuthentication, withAuthorization } from "~/src/components/global";

const App = ({ children }) => (
  <div className="app">
    {children}
  </div>
);

const AppWithAuthentication = compose(
  withAuthentication,
)(App);

const AppMustHaveUser = compose(
  withAuthentication,
  withAuthorization((authUser) => !!authUser),
)(App);

const AppMustHaveAdmin = compose(
  withAuthentication,
  withAuthorization((authUser) => authUser.admin, ROUTES.TEACHERS),
)(App);

export { AppWithAuthentication, AppMustHaveUser, AppMustHaveAdmin };
