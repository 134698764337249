/* eslint-disable camelcase */
import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { Hidden, FormHelperText } from "@material-ui/core";
import Geosuggest from "react-geosuggest";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  input: {
    height: theme.shape.actionHeight,
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    font: "inherit",
    padding: [[0, 12, 1]],
    boxSizing: "border-box",
    border: `1px solid ${fade(theme.palette.text.primary, 0.2)}`,
    "-webkitAppearance": "none",
    "&:focus": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      boxShadow: `0 0 0 2px ${fade(theme.palette.primary.main, 0.2)}`,
      outline: "none",
    },
    "&:disabled": {
      color: theme.palette.text.disabled,
      background: fade(theme.palette.text.primary, 0.1),
    },
    "@media (max-device-width: 600px)": {
      fontSize: 16, // Prevent zooming in on iPhones.
    },
  },
  inputError: {
    borderColor: theme.palette.error.main,
  },
  suggestList: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 2,
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    margin: [[theme.spacing(0.5), 0, 0]],
    padding: [[theme.spacing(), 0]],
    borderRadius: theme.shape.borderRadius,
    listStyle: "none",
    "&:empty": {
      display: "none",
    },
  },
  suggestListHidden: {
    display: "none",
  },
  suggestItem: {
    display: "flex",
    alignItems: "center",
    padding: [[7, theme.spacing(2), 6]],
    "&:hover": {
      cursor: "pointer",
      background: fade(theme.palette.text.primary, 0.1),
    },
    "& b": {
      fontWeight: 500,
    },
  },
  suggestItemActive: {
    background: fade(theme.palette.text.primary, 0.1),
  },
}));

function InputLocation(props) {
  const {
    disabled, arrayIndex, types, helperText, label,
  } = props;
  const classes = useStyles();
  const { values, errors, setFieldValue } = useFormikContext();
  const isMultiple = arrayIndex !== undefined;

  function setAddressValue(name, value, address_components) {
    const foundValue = address_components.find((component) => component.types.includes(value));

    if (foundValue) {
      setFieldValue(name, foundValue.long_name);
    } else {
      setFieldValue(name, "");
    }
  }

  function setLatLongValue(name, value, location) {
    const foundValue = location[value];

    if (foundValue) {
      setFieldValue(name, foundValue);
    } else {
      setFieldValue(name, "");
    }
  }

  function getFieldName(name) {
    if (isMultiple) {
      return `locations[${arrayIndex}].${name}`;
    }
    return `location.${name}`;
  }

  function getFieldValue(name) {
    if (isMultiple) {
      return values.locations[arrayIndex][name];
    }
    return values.location[name];
  }

  function getFieldError(name) {
    if (isMultiple) {
      return errors && errors.locations && errors.locations[arrayIndex] && errors.locations[arrayIndex][name];
    }
    return errors && errors.location && errors.location[name];
  }

  function onSuggestSelect(suggest) {
    if (suggest) {
      if (suggest.gmaps && suggest.gmaps.address_components) {
        setFieldValue(getFieldName("label"), suggest.label);
        setFieldValue(getFieldName("name"), suggest.gmaps.name);
        setAddressValue(getFieldName("streetNumber"), "street_number", suggest.gmaps.address_components);
        setAddressValue(getFieldName("streetName"), "route", suggest.gmaps.address_components);
        setAddressValue(getFieldName("suburb"), "locality", suggest.gmaps.address_components);
        setAddressValue(getFieldName("state"), "administrative_area_level_1", suggest.gmaps.address_components);
        setAddressValue(getFieldName("country"), "country", suggest.gmaps.address_components);
        setAddressValue(getFieldName("postCode"), "postal_code", suggest.gmaps.address_components);
      }
      if (suggest.location) {
        setLatLongValue(getFieldName("latitude"), "lat", suggest.location);
        setLatLongValue(getFieldName("longitude"), "lng", suggest.location);
      }
    }
  }

  const errorMessage = getFieldError("label");

  const inputClasses = classNames(
    classes.input,
    errorMessage && classes.inputError,
  );

  return (
    <>
      <Geosuggest
        inputClassName={inputClasses}
        className={classes.container}
        suggestsClassName={classes.suggestList}
        suggestsHiddenClassName={classes.suggestListHidden}
        suggestItemClassName={classes.suggestItem}
        suggestItemActiveClassName={classes.suggestItemActive}

        id={isMultiple ? undefined : "location-input"}
        label={isMultiple ? undefined : label}
        placeholder="Search locations…"
        initialValue={getFieldValue("label")}
        country="au"
        types={types}
        disabled={disabled}
        onSuggestSelect={onSuggestSelect.bind(this)} // eslint-disable-line react/jsx-no-bind
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}

      <Hidden implementation="css" xsUp>
        <Field label="Street Number" name={getFieldName("streetNumber")} component={TextField} disabled />
        <Field label="Street Name" name={getFieldName("streetName")} component={TextField} disabled />
        <Field label="Suburb" name={getFieldName("suburb")} component={TextField} disabled />
        <Field label="State" name={getFieldName("state")} component={TextField} disabled />
        <Field label="Post Code" name={getFieldName("postCode")} component={TextField} disabled />
        <Field label="Country" name={getFieldName("country")} component={TextField} disabled />
      </Hidden>
    </>
  );
}

InputLocation.defaultProps = {
  label: "Location",
  types: ["geocode"],
};

export default InputLocation;
