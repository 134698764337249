import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  strong: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const Strong = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <strong className={classes.strong}>
      {children}
    </strong>
  );
};

export default Strong;
