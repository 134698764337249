import React from "react";
import { firebase } from "~/src/api";
import { Button, Link } from "~/src/components/global";

class MessageButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      loading: true,
    };
  }

  componentDidMount = async () => {
    const { authID } = this.props;
    let { email } = this.props;

    if (!email && authID) {
      const user = await firebase.user(authID).get();
      const userData = user.data();
      email = userData.email;
    }

    this.setState({
      email,
      loading: false,
    });
  }

  render() {
    const { name } = this.props;
    const { email, loading } = this.state;

    if (loading) {
      return (
        <Button loading>
          Email Studio
        </Button>
      );
    }

    if (email) {
      return (
        <Link href={`mailto:${email}`} external unstyled>
          <Button color={name ? "primary" : undefined} data-cy="email-button">
            {name ? `Contact ${name}` : "Email Studio"}
          </Button>
        </Link>
      );
    }

    return null;
  }
}

export default MessageButton;
