import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Avatar } from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  media: {
    display: "flex",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
    },
  },
  image: {
    flexShrink: 0,
    alignSelf: "flex-start",
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  text: {
    alignSelf: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
  },
  title: {
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(1.5),
    },
  },
  badge: {
    marginTop: theme.spacing(0.5),
  },
}));

function DetailHero(props) {
  const {
    image,
    title,
    badge,
  } = props;

  const classes = useStyles();

  const avatarMarkup = image ? (
    <div className={classes.image}>
      <Avatar alt={title} src={image} size="auto" />
    </div>
  ) : null;

  return (
    <div className={classes.media}>
      {avatarMarkup}
      <div className={classes.text}>
        <Typography variant="h1" className={classes.title}>{title}</Typography>
        {badge && <div className={classes.badge}>{badge}</div>}
      </div>
    </div>
  );
}

export default DetailHero;
