const JOB_PLACEHOLDERS = {
  NAME: [
    "eg. Vinyasa Flow",
    "eg. Sound Healing",
    "eg. Pilates Reformer",
    "eg. Mobility Class",
  ],
  DESCRIPTION: [
    "eg. Hey guys, we are after a yogi with lots of energy to take over a popular class, as one of our teachers is leaving. CPR, First Aid & Insurance a must.",
    "eg. Hi all, we are holding a workshop and want to include a segment with Sound Healing.",
    "eg. We have a pilates reformer class we need covered. CPR, First Aid & Insurance a must.",
    "eg. Hi there, I’m away for the weekend and need my mobility class covered. Would suit flowy yoga or pilates teachers.",
  ],
};

export default JOB_PLACEHOLDERS;
