import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: 200,
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadiusLarge,
    marginTop: theme.spacing(0.5),
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: `1px solid ${theme.palette.border.base}`,
      borderRadius: theme.shape.borderRadiusLarge,
      zIndex: 1,
    },
    [theme.breakpoints.up("sm")]: {
      height: 300,
    },
  },
  iframe: {
    border: "none",
    transform: "scale(0.25)",
    transformOrigin: "0 0",
    width: "400%",
    height: 800,
    pointerEvents: "none",
    [theme.breakpoints.up("sm")]: {
      height: 1200,
    },
  },
}));

function PitchPreview({ url }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <iframe title="Preview Iframe" src={url} className={classes.iframe} />
      </div>
    </>
  );
}

export default PitchPreview;
