import React from "react";
import { Banner, Link, Spacing } from "~/src/components/global";
import { getTeacherProfileSections } from "~/src/utils";
import * as ROUTES from "~/src/constants/routes";

function IncompleteProfileBanner({ teacher }) {
  const sections = getTeacherProfileSections(teacher);
  const progress = Math.ceil(sections.filter((section) => section.completed).length / sections.length * 100);

  return progress === 100 ? null : (
    <>
      <Banner status="warning">
        Your teacher profile is incomplete! <Link href={ROUTES.PROFILE_TEACHER}>Complete your profile</Link> to increase your chances of getting this job.
      </Banner>
      <Spacing />
    </>
  );
}

export default IncompleteProfileBanner;
