import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";

import {
  Link,
  Flex,
  Avatar,
  Strong,
} from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  link: {
    "&:hover": {
      color: theme.palette.primary.main,
      "& $title": {
        textDecoration: "underline",
      },
    },
  },
  title: {

  },
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

function ListItem(props) {
  const {
    imageSrc,
    AvatarProps,
    primary,
    secondary,
    tertiary,
    caption,
    href,
    as,
    onClick,
    disabled,
    ListItemOverrides,
    dataCy,
  } = props;

  const classes = useStyles();

  const primaryMarkup = caption ? (
    <Flex>
      <Typography className={classes.title}>
        <Strong>{primary}</Strong>
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {caption}
      </Typography>
    </Flex>
  ) : (
    <Typography className={classes.title}>
      <Strong>{primary}</Strong>
    </Typography>
  );

  const secondaryMarkup = tertiary ? (
    <>
      <Typography color="textPrimary" className={classes.ellipsis}>{secondary}</Typography>
      <Typography color="textSecondary" className={classes.ellipsis}>{tertiary}</Typography>
    </>
  ) : <Typography color="textSecondary" className={classes.ellipsis}>{secondary}</Typography>;

  const avatarMarkup = imageSrc ? (
    <ListItemAvatar>
      <Avatar alt={primary} src={imageSrc} {...AvatarProps} />
    </ListItemAvatar>
  ) : null;

  const markup = (
    <MuiListItem
      alignItems="flex-start"
      className={href ? classes.link : undefined}
      button={!!onClick}
      onClick={onClick}
      disabled={disabled}
      data-cy={dataCy}
    >
      {avatarMarkup}
      <ListItemText
        disableTypography
        primary={primaryMarkup}
        secondary={secondaryMarkup}
      />
    </MuiListItem>
  );

  return href ? (
    <Link href={ListItemOverrides && ListItemOverrides.href ? ListItemOverrides.href : href} as={ListItemOverrides && ListItemOverrides.as ? ListItemOverrides.as : as} unstyled>
      {markup}
    </Link>
  ) : markup;
}

export default ListItem;
