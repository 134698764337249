import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      overflow: "auto",
      flexWrap: "nowrap",
      padding: [[theme.spacing(), 0]],
      margin: [[theme.spacing(-1), theme.spacing(-2)]],
      "& > *": {
        paddingRight: theme.spacing(1.5),
        "&:first-child": {
          paddingLeft: theme.spacing(2),
        },
        "&:last-child": {
          paddingRight: theme.spacing(2),
        },
      },
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      "& > *": {
        width: "100%",
        paddingBottom: theme.spacing(2),
      },
    },
  },
}));

const Filter = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {children}
    </div>
  );
};

export default Filter;
