/* eslint-disable max-len */
import React from "react";

const InstagramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="currentColor" d="M17,1 C20.2383969,1 22.8775718,3.56557489 22.9958615,6.77506174 L23,7 L23,17 C23,20.2383969 20.4344251,22.8775718 17.2249383,22.9958615 L17,23 L7,23 C3.76160306,23 1.12242824,20.4344251 1.00413847,17.2249383 L1,17 L1,7 C1,3.76160306 3.56557489,1.12242824 6.77506174,1.00413847 L7,1 L17,1 Z M17,3 L7,3 C4.85780461,3 3.10892112,4.68396847 3.00489531,6.80035966 L3,7 L3,17 C3,19.1421954 4.68396847,20.8910789 6.80035966,20.9951047 L7,21 L17,21 C19.1421954,21 20.8910789,19.3160315 20.9951047,17.1996403 L21,17 L21,7 C21,4.790861 19.209139,3 17,3 Z M12.7766835,7.01081652 C14.9554044,7.33389346 16.6661065,9.04459556 16.9891835,11.2233165 C17.3055865,13.357031 16.2179658,15.4531222 14.2912166,16.4229088 C12.3644675,17.3926955 10.0329942,17.0175298 8.50773217,15.4922678 C6.98247016,13.9670058 6.60730452,11.6355325 7.57709117,9.70878336 C8.54687783,7.78203422 10.642969,6.69441347 12.7766835,7.01081652 Z M9.36356114,10.6079636 C8.78168914,11.7640131 9.00678853,13.1628971 9.92194574,14.0780543 C10.8371029,14.9932115 12.2359869,15.2183109 13.3920364,14.6364389 C14.5480859,14.0545669 15.2006583,12.7969122 15.0108165,11.5166835 C14.8262011,10.2717001 13.8864156,9.28142081 12.6680942,9.02247999 L12.4833165,8.98918348 L12.2915332,8.96698295 C11.0790118,8.86585759 9.91633953,9.50971657 9.36356114,10.6079636 Z M17.5,5.5 C18.0522847,5.5 18.5,5.94771525 18.5,6.5 C18.5,7.05228475 18.0522847,7.5 17.5,7.5 C16.9477153,7.5 16.5,7.05228475 16.5,6.5 C16.5,5.94771525 16.9477153,5.5 17.5,5.5 Z" />
  </svg>
);

export default InstagramIcon;
