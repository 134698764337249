import React from "react";
import { Strong } from "~/src/components/global";
import { renderLocation } from "~/src/utils";
import SKILLS from "~/src/constants/skills";

function WeeklyRoundupJob({ job }) {
  const jobType = SKILLS.find((skill) => skill.name === job.type);
  const url = `${process.env.URL_PREFIX}/jobs/${job.id}`;

  return (
    <li key={job.id}>
      {jobType && jobType.emoji} <Strong>{job.name}</Strong> for {job.studio && job.studio.name} in <Strong>{renderLocation(job.location)}</Strong> (<a href={url}>{url}</a>)
    </li>
  );
}

export default WeeklyRoundupJob;
