import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  spacing: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    "&$children": {
      paddingLeft: 0,
      paddingBottom: theme.spacing(2),
    },
  },
  loose: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(2.5),
      paddingLeft: theme.spacing(2.5),
      "&$children": {
        paddingLeft: 0,
        paddingBottom: theme.spacing(2.5),
      },
    },
  },
  extraTight: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    "&$children": {
      paddingLeft: 0,
      paddingBottom: theme.spacing(1),
    },
  },
  extraLoose: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    "&$children": {
      paddingLeft: 0,
      paddingBottom: theme.spacing(4),
    },
  },
  children: {

  },
}));

function Spacing(props) {
  const {
    tight, extraTight, extraLoose, children,
  } = props;
  const classes = useStyles();

  const spacingClasses = classNames(
    classes.spacing,
    !tight && classes.loose,
    extraLoose && classes.extraLoose,
    extraTight && classes.extraTight,
    children && classes.children,
  );

  return (
    <div className={spacingClasses}>
      {children}
    </div>
  );
}

export default Spacing;
