import React from "react";
import { wrapWithComponent, isElementOfType } from "@shopify/react-utilities";
import { makeStyles } from "@material-ui/core/styles";

import Item from "./Item";

const useStyles = makeStyles(() => ({
  group: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export default function Group(props) {
  const { children } = props;
  const classes = useStyles();

  const wrapChildren = function (child, index) {
    if (isElementOfType(child, Group)) {
      return child;
    }
    const props = { key: index };
    return wrapWithComponent(child, Item, props);
  };

  return (
    <div className={classes.group}>
      {React.Children.map(children, wrapChildren)}
    </div>
  );
}
