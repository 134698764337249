import React from "react";
import { Formik, Form } from "formik";
import { Typography } from "@material-ui/core";
import { firebase } from "~/src/api";
import LOCATION_INITIAL_VALUES from "~/src/constants/initialValues/location";
import {
  Button, Card, FormLayout, InputLocation,
} from "~/src/components/global";
import { JobGridItem } from "~/src/components/jobs";

function AdminInlineGeojobForm({ job }) {
  return (
    <Card>
      <Formik
        initialValues={{
          location: job && job.location || LOCATION_INITIAL_VALUES,
        }}
        onSubmit={async (values) => {
          if (values.location.latitude && values.location.longitude) {
            await firebase.job(job.id).update({
              location: values.location,
              missingLatLong: false,
            });
          }
        }}
      >
        {({
          isSubmitting, values,
        }) => (
          <Form>
            <FormLayout>
              <JobGridItem job={job} />

              <div>
                <Typography noWrap>🌏 {job.location.label} · {values.location.label}</Typography>
                <Typography noWrap color="textSecondary" variant="caption">{job.location.latitude || "null"} · {values.location.latitude || "unset"}</Typography>
                <Typography noWrap color="textSecondary" variant="caption">{job.location.longitude || "null"} · {values.location.longitude || "unset"}</Typography>
              </div>

              <InputLocation label="Location" disabled={isSubmitting} />

              <FormLayout.Footer>
                <Button
                  color="primary"
                  loading={isSubmitting}
                  type="submit"
                >
                  Save
                </Button>
              </FormLayout.Footer>
            </FormLayout>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

export default AdminInlineGeojobForm;
