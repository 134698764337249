import React from "react";
import { FieldArray, Field } from "formik";
import { TextField } from "formik-material-ui";

import {
  IconButton,
  FormLabel,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/DeleteOutline";

import {
  Button,
  FormLayout,
  Flex,
  Spacing,
} from "~/src/components/global";

const emptyQualificationStructure = {
  name: "",
};

function InputQualifications(props) {
  const {
    array, name, label, buttonText, disabled, placeholders,
  } = props;

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div>
          {label && (
            <>
              <FormLabel>{label}</FormLabel>

              <Spacing extraTight />
            </>
          )}

          {array && array.length > 0 ? (
            <>
              <FormLayout tight>
                {array.map((value, index) => (
                  <Flex key={index} align="start">
                    <Flex.Item fill>
                      <Field
                        name={`${name}.${index}.name`}
                        component={TextField}
                        placeholder={placeholders && placeholders[index]}
                      />
                    </Flex.Item>

                    <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)} disabled={disabled}>
                      <DeleteIcon />
                    </IconButton>
                  </Flex>
                ))}
              </FormLayout>

              <Spacing extraTight />
            </>
          ) : null}

          <Button color="primary" variant="text" disabled={disabled} onClick={() => arrayHelpers.push(emptyQualificationStructure)}>
            {buttonText}
          </Button>
        </div>
      )}
    />
  );
}

export default InputQualifications;
