import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Container as MuiContainer } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  margin: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  disableGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function Container(props) {
  const {
    children, size, disableMargins, disableGutters,
  } = props;
  const classes = useStyles();

  const containerClasses = classNames(
    disableGutters && classes.disableGutters,
    !disableMargins && classes.margin,
  );

  return (
    <MuiContainer
      className={containerClasses}
      maxWidth={size}
    >
      {children}
    </MuiContainer>
  );
}

Container.defaultProps = {
  size: "lg",
};

export default Container;
