import React from "react";

import {
  IconButton,
  Box,
  Paper,
  Popper,
  ClickAwayListener,
  MenuList,
  Divider,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ProfileIcon from "@material-ui/icons/AccountCircleOutlined";
import StudioIcon from "@material-ui/icons/StoreMallDirectoryOutlined";
import JobsIcon from "@material-ui/icons/BusinessCenterOutlined";
import { newIntercomMessage } from "~/src/api/intercom";
import * as ROUTES from "~/src/constants/routes";
import { firebase } from "~/src/api";

import MenuItem from "./MenuItem";

function MenuLoggedIn(props) {
  const { authUser } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // const handleContactSupport = (event) => {
  //   newIntercomMessage();
  //   handleClose(event);
  // };

  const handleLogOut = async () => {
    await firebase.doSignOut();
    location.reload();
  };

  const teacherMenuItemMarkup = authUser.teacher ? (
    <MenuItem
      href={ROUTES.PROFILE_TEACHER}
      onClick={handleClose}
      icon={<ProfileIcon />}
      label="Teacher Profile"
    />
  ) : (
    <MenuItem
      href={ROUTES.PROFILE_TEACHER_ADD}
      onClick={handleClose}
      icon={<ProfileIcon />}
      label="Create Teacher Profile..."
    />
  );

  const studioMenuItemMarkup = authUser.studio ? (
    <MenuItem
      href={ROUTES.PROFILE_STUDIO}
      onClick={handleClose}
      icon={<StudioIcon />}
      label="Studio Profile"
    />
  ) : (
    <MenuItem
      href={ROUTES.PROFILE_STUDIO_ADD}
      onClick={handleClose}
      icon={<StudioIcon />}
      label="Create Studio Profile..."
    />
  );

  const menuMarkup = (
    <MenuList disablePadding={false} id="menu-list-grow">
      {teacherMenuItemMarkup}

      {studioMenuItemMarkup}

      <MenuItem
        href={ROUTES.PROFILE_YOUR_JOBS}
        onClick={handleClose}
        icon={<JobsIcon />}
        label="Your Jobs"
      />

      <Divider />

      <MenuItem
        href={ROUTES.ACCOUNT_SETTINGS}
        onClick={handleClose}
        label="Account"
      />

      <MenuItem
        // onClick={handleContactSupport}
        href="mailto:hello@morgancarter.com.au"
        label="Contact Support"
        external
      />

      <Divider />

      <MenuItem
        onClick={handleLogOut}
        label="Log Out"
      />
    </MenuList>
  );

  return (
    <>
      <IconButton edge="end" color="inherit" aria-label="menu" aria-controls={open ? "menu-list-grow" : undefined} aria-haspopup="true" onClick={handleToggle} ref={anchorRef}>
        <MenuIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        style={{ zIndex: 1 }}
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={2}>
            <Box px={2}>
              {menuMarkup}
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default MenuLoggedIn;
