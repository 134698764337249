import React from "react";
import { Typography } from "@material-ui/core";
import {
  Link, Flex, Card, Spacing,
} from "~/src/components/global";
import { getFilteredDocs } from "~/src/api";
import * as ROUTES from "~/src/constants/routes";
import Carousel from "./Carousel";

const PAGE_SIZE = 13;

class MoreLikeThis extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      results: [],
    };
  }

  loadData = async () => {
    const {
      query, label, discipline, location, promiseFunction,
    } = this.props;

    this.setState({ loading: true });

    const result = await getFilteredDocs({
      label,
      query,
      promiseFunction,
      filters: {
        discipline: discipline ? discipline.name : undefined,
        location: location ? location.state : undefined,
      },
      pageSize: PAGE_SIZE,
    });

    this.setState({
      loading: false,
      results: result.results,
    });
  }

  componentDidMount = async () => {
    this.loadData();
  }

  render() {
    const { job, discipline, location } = this.props;
    const { results, loading } = this.state;

    if (loading || !results.length) return null;

    const filteredResults = results.filter((result) => result.id !== job.id).slice(0, 12);

    if (!filteredResults.length) return null;

    return (
      <>
        <Spacing extraLoose />

        <Card well>

          <Flex wrap>
            <Typography variant="h4">
              More {discipline ? discipline.label : "health and fitness"} jobs {location ? `in ${location.state}` : ""}
            </Typography>
            <Link href={ROUTES.JOBS} target="_blank" rel="noopener">View all →</Link>
          </Flex>

          <Spacing tight />

          <Carousel
            items={filteredResults}
            listItem={this.props.listItem}
          />
        </Card>
      </>
    );
  }
}

export default MoreLikeThis;
