/* eslint-disable no-await-in-loop */
import React from "react";
import Router from "next/router";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LOCATION_INITIAL_VALUES from "~/src/constants/initialValues/location";

import { firebase } from "~/src/api";

import {
  FormLayout,
  Button,
  Link,
  InputLocationsArray,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  locations: yup.array().of(
    yup.object().shape({
      label: yup.string().required("You must have a location."),
    }),
  ),
});

class FormStudioLocations extends React.Component {
  ensureAtLeastOneLocationIsPrimary = (locations) => {
    const locationsWithPrimary = [].concat(locations);

    if (locationsWithPrimary && locationsWithPrimary.length > 0) {
      const currentPrimary = locationsWithPrimary.findIndex((location) => location.primary);

      if (currentPrimary === -1) {
        locationsWithPrimary[0].primary = true;
      }
    }
    return locationsWithPrimary;
  }

  submitForm = async (values, setSubmitting) => {
    const { studio, forward } = this.props;
    const { locations } = values;
    const newLocations = this.ensureAtLeastOneLocationIsPrimary(locations);
    const oldLocations = studio.locations;

    if (locations && locations.length) {
      for (const location of newLocations) {
        // Only pre-existing locations have IDs
        if (location.id) {
          // Update existing location
          await firebase.db.doc(`studios/${studio.id}/locations/${location.id}`).update({ ...location });
        } else {
          // Add new location
          const locationRef = await firebase.studioLocations(studio.id).doc();
          await locationRef.set({ ...location });
        }
      }
    }

    // Finally, remove any old locations
    if (oldLocations && oldLocations.length) {
      for (const oldLocation of oldLocations) {
        const keepingLocation = newLocations && newLocations.find((location) => location.id === oldLocation.id);
        if (keepingLocation) {

          // Do nothing, leave it
        } else {
          // Delete it
          await firebase.db.doc(`studios/${studio.id}/locations/${oldLocation.id}`).delete();
        }
      }
    }

    if (forward.route) {
      Router.push(forward.route, forward.routeAs);
    } else {
      setSubmitting(false);
    }
  }

  render() {
    const {
      studio, back, forward,
    } = this.props;

    return (
      <Formik
        initialValues={{
          locations: studio.locations && studio.locations.length ? studio.locations : [{
            ...LOCATION_INITIAL_VALUES,
            primary: false,
          }],
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          this.submitForm(values, setSubmitting);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <FormLayout>
              <InputLocationsArray
                name="locations"
                array={values.locations}
                disabled={isSubmitting}
              />

              <FormLayout.Footer>
                {back && back.route && (
                <Link href={back.route} unstyled>
                  <Button>{back.label}</Button>
                </Link>
                )}

                <Button
                  color="primary"
                  loading={isSubmitting}
                  type="submit"
                >
                  {forward.label}
                </Button>
              </FormLayout.Footer>
            </FormLayout>
          </Form>
        )}
      </Formik>
    );
  }
}

export default FormStudioLocations;
