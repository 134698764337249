/* eslint-disable max-len */
import React from "react";

const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="currentColor" d="M11.9990958,0 C5.37215981,0 0,5.36462443 0,11.982265 C0,17.9028073 4.3272174,22.9103587 10.1254171,23.8189948 L11.2804499,24 L11.2804499,14.1586434 L8.487,14.1581129 L8.487,12.9807667 L11.2804499,12.9808624 L11.2804499,9.56240901 C11.2804499,7.4951569 12.3377561,6.3670624 14.2291102,6.29172087 L14.5728571,6.28868917 L14.9162877,6.29899248 C15.1494615,6.30940835 15.3903358,6.32671828 15.6344753,6.34972265 L15.897,6.37704253 L15.897,7.20787551 L15.5101789,7.2080142 C13.7101771,7.2080142 12.7176501,8.35409675 12.7176501,9.92188565 L12.7176501,12.9808624 L15.603,12.9807667 L15.422,14.1581129 L12.7176501,14.1586434 L12.7176501,24 L13.8726805,23.8189952 C19.6709318,22.9103653 23.9981,17.9028396 23.9981,11.982265 C23.9981,5.36462167 18.6259924,0 11.9990958,0 Z M11.9990958,1.99719466 C17.5214188,1.99719466 21.9981,6.4676377 21.9981,11.982265 L21.9942643,12.2606827 C21.8743284,16.6092885 18.9535865,20.3209793 14.8946628,21.5427789 L14.717,21.59267 L14.717,16.1553076 L17.138854,16.1558381 L17.933278,10.9836677 L14.717,10.983572 L14.7176501,9.92188565 C14.7176501,9.39787908 14.8845058,9.20520885 15.5101789,9.20520885 L17.8970856,9.20520885 L17.8970856,4.66098825 L17.0653131,4.51923726 C16.8913185,4.48958503 16.6002909,4.44680446 16.2319869,4.40433748 C15.6777197,4.34042819 15.125067,4.29921786 14.6016148,4.29170098 L14.4349366,4.29048747 L14.1967068,4.29447424 C11.2069118,4.3952987 9.28044987,6.40189517 9.28044987,9.56240901 L9.28,10.983572 L6.48773497,10.9836677 L6.48773497,16.1558381 L9.28,16.1553076 L9.28,21.59267 L9.1034502,21.5427773 C4.95819297,20.2949742 2,16.450186 2,11.982265 C2,6.46764458 6.47672931,1.99719466 11.9990958,1.99719466 Z" />
  </svg>
);

export default FacebookIcon;
