import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Fab,
} from "@material-ui/core";

import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingBottom: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: "cover",
    display: "block",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  button: {
    background: "white",
    boxShadow: theme.shadows[2],
    borderRadius: "50%",
    position: "absolute",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    zIndex: 1,
  },
}));

function Video(props) {
  const {
    source, poster, autoplay, noAudio,
  } = props;
  const classes = useStyles();

  const videoEl = React.useRef(null);
  const [muted, setMuted] = React.useState(autoplay);
  const [playing, setPlaying] = React.useState(autoplay);
  const [clickedBefore, setClickedBefore] = React.useState(false);

  const togglePlayback = () => {
    if (playing) {
      videoEl.current.pause();
      setPlaying(false);
    } else {
      videoEl.current.play();
      setPlaying(true);
    }
  };

  const toggleMuted = () => {
    if (clickedBefore) {
      setMuted((prevMuted) => !prevMuted);
    } else {
      setClickedBefore(true);

      setMuted(false);
      videoEl.current.currentTime = 0;
    }
  };

  const buttonMarkup = noAudio ? null : autoplay ? (
    <Fab className={classes.button} onClick={toggleMuted}>
      {!clickedBefore ? <PlayIcon /> : muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
    </Fab>
  ) : (
    <Fab className={classes.button} onClick={togglePlayback}>
      {playing ? <PauseIcon /> : <PlayIcon /> }
    </Fab>
  );

  return (
    <div className={classes.wrapper}>
      {buttonMarkup}
      <video
        ref={videoEl}
        className={classes.video}
        tabIndex="-1"
        preload="auto"
        src={source}
        poster={poster}
        autoPlay={autoplay}
        muted={muted}
        loop
        playsInline
      />
    </div>
  );
}

export default Video;
