import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { FormLayout, Button, Dialog } from "~/src/components/global";

function ConfirmDeleteButton({ label, deleteFunction, isSubmitting }) {
  if (!deleteFunction) return null;

  const [modalOpen, setModalOpen] = React.useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <IconButton aria-label="delete" onClick={toggleModal} color="secondary" disabled={isSubmitting}>
        <DeleteIcon />
      </IconButton>

      <Dialog
        onClose={toggleModal}
        id="view-qualification"
        open={modalOpen}
        title="Are you sure?"
      >
        <FormLayout>
          <Typography>Are you sure you want to delete this {label}?</Typography>
          <FormLayout.Footer>
            <Button onClick={toggleModal} loading={isSubmitting}>Cancel</Button>
            <Button onClick={deleteFunction} color="secondary" loading={isSubmitting}>
              Delete
            </Button>
          </FormLayout.Footer>
        </FormLayout>
      </Dialog>
    </>
  );
}

export default ConfirmDeleteButton;
