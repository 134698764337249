import React from "react";

import ImageIcon from "@material-ui/icons/WallpaperOutlined";
import { Avatar } from "~/src/components/global";

class Preview extends React.Component {
  constructor() {
    super();

    this.state = {
      thumb: undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.image) { return; }

    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ thumb: reader.result });
    };

    reader.readAsDataURL(nextProps.image);
  }

  render() {
    const { image, ...avatarProps } = this.props;
    const { thumb } = this.state;

    if (!image) {
      return (
        <Avatar size="large" {...avatarProps}>
          <ImageIcon fontSize="large" />
        </Avatar>
      );
    }
    return (
      <Avatar
        src={thumb}
        alt={image.name}
        size="large"
        {...avatarProps}
      />
    );
  }
}

export default Preview;
