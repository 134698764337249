import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { StudioGridItem } from "~/src/components/studios";
import {
  FormLayout, InputLocation, InputImageUploader, InputAutocomplete,
} from "~/src/components/global";

export default function InputStudioAutocomplete({
  values, studios, setFieldValue, isSubmitting, ...otherProps
}) {
  const selectedStudio = studios.find((_studio) => _studio.id === values.studio);

  return (
    <FormLayout>
      <Field
        label="Studio Name"
        name="studio"
        newName="newStudioName"
        initialDisplayValue={selectedStudio ? selectedStudio.name : null}
        component={InputAutocomplete}
        options={studios && studios.map((studio) => ({
          value: studio.id,
          label: studio.name,
        }))}
        disabled={isSubmitting}
        {...otherProps}
      />
      {selectedStudio && (
        <StudioGridItem studio={selectedStudio} />
      )}
      {values.studio === "new" && (
        <Field
          label="Website"
          name="newStudioWebsite"
          component={TextField}
        />
      )}
      {values.studio === "new" && (
        <Field
          label="Description"
          name="newStudioDescription"
          component={TextField}
          multiline
        />
      )}
      {values.studio === "new" && (
        <InputImageUploader
          newImage={values.newStudioPhoto}
          setFieldValue={setFieldValue}
          label="Logo"
          name="newStudioPhoto"
          disabled={isSubmitting}
        />
      )}
      {values.studio === "new" && (
        <InputLocation label="Location" disabled={isSubmitting} />
      )}
    </FormLayout>
  );
}
