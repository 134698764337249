const SKILLS = [
  {
    label: "Barre",
    labelEmoji: "👯‍♀️ Barre",
    emoji: "👯‍♀️",
    name: "barre",
  },
  {
    label: "Fitness & HIIT",
    labelEmoji: "🏋️‍♂️ Fitness & HIIT",
    emoji: "🏋️‍♂️",
    name: "hiit",
  },
  {
    label: "Dance",
    labelEmoji: "💃 Dance",
    emoji: "💃",
    name: "dance",
  },
  {
    label: "Kickboxing",
    labelEmoji: "🥊 Kickboxing",
    emoji: "🥊",
    name: "kickboxing",
  },
  {
    label: "Meditation",
    labelEmoji: "🙏 Meditation",
    emoji: "🙏",
    name: "meditation",
  },
  {
    label: "Stretch",
    labelEmoji: "🙆‍♂️ Stretch",
    emoji: "🙆‍♂️",
    name: "mobility",
  },
  {
    label: "Personal Trainer",
    labelEmoji: "💪 Personal Trainer",
    emoji: "💪",
    name: "personalTraining",
  },
  {
    label: "Pilates",
    labelEmoji: "🤸‍♀️ Pilates",
    emoji: "🤸‍♀️",
    name: "pilates",
  },
  {
    label: "Spin & Cycle",
    labelEmoji: "🚴‍♂️ Spin & Cycle",
    emoji: "🚴‍♂️",
    name: "spin",
  },
  {
    label: "Yoga",
    labelEmoji: "🧘‍♀️ Yoga",
    emoji: "🧘‍♀️",
    name: "yoga",
  },
];

export default SKILLS;
