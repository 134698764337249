import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import * as yup from "yup";

import {
  Divider,
  FormControl,
  MenuItem,
  FormGroup,
  Typography,
} from "@material-ui/core";
import SKILLS from "~/src/constants/skills";
import EXAMPLE_INTERESTS from "~/src/constants/interests";

import { firebase } from "~/src/api";

import {
  FormLayout,
  Button,
  Link,
  ArrayEditor,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  interests: yup.array().of(
    yup.string().required("This can’t be blank"),
  ),
});

function TeacherFormSkills(props) {
  const {
    teacher, back, forward,
  } = props;

  let skillsConverted = {};
  if (teacher.skills) {
    skillsConverted = teacher.skills;
  } else {
    for (const skill of SKILLS) {
      skillsConverted[skill.name] = false;
    }
  }

  return (
    <Formik
      initialValues={{
        skills: skillsConverted,
        yearsOfExperience: teacher.yearsOfExperience || [0],
        interests: teacher.interests || ["", "", ""],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const { interests, skills, yearsOfExperience } = values;

        firebase.teacher(teacher.authID).set(
          {
            skills,
            yearsOfExperience,
            interests: interests || [],
          },
          { merge: true },
        ).then(() => {
          Router.push(forward.route, forward.routeAs);
        });
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <FormLayout>
            <FormControl>
              <FormGroup row>
                {SKILLS.map((SKILL, index) => (
                  <Field
                    Label={{ label: SKILL.labelEmoji }}
                    name={`skills.${SKILL.name}`}
                    component={CheckboxWithLabel}
                    key={index}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <Divider />

            <Field
              label="Years of Experience"
              name="yearsOfExperience"
              component={TextField}
              select
            >
              <MenuItem value={0} key={0}>
                Less than 1 year / Haven’t taught yet
              </MenuItem>
              {[...Array(5)].map((x, i) => (
                <MenuItem value={i + 1} key={i + 1}>
                  {i + 1} year{i >= 1 && "s"}
                </MenuItem>
              ))}
              <MenuItem value={6} key={6}>
                6-9 years
              </MenuItem>
              <MenuItem value={10} key={10}>
                10+ years
              </MenuItem>
            </Field>

            <Divider />

            <Typography variant="h6">Interests</Typography>

            <ArrayEditor
              name="interests"
              buttonText="Add Interest"
              array={values.interests}
              disabled={isSubmitting}
              placeholders={EXAMPLE_INTERESTS}
            />
            <FormLayout.Footer>
              {back && back.route && (
              <Link href={back.route} as={back.routeAs} unstyled>
                <Button>{back.label}</Button>
              </Link>
              )}

              <Button
                color="primary"
                loading={isSubmitting}
                type="submit"
              >
                {forward.label}
              </Button>
            </FormLayout.Footer>
          </FormLayout>
        </Form>
      )}
    </Formik>
  );
}

export default TeacherFormSkills;
