import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  spaceChildren: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  spaceChildrenTight: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  spaceChildrenLoose: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  fill: {
    flexGrow: 1,
  },
}));

export default function Item(props) {
  const { children, fill, spaceChildren } = props;
  const classes = useStyles();

  const className = classNames(
    spaceChildren === true && classes.spaceChildren,
    spaceChildren === "tight" && classes.spaceChildrenTight,
    spaceChildren === "loose" && classes.spaceChildrenLoose,
    fill && classes.fill,
  );

  return <div className={className}>{children}</div>;
}
