/* eslint-disable no-await-in-loop */
import React from "react";
import { List, Divider, Snackbar } from "@material-ui/core";
import {
  Flex, FormLayout, Button, Link,
} from "~/src/components/global";
import { TeacherDisplayQualification, TeacherDisplaySafetyCheck } from "~/src/components/teachers";
import SAFETY_CHECKS from "~/src/constants/safetyChecks";
import QualificationDialog from "./QualificationDialog";
import SafetyCheckDialog from "./SafetyCheckDialog";

function TeacherFormQualifications(props) {
  const { teacher, back, forward } = props;
  const [qualificationModalOpen, setQualificationModalOpen] = React.useState(false);
  const [qualification, setQualification] = React.useState(null);
  const [safetyCheckModalOpen, setSafetyCheckModalOpen] = React.useState(false);
  const [safetyCheck, setSafetyCheck] = React.useState(null);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const openEditQualificationDialog = (qualification) => {
    setQualificationModalOpen(true);
    setQualification(qualification);
  };

  const openEditSafetyCheckDialog = (safetyCheck) => {
    setSafetyCheckModalOpen(true);
    setSafetyCheck(safetyCheck);
  };

  const closeModals = () => {
    setQualificationModalOpen(false);
    setSafetyCheckModalOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage("");
  };

  const renderSafetyCheck = (SAFETY_CHECK) => {
    const safetyCheckValues = teacher.safetyChecks && teacher.safetyChecks[SAFETY_CHECK.name];
    const safetyCheck = {
      ...SAFETY_CHECK,
      ...safetyCheckValues,
    };

    return safetyCheckValues && safetyCheckValues.active ? (
      <Flex key={SAFETY_CHECK.name}>
        <List>
          <TeacherDisplaySafetyCheck safetyCheck={safetyCheck} />
        </List>
        <Button onClick={() => openEditSafetyCheckDialog(safetyCheck)}>Edit</Button>
      </Flex>
    ) : (
      <Button variant="text" color="primary" onClick={() => openEditSafetyCheckDialog(safetyCheck)} key={SAFETY_CHECK.name}>Add {SAFETY_CHECK.label}</Button>
    );
  };

  return (
    <FormLayout>
      {SAFETY_CHECKS.map((SAFETY_CHECK) => (
        renderSafetyCheck(SAFETY_CHECK)
      ))}

      <Divider />

      {teacher.qualifications && teacher.qualifications.map((qualification) => (
        <Flex key={qualification.id}>
          <List>
            <TeacherDisplayQualification qualification={qualification} />
          </List>
          <Button onClick={() => openEditQualificationDialog(qualification)}>Edit</Button>
        </Flex>
      ))}

      <Button variant="text" color="primary" onClick={() => openEditQualificationDialog()}>Add Qualification</Button>

      <SafetyCheckDialog
        teacher={teacher}
        modalOpen={safetyCheckModalOpen}
        closeModals={closeModals}
        safetyCheck={safetyCheck}
        safetyChecks={teacher.safetyChecks}
        setSnackbarMessage={setSnackbarMessage}
      />

      <QualificationDialog
        teacher={teacher}
        modalOpen={qualificationModalOpen}
        closeModals={closeModals}
        qualification={qualification}
        qualifications={teacher.qualifications}
        setSnackbarMessage={setSnackbarMessage}
      />

      <Snackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />

      <FormLayout.Footer>
        {back && back.route && (
          <Link href={back.route} as={back.routeAs} unstyled>
            <Button>{back.label}</Button>
          </Link>
        )}

        {forward && forward.route && (
          <Link href={forward.route} as={forward.routeAs} unstyled>
            <Button color="primary">{forward.label}</Button>
          </Link>
        )}
      </FormLayout.Footer>
    </FormLayout>
  );
}

export default TeacherFormQualifications;
