import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import * as yup from "yup";
import { FormControl, FormGroup } from "@material-ui/core";
import * as ROUTES_ADMIN from "~/src/constants/routesAdmin";
import { firebase, uploadPhoto, createStudio } from "~/src/api";
import SKILLS from "~/src/constants/skills";
import {
  Button,
  Banner,
  Link,
  FormLayout,
  InputImageUploader,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  website: yup.string().required(),
  description: yup.string(),
});

function AdminStudioForm(props) {
  const { back, forward, studio } = props;
  const [info, setInfo] = React.useState(false);
  const [error, setError] = React.useState(false);

  const stylesArrayToBooleanObject = {};
  for (const skill of SKILLS) {
    stylesArrayToBooleanObject[skill.name] = !!(studio && studio.styles && studio.styles.includes(skill.name));
  }

  return (
    <Formik
      initialValues={{
        name: studio && studio.name || "",
        website: studio && studio.website || "",
        description: studio && studio.description || "",
        photo: studio && studio.photo || "",
        newPhoto: "",
        styles: stylesArrayToBooleanObject,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const {
          name, website, description,
        } = values;

        // Upload new photo if there is one.
        const photo = values.newPhoto ? await uploadPhoto({
          photo: values.newPhoto,
          subdirectory: "studios",
          setInfo,
          setError,
        }) : values.photo;

        // Convert boolean object to array
        const booleanObjectToStylesArray = [];
        for (const skill of SKILLS) {
          if (values.styles[skill.name]) {
            booleanObjectToStylesArray.push(skill.name);
          }
        }

        if (studio) {
          await firebase.studio(studio.id).update({
            name,
            photo,
            website,
            description,
            styles: booleanObjectToStylesArray,
          });

          Router.push(ROUTES_ADMIN.STUDIOS);
        } else {
          const studioRef = await createStudio({
            name,
            photo,
            website,
            description,
            styles: booleanObjectToStylesArray,
          });

          Router.push(ROUTES_ADMIN.STUDIOS_DETAIL_EDIT_LOCATIONS, `/admin/studios/${studioRef.id}/edit-locations`);
        }
      }}
    >
      {({
        isSubmitting, values, setFieldValue,
      }) => (
        <Form>
          <FormLayout>
            {info && <Banner status="info">{info}</Banner>}
            {error && <Banner status="danger">{error.message}</Banner>}

            <Field
              label="Name"
              name="name"
              component={TextField}
            />

            <InputImageUploader
              oldImage={values.photo}
              newImage={values.newPhoto}
              setFieldValue={setFieldValue}
              label="Logo"
              name="newPhoto"
              disabled={isSubmitting}
            />

            <Field
              label="Website"
              name="website"
              component={TextField}
            />

            <Field
              label="Description"
              name="description"
              component={TextField}
              multiline
            />

            <FormControl>
              <FormGroup row>
                {SKILLS.map((SKILL, index) => (
                  <Field
                    Label={{ label: SKILL.labelEmoji }}
                    name={`styles.${SKILL.name}`}
                    component={CheckboxWithLabel}
                    key={index}
                  />
                ))}
              </FormGroup>
            </FormControl>

            <FormLayout.Footer>
              {back && (
                <Link href={ROUTES_ADMIN.STUDIOS} unstyled>
                  <Button>{back}</Button>
                </Link>
              )}

              <Button
                color="primary"
                loading={isSubmitting}
                type="submit"
              >
                {forward}
              </Button>
            </FormLayout.Footer>
          </FormLayout>
        </Form>
      )}
    </Formik>
  );
}

export default AdminStudioForm;
