import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar } from "@material-ui/core";
import * as ROUTES from "~/src/constants/routes";
import { Button, Container, Link } from "~/src/components/global";
import { InlineNavigation, MenuLoggedIn, MenuLoggedOut } from "./components";

import logo from "./logo.svg";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: "none",
    paddingTop: theme.spacing(2),
  },
  flex: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    width: 70,
    order: 0,
  },
  button: {
    order: 2,
    "@media (min-width: 500px)": {
      order: 3,
    },
  },
  postJob: {
    order: 1,
    marginLeft: "auto",
    marginRight: theme.spacing(),
    "@media (min-width: 500px)": {
      order: 2,
    },
  },
  inlineNav: {
    order: 3,
    width: "100%",
    paddingTop: theme.spacing(1),
    "@media (min-width: 500px)": {
      order: 1,
      width: "auto",
      paddingTop: 0,
      marginLeft: theme.spacing(3),
      marginRight: "auto",
    },
  },
}));

const Header = ({ authUser }) => {
  const classes = useStyles();

  return (
    <Container disableMargins size="lg">
      <AppBar position="static" color="inherit" className={classes.appbar}>
        <div className={classes.flex}>
          <Link href={ROUTES.HOME}>
            <img className={classes.logo} alt="Bolstr" src={logo} />
          </Link>

          <div className={classes.postJob}>
            <Link href={ROUTES.JOB_ADD} unstyled>
              <Button color="primary" fullWidth>Post Job</Button>
            </Link>
          </div>

          <div className={classes.button}>
            { authUser
              ? <MenuLoggedIn authUser={authUser} />
              : <MenuLoggedOut />}
          </div>

          <div className={classes.inlineNav}>
            <InlineNavigation authUser={authUser} />
          </div>
        </div>
      </AppBar>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Header);
