import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { firebase } from "~/src/api";
import JOB_PLACEHOLDERS from "~/src/constants/jobPlaceholders";
import SKILLS from "~/src/constants/skills";
import LOCATION_INITIAL_VALUES from "~/src/constants/initialValues/location";

import {
  Button,
  Link,
  FormLayout,
  InputLocation,
  InputSelect,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  name: yup.string().required("Job name is required"),
  description: yup.string(),
});

class FormJob extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholderIndex: Math.floor(Math.random() * JOB_PLACEHOLDERS.NAME.length),
    };
  }

  saveNewJob = async (data) => {
    const { authUser } = this.props;

    const jobRef = firebase.jobs().doc();
    await jobRef.set({
      ...data,
      authID: authUser.authID,
      studio: authUser.studio ? authUser.studio : null,
      timestamp: firebase.fieldValue.serverTimestamp(),
      closed: false,
    });

    Router.push("/jobs/[id]", `/jobs/${jobRef.id}`);
  }

  updateJob = async (data) => {
    const { job, forward } = this.props;

    await firebase.GeoFirestore.collection("jobs").doc(job.id).update({
      ...data,
      coordinates: new firebase.firestore.GeoPoint(data.location.latitude, data.location.longitude),
    });

    Router.push(forward.route, forward.routeAs);
  }

  renderLocationInput(values, locationOptions, isSubmitting) {
    const { job } = this.props;

    return job || values.useStudioLocation === "no" ? (
      <InputLocation label="Location" disabled={isSubmitting} />
    ) : (
      <Field
        name="useStudioLocation"
        label="Location"
        component={InputSelect}
        options={locationOptions}
      />
    );
  }

  render() {
    const {
      back, forward, job, studioLocations,
    } = this.props;
    const { placeholderIndex } = this.state;

    const locationOptions = [{
      value: "no",
      label: "--New Location--",
    }];
    studioLocations && studioLocations.forEach((location) => { // eslint-disable-line no-unused-expressions
      locationOptions.push({
        value: location.id,
        label: location.label,
      });
    });

    return (
      <Formik
        initialValues={{
          name: job && job.name || "",
          type: job && job.type || "yoga",
          useStudioLocation: !job && studioLocations && studioLocations.length ? studioLocations[0].id : "no",
          location: job && job.location || LOCATION_INITIAL_VALUES,
          source: job && job.source || {
            type: "bolstr",
            link: "",
            firstname: "",
          },
          description: job && job.description || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const {
            name, type, location, useStudioLocation, description, source, // eslint-disable-line camelcase
          } = values;

          const useLocationInput = job || useStudioLocation === "no";
          const locationToUse = useLocationInput ? location : studioLocations.find((studioLocation) => studioLocation.id === useStudioLocation);

          const data = {
            name,
            type,
            location: locationToUse,
            description,
            source,
          };

          if (job) {
            this.updateJob(data);
          } else {
            this.saveNewJob(data);
          }
        }}
      >
        {({
          isSubmitting, values,
        }) => (
          <Form>
            <FormLayout>
              <FormLayout.Group>
                <Field
                  label="Job Name"
                  name="name"
                  component={TextField}
                  placeholder={JOB_PLACEHOLDERS.NAME[placeholderIndex]}
                />

                <Field
                  label="Job Type"
                  name="type"
                  component={InputSelect}
                  options={SKILLS.map((skill) => ({
                    value: skill.name,
                    label: skill.labelEmoji,
                  }))}
                />
              </FormLayout.Group>

              {this.renderLocationInput(values, locationOptions, isSubmitting)}

              <Field
                label="Description"
                name="description"
                component={TextField}
                multiline
                placeholder={JOB_PLACEHOLDERS.DESCRIPTION[placeholderIndex]}
              />

              <FormLayout.Footer>
                {back && back.route && (
                <Link href={back.route} as={back.routeAs} unstyled>
                  <Button>{back.label}</Button>
                </Link>
                )}

                <Button
                  color="primary"
                  loading={isSubmitting}
                  type="submit"
                >
                  {forward.label}
                </Button>
              </FormLayout.Footer>
            </FormLayout>
          </Form>
        )}
      </Formik>
    );
  }
}

export default FormJob;
