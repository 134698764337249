import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "100%",
  },
  title: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  noPadding: {
    paddingBottom: 0,
  },
}));

function Dialog(props) {
  const classes = useStyles();
  const {
    onClose, title, subtitle, id, children, open, noPadding, maxWidth,
  } = props;

  return (
    <MuiDialog scroll="paper" onClose={onClose} aria-labelledby={id} open={open} maxWidth={maxWidth || "xs"} className={classes.dialog}>
      <DialogTitle id={id} disableTypography className={classes.title}>
        <Typography variant="h5" component="h2">{title}</Typography>
        <Typography color="textSecondary">{subtitle}</Typography>

        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers className={noPadding ? classes.noPadding : undefined}>
        {children}
      </DialogContent>
    </MuiDialog>
  );
}

export default Dialog;
