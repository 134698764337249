import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Truncate from "react-truncate";
import { Button } from "~/src/components/global";

const NUM_LINES = 3;

const useStyles = makeStyles((theme) => ({
  truncate: {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": NUM_LINES,
    boxOrient: "vertical",
    lineClamp: NUM_LINES,
  },
  paragraph: {
    margin: 0,
    "& + $paragraph": {
      marginTop: theme.spacing(),
    },
  },
}));

const Description = (props) => {
  const classes = useStyles();
  const { color, text, variant } = props;

  const [expanded, setExpanded] = React.useState(false);
  const [isTruncated, setIsTruncated] = React.useState(false);

  if (!text || !text.length) return null;

  return expanded ? (
    <>
      <Typography variant={variant || "body2"} component="div" color={color}>
        {text.split("\n").map((paragraph, i) => (
          <p className={classes.paragraph} key={i}>
            {" "}{paragraph}
          </p>
        ))}
      </Typography>
      <Button variant="text" color="primary" onClick={() => setExpanded(false)}>
        Read less
      </Button>
    </>
  ) : (
    <>
      <Typography variant={variant || "body2"} component="div" color={color} className={classes.truncate}>
        <Truncate
          lines={NUM_LINES}
          onTruncate={(value) => setIsTruncated(value)}
        >
          {text.split("\n").map((paragraph, i) => (
            <span className={classes.paragraph} key={i}>
              {" "}{paragraph}
            </span>
          ))}
        </Truncate>
      </Typography>
      {isTruncated && (
        <Button variant="text" color="primary" onClick={() => setExpanded(true)}>
          Read more
        </Button>
      )}
    </>
  );
};

export default Description;
