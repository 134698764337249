import React from "react";
import { getThumbnailURL } from "~/src/api";
import { ListItem } from "~/src/components/global";
import { TeacherDisplaySkills } from "~/src/components/teachers";

function ListItemTeacher(props) {
  const {
    teacher,
    onClick,
    disabled,
    ListItemOverrides,
    dataCy,
  } = props;

  if (!teacher) return null;

  return (
    <ListItem
      imageSrc={getThumbnailURL({ url: teacher.photo })}
      primary={teacher.firstname}
      secondary={<TeacherDisplaySkills teacher={teacher} minimal />}
      href="/teachers/[username]"
      as={`/teachers/${teacher.username}`}
      onClick={onClick}
      disabled={disabled}
      ListItemOverrides={ListItemOverrides}
      dataCy={dataCy}
    />
  );
}

export default ListItemTeacher;
