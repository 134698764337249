import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {
  Button, Link, Flex, Spacing,
} from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  strong: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function TeacherPitchHero({
  addressTo, job, teacher, user,
}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h1" gutterBottom>Hi {addressTo}!</Typography>

      <Flex spaceChildren="tight" wrap>
        <Typography variant="h3" color="textSecondary">
          I’m <strong className={classes.strong}>{teacher.firstname} {teacher.lastname}</strong>, and I’m thrilled to
          <br />
          apply for your <strong className={classes.strong}>{job.name}</strong> job.
        </Typography>
        <Link unstyled href={`mailto:${user.email}`} external>
          <Button color="primary" size="large">Email {teacher.firstname}</Button>
        </Link>
      </Flex>

      <Spacing tight />
    </>
  );
}

export default TeacherPitchHero;
