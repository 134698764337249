import React from "react";
import TimeAgo from "react-timeago";
import { Chip, Typography } from "@material-ui/core";
import { getThumbnailURL } from "~/src/api";
import { renderLocation } from "~/src/utils";
import { Avatar, GridItem } from "~/src/components/global";
import SKILLS from "~/src/constants/skills";

function JobGridItem(props) {
  const {
    job,
    onClick,
    disabled,
    GridItemOverrides,
  } = props;

  if (!job) return null;

  const { teacher, studio } = job;

  const jobType = SKILLS.find((skill) => skill.name === job.type);

  const avatarMarkup = ((teacher && teacher.photo) || (studio && studio.photo)) ? (
    <Avatar
      alt={job.name}
      src={getThumbnailURL({
        alt: job.name,
        url: studio ? studio.photo : teacher.photo,
        size: 128,
      })}
    />
  ) : jobType ? (
    <Avatar>{jobType.emoji}</Avatar>
  ) : null;

  const timestampMarkup = job.timestamp ? (
    <Typography display="inline" component="span" color="textSecondary">
      {" "}· <TimeAgo live={false} date={job.timestamp && job.timestamp.seconds && new Date(job.timestamp.seconds * 1000)} />
    </Typography>
  ) : null;

  const locationMarkup = (
    <>
      {renderLocation(job.location)}
      {timestampMarkup}
    </>
  );

  return (
    <GridItem
      image={avatarMarkup}
      title={job.name}
      badge={job.closed && <Chip label="Filled" />}
      secondary={studio && studio.name}
      tertiary={jobType && jobType.label}
      description={job.description}
      location={locationMarkup}
      href="/jobs/[id]"
      as={`/jobs/${job.id}`}
      onClick={onClick}
      disabled={disabled}
      GridItemOverrides={GridItemOverrides}
      dataCy="grid-item-job"
    />
  );
}

export default JobGridItem;
