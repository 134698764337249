import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputBase, InputAdornment, Hidden, TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Card } from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  filter: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize,
    height: theme.shape.actionHeightSmall,
    display: "inline-flex",
    alignItems: "center",
    boxShadow: `0 0 0 1px ${theme.palette.border.base}`,
    background: theme.palette.background.paper,
    borderRadius: 100,
    padding: [[0, theme.spacing(2)]],
    "&:hover": {
      background: theme.palette.background.paper,
      boxShadow: `0 0 0 1px ${theme.palette.border.dark}`,
    },
    "@media (max-device-width: 600px)": {
      fontSize: 16, // Prevent zooming in on iPhones.
    },
  },
  disabled: {
    color: [["inherit"], "!important"],
    backgroundColor: [[theme.palette.background.paper], "!important"],
    opacity: 0.5,
    pointerEvents: "none",
  },
  active: {
    background: theme.palette.background.dark,
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: [[`0 0 0 1px ${theme.palette.text.primary}, inset 0 0 0 0.5px ${theme.palette.text.primary}`], "!important"],
    borderWidth: 2,
  },
  icon: {
    color: theme.palette.text.secondary,
    fontSize: 24,
    height: theme.shape.actionHeightSmall,
    width: theme.shape.actionHeightSmall,
    padding: 6,
    marginRight: -12,
    "&:hover": {
      color: theme.palette.text.primary,
      cursor: "pointer",
    },
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value);
  const {
    onChange, disabled, placeholder,
  } = props;

  const handleClear = () => {
    setValue("");
    onChange("");
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    if (value !== props.value) {
      onChange(value);
    }
  };

  const submitForm = (event) => {
    event.preventDefault();
    onChange(value);
  };

  const filterClasses = classNames(
    classes.filter,
    value && value.length && classes.active,
  );

  const inputProps = {
    classes: {
      disabled: classes.disabled,
    },
    value,
    disabled,
    onBlur: handleBlur,
    onChange: handleChange,
    placeholder,
    inputProps: {
      spellcheck: "false",
      autocorrect: "off",
    },
  };

  return (
    <form action="#" onSubmit={submitForm}>
      <Hidden xsDown>
        <Card title="Studio Name">
          <TextField
            {...inputProps}
            InputProps={{
              endAdornment: value && value.length && (
                <InputAdornment>
                  <CloseIcon className={classes.icon} fontSize="small" onClick={handleClear} />
                </InputAdornment>
              ),
            }}
          />
        </Card>
      </Hidden>
      <Hidden smUp>
        <InputBase
          {...inputProps}
          className={filterClasses}
          endAdornment={value && value.length && (
            <InputAdornment>
              <CloseIcon className={classes.icon} fontSize="small" onClick={handleClear} />
            </InputAdornment>
          )}
        />
      </Hidden>
    </form>
  );
};

export default Filter;
