import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField } from "@material-ui/core";
import {
  Button, Dialog, Spacing, Link,
} from "~/src/components/global";
import PitchPreview from "./PitchPreview";
import IncompleteProfileBanner from "./IncompleteProfileBanner";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    marginTop: theme.spacing(),
  },
  textfield: {
    flexGrow: 1,
    marginRight: theme.spacing(),
  },
}));

function ApplyViaFacebookPost(props) {
  const classes = useStyles();
  const {
    selfTeacher,
    pitchUrl,
    textareaRef,
    messageCopied,
    modalOpen,
    toggleModal,
    copyToClipboard,
    job,
  } = props;

  return (
    <Dialog
      onClose={toggleModal}
      id="apply-for-job"
      open={modalOpen}
      title="Apply for Job"
      subtitle="On Facebook’s job board."
      maxWidth="sm"
    >
      <IncompleteProfileBanner teacher={selfTeacher} />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">1. Copy Application Link</Typography>

          <div className={classes.flex}>
            <div className={classes.textfield}>
              <TextField
                value={`${process.env.URL_PREFIX}${pitchUrl}`}
                inputRef={textareaRef}
              />
            </div>
            <Button type="submit" color={messageCopied ? undefined : "primary"} onClick={copyToClipboard}>
              {messageCopied ? "✓ Copied!" : "Copy"}
            </Button>
          </div>

          <Spacing extraTight />
          <PitchPreview url={pitchUrl} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>2. Paste Link in Ad</Typography>
          <Link href={job.source.link} external unstyled>
            <Button color={messageCopied ? "primary" : undefined} fullWidth>
              Go to Ad →
            </Button>
          </Link>
          <Spacing extraTight />
          <Typography color="textSecondary" gutterBottom>If possible. Not all ads let you do this.</Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ApplyViaFacebookPost;
