import React from "react";
import { Typography } from "@material-ui/core";
import { Spacing, Strong, Link } from "~/src/components/global";
import { JobGridItem } from "~/src/components/jobs";
import SKILLS from "~/src/constants/skills";

export default function JobPreview({
  values, studios, location,
}) {
  const studio = studios.find((_studio) => _studio.id === values.studio);
  const job = {
    name: values.name || "Job Preview",
    type: values.type,
    description: values.description,
    location: location && location.label ? location : null,
    studio: studio || {
      name: values.newStudioName,
    },
  };

  const handleClick = () => {};

  const { type, firstname, link } = values.source;
  let linkLabel = "Send applications to";
  let linkValue = link;
  if (type === "facebookJob") {
    linkLabel = "Facebook Job";
    linkValue = <Link href={link} external>{link}</Link>;
  } else if (type === "facebookPost") {
    linkLabel = "Facebook Post";
    linkValue = <Link href={link} external>{link}</Link>;
  } else if (type === "website") {
    linkLabel = "Website";
    linkValue = <Link href={link} external>{link}</Link>;
  }

  const firstnameMarkup = firstname ? (
    <>
      <Typography>
        <Strong>Contact name</Strong>
      </Typography>
      <Typography color="textSecondary">
        {firstname}
      </Typography>

      <Spacing tight />
    </>
  ) : null;

  const skill = SKILLS.find((skill) => skill.name === job.type);

  return (
    <>
      <JobGridItem
        job={job}
        onClick={handleClick}
      />

      <Spacing tight />

      <Typography>
        <Strong>Job type</Strong>
      </Typography>
      <Typography color="textSecondary">
        {skill && skill.label}
      </Typography>

      <Spacing tight />

      {firstnameMarkup}

      <Typography>
        <Strong>{linkLabel}</Strong>
      </Typography>
      <Typography color="textSecondary">
        {linkValue}
      </Typography>
    </>
  );
}
