import React from "react";
import { Field } from "formik";
import { TextField, RadioGroup } from "formik-material-ui";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
} from "@material-ui/core";
import {
  Card, FormLayout, Spacing,
} from "~/src/components/global";
import JOB_PLACEHOLDERS from "~/src/constants/jobPlaceholders";
import SKILLS from "~/src/constants/skills";

export default function JobFields({ placeholderIndex, errors, isSubmitting }) {
  // const typeMarkup = (
  //   <FormControl>
  //     <FormLabel>Skill required</FormLabel>
  //     <Spacing />
  //     <FormGroup row>
  //       {SKILLS.map((SKILL, index) => (
  //         <Field
  //           Label={{ label: SKILL.labelEmoji }}
  //           name={`skills.${SKILL.name}`}
  //           component={CheckboxWithLabel}
  //           key={index}
  //         />
  //       ))}
  //     </FormGroup>
  //   </FormControl>
  // );

  const typeMarkup = (
    <FormControl error={Boolean(errors.type)}>
      <FormLabel>Skillset</FormLabel>
      <Spacing extraTight />

      <Field component={RadioGroup} name="type" row>
          {SKILLS.map((SKILL) => (
            <FormControlLabel
              key={SKILL.name}
              value={SKILL.name}
              control={<Radio disabled={isSubmitting} />}
              label={SKILL.labelEmoji}
              disabled={isSubmitting}
            />
          ))}
      </Field>

      <FormHelperText>{errors.type}</FormHelperText>
    </FormControl>
  );

  return (
    <>
    <Card title="Job">
      <FormLayout>

        <Field
          label="Job Name"
          name="name"
          component={TextField}
          placeholder={JOB_PLACEHOLDERS.NAME[placeholderIndex]}
        />

        <Field
          label="Description"
          name="description"
          component={TextField}
          multiline
          placeholder={JOB_PLACEHOLDERS.DESCRIPTION[placeholderIndex]}
        />

        {typeMarkup}
      </FormLayout>
    </Card>
    </>
  );
}
