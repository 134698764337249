import React from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  Loading, Banner, Button, Spacing,
} from "~/src/components/global";
import { Filter, FilterLocation } from "~/src/components/shared";
import { getFilteredDocs, getFilteredGeoDocs } from "~/src/api";

import SKILLS from "~/src/constants/skills";

const PAGE_SIZE = 100;

const disciplineOptions = SKILLS.map((skill) => ({
  value: skill.name,
  label: skill.label,
  labelEmoji: skill.labelEmoji,
}));

class WeeklyRoundupList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      radiusIndex: 0,
      weeksOld: 1,
      moreToLoad: true,
      lastVisible: null,
      results: [],
      error: null,
      filterValueDiscipline: null,
      filterValueLatitude: "",
      filterValueLongitude: "",
    };
  }

  loadData = async () => {
    const {
      results, lastVisible, radiusIndex, weeksOld, filterValueDiscipline, filterValueLatitude, filterValueLongitude,
    } = this.state;
    const {
      label, promiseFunction, sortFunction, defaultQuery, filteredQuery, geoQuery,
    } = this.props;

    this.setState({ loading: true });

    const useGeoQuery = Boolean(filterValueLatitude) && Boolean(filterValueLongitude);
    const useFilteredQuery = Boolean(filterValueDiscipline) || useGeoQuery;
    const query = useGeoQuery ? geoQuery : useFilteredQuery ? filteredQuery : defaultQuery;
    const queryData = {
      label,
      query,
      promiseFunction,
      sortFunction,
      results,
      lastVisible,
      radiusIndex,
      weeksOld,
      filters: {
        discipline: filterValueDiscipline,
        latitude: filterValueLatitude,
        longitude: filterValueLongitude,
      },
      pageSize: PAGE_SIZE,
    };
    const result = useGeoQuery ? await getFilteredGeoDocs(queryData) : await getFilteredDocs(queryData);

    this.setState({
      loading: false,
      results: result.results,
      radiusIndex: result.radiusIndex,
      lastVisible: result.lastVisible,
      moreToLoad: result.moreToLoad,
    });
  }

  componentDidMount = async () => {
    this.loadData();
  }

  handleChangeDiscipline = async (event) => {
    this.setState({
      filterValueDiscipline: event.target.value,
      radiusIndex: 0,
      moreToLoad: true,
      lastVisible: null,
      results: [],
      error: null,
    },
    function () {
      this.loadData();
    });
  }

  handleChangeLocation = async (values) => {
    const { filterValueLongitude } = this.state;

    if (values.longitude !== filterValueLongitude) {
      this.setState({
        filterValueLocation: values.name,
        filterValueLatitude: values.latitude,
        filterValueLongitude: values.longitude,
        radiusIndex: 0,
        lastVisible: null,
        results: [],
        error: null,
      },
      function () {
        this.loadData();
      });
    }
  }

  render() {
    const { label } = this.props;
    const {
      results, loading, moreToLoad, error, filterValueLocation, filterValueDiscipline,
    } = this.state;

    const headerLocation = filterValueLocation ? `in ${filterValueLocation}` : "across Australia";
    const headerDiscipline = filterValueDiscipline ? `${SKILLS.find((skill) => skill.name === filterValueDiscipline).label} jobs` : "jobs in health and fitness";

    return (
      <>
        <Typography variant="h1">Weekly Job Round-up</Typography>
        <Typography variant="h3" component="p">
          {results && results.length} {headerDiscipline} {headerLocation} this week! List your job for free on {process.env.URL_PREFIX}/jobs
        </Typography>

        <Spacing />

        {error && <Banner status="danger">{error.message}</Banner>}

        <Grid container>
          <Grid item xs={12} sm={8} md={9}>
            {(loading && !results.length) ? <Loading /> : (
              <>
                {(results && results.length) ? (
                  <Grid container>
                    {results.map((result) => (
                      <Grid item xs={12} key={result.id}>
                        {this.props.listItem(result)}
                      </Grid>
                    ))}
                    {moreToLoad && (
                      <Grid item xs={12}>
                        <Typography align="center">
                          <Button color="primary" onClick={this.loadData} loading={loading}>Load More</Button>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Banner>No {label} found. Try changing your filters.</Banner>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FilterLocation
              onChange={this.handleChangeLocation}
              disabled={loading}
            />

            <Spacing tight />

            <Filter
              value={this.state.filterValueDiscipline}
              options={disciplineOptions}
              onChange={this.handleChangeDiscipline}
              title="Discipline"
              titlePlural="disciplines"
              disabled={loading}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default WeeklyRoundupList;
