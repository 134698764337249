const getDate = (timestamp) => {
  const date = new Date(timestamp.seconds * 1000);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;
  return `${year}-${month}-${day}`;
};

const getHiringOrganization = (studio) => {
  if (studio) {
    const hiringOrganization = {
      "@type": "Organization",
      name: studio.name,
    };
    if (studio.website) {
      hiringOrganization.sameAs = studio.website;
    }
    if (studio.photo) {
      hiringOrganization.logo = studio.photo;
    }
    return hiringOrganization;
  }
  return null;
};

const getLocation = (location) => {
  if (location) {
    return {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressLocality: location.suburb || location.name,
        addressRegion: location.state,
        postalCode: location.postCode,
        addressCountry: location.country,
      },
    };
  }
  return null;
};

const getIdentifier = (job) => {
  const identifier = {
    "@type": "PropertyValue",
    value: job.id,
  };
  if (job.studio) {
    identifier.name = job.studio.name;
  }
  return identifier;
};

const getJobStructuredData = (job) => {
  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: job.name,
    datePosted: getDate(job.timestamp),
    employmentType: "CONTRACTOR",
    identifier: getIdentifier(job),
  };

  if (job.description) {
    structuredData.description = `<p>${`${job.description.split("\n").join("<br>\n")}<br>`}</p>`;
  }

  const hiringOrganization = getHiringOrganization(job.studio);
  if (hiringOrganization) {
    structuredData.hiringOrganization = { ...hiringOrganization };
  }

  const location = getLocation(job.location);
  if (location) {
    structuredData.jobLocation = { ...location };
  }

  return structuredData;
};

export default getJobStructuredData;
