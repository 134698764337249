import React from "react";
import { Chip, Grid, Typography } from "@material-ui/core";
import Error from "~/src/pages/_error.js";
import { getThumbnailURL } from "~/src/api";
import { Spacing, Divider, Description } from "~/src/components/global";
import { DetailHero, BannerReplyTime } from "~/src/components/shared";
import { JobGridItem } from "~/src/components/jobs";
import { StudioDisplayCategories, StudioActionButtons } from "~/src/components/studios";

const StudioDetail = (props) => {
  const { studio, authUser, ActionButtonsOverride } = props;

  if (!studio) {
    return <Error />;
  }

  const descriptionMarkup = studio.description && studio.description.length ? (
    <>
      <Divider />

      <Description text={studio.description} />
    </>
  ) : null;

  const jobsMarkup = studio.jobs && studio.jobs.length ? (
    <>
      <Divider extraLoose />

      <Typography variant="h5" component="h2">Jobs</Typography>

      <Spacing tight />

      <Grid container>
        {studio.jobs && studio.jobs.map((job) => (
          <Grid item xs={12} key={job.id}>
            <JobGridItem
              job={job}
            />
          </Grid>
        ))}
      </Grid>
    </>
  ) : null;

  return (
    <>
      <DetailHero
        image={getThumbnailURL({
          url: studio.photo,
          size: 128,
        })}
        title={studio.name}
        badge={studio.jobs && studio.jobs.length ? <Chip label="Hiring" color="primary" /> : null}
      />

      {ActionButtonsOverride || (
        <StudioActionButtons
          studio={studio}
          authUser={authUser}
        />
      )}

      <BannerReplyTime name={studio.name} avgReplyTime={studio.avgReplyTime} marginTop />

      {studio.styles && (
        <>
          <Spacing />

          <StudioDisplayCategories categories={studio.styles} locations={studio.locations} />
        </>
      )}

      {descriptionMarkup}

      {jobsMarkup}
    </>
  );
};

export default StudioDetail;
