import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { Card, FormLayout, InputRadioButtons } from "~/src/components/global";
import JOB_SOURCES from "~/src/constants/jobSources";

export default function ContactCard({ authUser, isSubmitting, values }) {
  return (
    <Card title="Contact">
      <FormLayout>
        {authUser && authUser.admin && (
          <InputRadioButtons
            label="Source Type (Admin Only)"
            name="source.type"
            options={JOB_SOURCES.map((source) => ({
              value: source.name,
              label: source.label,
            }))}
            isSubmitting={isSubmitting}
          />
        )}

        {values.source.type !== "bolstr" && (
          <Field
            label="Contact First Name"
            name="source.firstname"
            component={TextField}
            placeholder="eg. Jenny"
          />
        )}

        {values.source.type !== "bolstr" && (
          <Field
            label="Contact Email Address"
            name="source.link"
            component={TextField}
            placeholder="eg. jenny@studio.com"
            helperText="Job applications will be sent here."
          />
        )}
      </FormLayout>
    </Card>
  );
}
