import MONTHS_OF_THE_YEAR from "~/src/constants/months";

function getMonthAndYearRange(props) {
  const {
    startDateMonth, startDateYear, endDateMonth, endDateYear, currentlyWorking,
  } = props;

  // Variants
  // null - 2016
  // null - April 2016
  // 2016 - Present
  // April 2016 - Present
  // April - May 2016
  // April 2016 - May 2017

  if (!startDateYear) return null;

  let dateStartText;
  let dateEndText;

  // If we're currentlyWorking
  // or we have both years, and they're different
  // Then we include the start year.
  if (currentlyWorking || (endDateYear && endDateYear > startDateYear)) {
    dateStartText = startDateYear;
  }

  // If we're currentlyWorking
  // Or we have different months/years
  if (startDateMonth && (currentlyWorking || (endDateYear && endDateYear > startDateYear) || (endDateMonth && endDateMonth > startDateMonth))) {
    const dateStartMonthText = MONTHS_OF_THE_YEAR.find((month) => month.value === startDateMonth).label;
    if (dateStartText) {
      dateStartText = `${dateStartMonthText} ${dateStartText}`;
    } else {
      dateStartText = dateStartMonthText;
    }
  }

  // If we're currently working, add Present
  if (currentlyWorking) {
    dateEndText = "Present";
  } else if (endDateYear && endDateMonth) {
    // Otherwise, always add the date and month, if we have them
    const dateEndMonthText = MONTHS_OF_THE_YEAR.find((month) => month.value === endDateMonth).label;
    dateEndText = `${dateEndMonthText} ${endDateYear}`;
  }

  if (dateStartText) {
    return `${dateStartText}–${dateEndText}`;
  }
  return dateEndText;
}

export default getMonthAndYearRange;
