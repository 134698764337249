// !! SHARED ACROSS APP AND CLOUD FUNCTIONS
import * as ROUTES from "~/src/constants/routes";

const getTeacherProfileSections = (teacher) => {
  const sections = [
    {
      label: "Profile",
      completed: Boolean(teacher.firstname),
      link: ROUTES.PROFILE_TEACHER_EDIT_ABOUT,
    },
    {
      label: "Video",
      completed: Boolean(teacher.video),
      link: ROUTES.PROFILE_TEACHER_EDIT_VIDEO,
    },
    {
      label: "Photo Gallery",
      completed: Boolean(teacher.photos && teacher.photos.length >= 4),
      link: ROUTES.PROFILE_TEACHER_EDIT_PHOTOS,
    },
    {
      label: "Location",
      completed: Boolean(teacher.location),
      link: ROUTES.PROFILE_TEACHER_EDIT_LOCATION,
    },
    {
      label: "Skills",
      completed: Boolean(teacher.skills),
      link: ROUTES.PROFILE_TEACHER_EDIT_SKILLS,
    },
    {
      label: "Qualifications",
      completed: Boolean(teacher.qualifications && teacher.qualifications.length),
      link: ROUTES.PROFILE_TEACHER_EDIT_QUALIFICATIONS,
    },
    {
      label: "Experience",
      completed: Boolean(teacher.experiences && teacher.experiences.length),
      link: ROUTES.PROFILE_TEACHER_EDIT_EXPERIENCE,
    },
    {
      label: "Description",
      completed: Boolean(teacher.description),
      link: ROUTES.PROFILE_TEACHER_EDIT_DESCRIPTION,
    },
  ];

  return sections;
};

export default getTeacherProfileSections;
