import React from "react";
import {
  firebase, getChatsForDetailPages, getTeacherData, getStudioClaim, getJobApplications,
} from "~/src/api";
import * as ROUTES from "~/src/constants/routes";

import {
  Button,
  Banner,
  Flex,
  Link,
  Spacing,
} from "~/src/components/global";

import {
  BannerEmailVerification,
  BannerCreateProfile,
  ActionButtonsMessageButton,
  ActionButtonsJobButton,
} from "~/src/components/shared";

import ApplyExternalWrapper from "./ApplyExternalWrapper";
import ClaimButton from "./ClaimButton";

class ActionButtonsJob extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chats: null,
      loading: false,
      sentLoadRequest: false,
      loadingPrimary: false,
    };
  }

  handleToggleJobOpen = async () => {
    const { job, refreshJobData } = this.props;

    this.setState({ loadingPrimary: true });

    await firebase.job(job.id).update({
      closed: !job.closed,
    });
    await refreshJobData();

    this.setState({ loadingPrimary: false });
  }

  loadData = async (authUser) => {
    this.setState({
      loading: true,
      sentLoadRequest: true,
    });

    const { job } = this.props;

    // Get chats between jobOwner and authUser
    // Gets up to 2 chats
    const chats = await getChatsForDetailPages({
      authUser,
      otherID: job.studio ? job.studio.id : job.authID,
    });

    if (chats && chats.length > 0) {
      // Get the chat with the jobOwner and the authUser's teacher
      const chat = chats.find((chat) => chat.members.includes(authUser.authID));

      if (chat) {
        // Check if this job is in the chat already
        const jobID = job.id;
        const jobExistsInChat = chat.jobs && chat.jobs.find((job) => job === jobID);

        this.setState({
          chats,
          applied: !!jobExistsInChat,
        });
      }
    }

    this.setState({
      jobApplications: authUser.admin ? await getJobApplications(job.id) : undefined,
      claim: await getStudioClaim({
        studioID: job.studio.id,
        authUser,
      }),
      selfTeacher: await getTeacherData({
        teacher: await firebase.teacher(authUser.authID).get(),
        withExperiences: true,
        withQualifications: true,
      }),
      loading: false,
    });
  }

  componentDidMount = async () => {
    if (this.props.authUser && !this.state.sentLoadRequest) this.loadData(this.props.authUser);
  }

  componentDidUpdate = async () => {
    if (this.props.authUser && !this.state.sentLoadRequest) this.loadData(this.props.authUser);
  }

  // Case 0: Logged Out -> Sign up to apply
  // Case 1: Owned && Open -> Mark Job "Filled" / Edit
  // Case 2: Owned && Closed -> Re-Open Job / Edit
  // Case 3: Closed -> "This job has closed"
  // Case 4: Not Applied -> Apply for Job / Message
  // Case 5: Applied: "Already applied" / Message

  render() {
    const {
      loading, claim, chats, applied, jobApplications, loadingPrimary, selfTeacher,
    } = this.state;
    const { job, authUser } = this.props;

    let primaryButtonMarkup;
    let secondaryButtonMarkup;
    let bannerMarkup;

    const ownJob = authUser && authUser.authID === job.authID;

    // Loading
    if (authUser === null || loading) {
      primaryButtonMarkup = <Button loading>Message</Button>;
    }

    // Logged out
    if (authUser === false) {
      bannerMarkup = (
        <Banner status="info">
          <Link href={ROUTES.SIGN_UP}>Sign up</Link> to apply for this job.
        </Banner>
      );
    }

    // Owned -> Edit job, open/close
    if (ownJob) {
      primaryButtonMarkup = job.closed ? (
        <Button color="primary" onClick={this.handleToggleJobOpen} loading={loadingPrimary}>
          Re-Open Job
        </Button>
      ) : (
        <Button color="primary" onClick={this.handleToggleJobOpen} loading={loadingPrimary}>
          Mark Job “Filled”
        </Button>
      );

      secondaryButtonMarkup = (
        <Link href="/jobs/[id]/edit" as={`/jobs/${job.id}/edit`} unstyled>
          <Button>
            Edit Job
          </Button>
        </Link>
      );
    } else if (authUser && authUser.admin) {
      if (claim) {
        primaryButtonMarkup = <ClaimButton job={job} claim={claim} />;
      }
      bannerMarkup = (
        <Banner status="onboarding" emoji="🕵️‍♂️">
          Job Type: {job.source.type}
          <br />
          Link: {job.source.link}
          <br />
          {job.source.firstname}
          <br />
          Applications: {jobApplications && jobApplications.count}
        </Banner>
      );
    } else {
      // Email not verified
      if (authUser && !authUser.emailVerified && !ownJob) {
        bannerMarkup = <BannerEmailVerification />;
      }

      // No teacher profile
      if (authUser && !authUser.teacher && !ownJob) {
        bannerMarkup = <BannerCreateProfile teacherOnly />;
      }

      // Registered, and allowed to apply / message
      if (authUser && authUser.emailVerified && authUser.teacher) {
        // Job is on Bolstr
        if (!job.source || job.source && job.source.type === "bolstr") {
          // And can message them
          if ((job.studio && job.studio.registered) || job.teacher) {
            secondaryButtonMarkup = (
              <ActionButtonsMessageButton
                job={job}
                authUser={authUser}
                chats={chats}
              />
            );

            if (applied) {
              bannerMarkup = <Banner status="info">You have already applied to this job.</Banner>;
            } else {
              primaryButtonMarkup = (
                <ActionButtonsJobButton
                  authUser={authUser}
                  job={job}
                  chats={chats}
                />
              );
            }
          }
        } else if (job.source && selfTeacher) {
          primaryButtonMarkup = (
            <ApplyExternalWrapper
              authUser={authUser}
              job={job}
              selfTeacher={selfTeacher}
            />
          );
        }
      }

      if (job.closed && !ownJob) {
        bannerMarkup = <Banner status="info">This job has been filled.</Banner>;
      }
    }

    const atLeastOneButton = primaryButtonMarkup || secondaryButtonMarkup;

    return (
      <>
        {atLeastOneButton && (
          <Flex spaceChildren justify="start">
            {primaryButtonMarkup}
            {secondaryButtonMarkup}
          </Flex>
        )}

        {atLeastOneButton && bannerMarkup && <Spacing extraTight />}

        {bannerMarkup}
      </>
    );
  }
}

export default ActionButtonsJob;
