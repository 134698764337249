import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, AppBar } from "@material-ui/core";
import { Link } from "~/src/components/global";
import * as ROUTES_ADMIN from "~/src/constants/routesAdmin";
import InlineNavigation from "./InlineNavigation";

const useStyles = makeStyles((theme) => ({
  admin: {
    color: theme.palette.background.default,
    background: theme.palette.text.primary,
  },
  appbar: {
    background: "none",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  flex: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
  },
  logo: {
    color: "inherit",
    fontSize: theme.typography.h6.fontSize,
  },
}));

const Header = ({ authUser }) => {
  const classes = useStyles();

  return (
    <div className={classes.admin}>
      <Container>
        <AppBar position="static" color="inherit" className={classes.appbar}>
          <div className={classes.flex}>
            <Link href={ROUTES_ADMIN.HOME} unstyled>
              <Typography variant="h5" className={classes.logo}>BOLSTR Admin</Typography>
            </Link>
            <Typography color="inherit">
              Admin:
              {authUser && authUser.email}
            </Typography>
          </div>
          <InlineNavigation />
        </AppBar>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Header);
