import React from "react";

import {
  IconButton,
  Box,
  Paper,
  Popper,
  Divider,
  ClickAwayListener,
  MenuList,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import { newIntercomMessage } from "~/src/api/intercom";

import * as ROUTES from "~/src/constants/routes";

import MenuItem from "./MenuItem";

function Menu() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleContactSupport = (event) => {
    newIntercomMessage();
    handleClose(event);
  };

  return (
    <>
      <IconButton edge="end" color="inherit" aria-label="menu" aria-controls={open ? "menu-list-grow" : undefined} aria-haspopup="true" onClick={handleToggle} ref={anchorRef}>
        <MenuIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        style={{ zIndex: 1 }}
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={2}>
            <Box px={2}>
              <MenuList disablePadding={false} id="menu-list-grow">
                <MenuItem
                  href={ROUTES.LOG_IN}
                  onClick={handleClose}
                  label="Log in"
                />

                <MenuItem
                  href={ROUTES.SIGN_UP}
                  onClick={handleClose}
                  label="Sign up"
                />

                <Divider />

                <MenuItem
                  // onClick={handleContactSupport}
                  href="mailto:hello@morgancarter.com.au"
                  label="Contact Support"
                  external
                />
              </MenuList>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default Menu;
