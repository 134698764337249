import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  emoji: {
    display: "inline-block",
    width: 18,
    height: 22,
    marginRight: 6,
  },
}));

function Emoji({ children }) {
  const classes = useStyles();

  return (
    <span className={classes.emoji}>{children}</span>
  );
}

export default Emoji;
