import React from "react";
import Router from "next/router";
import { Formik, Form } from "formik";
import uuid from "uuid";
import { isMobile } from "react-device-detect";

import {
  Typography,
} from "@material-ui/core";
import { firebase, logSentryError } from "~/src/api";

import {
  Video,
  Strong,
  Spacing,
  Banner,
  FormLayout,
  Button,
  Link,
} from "~/src/components/global";

const VIDEO_MAX_DURATION = 60;
const VIDEO_DEFAULT_SRC = "https://firebasestorage.googleapis.com/v0/b/bolstr.appspot.com/o/content%2FInstructions.mp4?alt=media&token=f722f83d-ebbf-42f7-b1a8-3db3bdd7d6ce";

class FormTeacherVideo extends React.Component {
  constructor(props) {
    super(props);

    this.videoPreview = React.createRef();

    this.state = {
      videoDefaultSrc: VIDEO_DEFAULT_SRC,
      videoSrc: VIDEO_DEFAULT_SRC,
      // videoUrl: null,
      // videoSize: null,
      videoDuration: null,
      info: null,
      error: null,
    };
  }

  onLoadedMetadata = () => {
    const { videoSrc, videoDefaultSrc } = this.state;

    if (videoSrc != videoDefaultSrc) {
      this.setState({
        videoDuration: this.videoPreview.current.duration,
      });
    }
  }

  onChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    const mime = file.type;
    const reader = new FileReader();

    reader.onload = (event) => {
      const blob = new Blob([event.target.result], { type: mime });
      const videoUrl = (URL || webkitURL).createObjectURL(blob); // eslint-disable-line no-undef
      this.setState({
        // videoUrl,
        videoSrc: videoUrl,
        // videoSize: (event.total / 1000000),
      });

      setFieldValue("newVideo", file);
    };
    reader.readAsArrayBuffer(file);
  }

  render() {
    const { teacher, back, forward } = this.props;
    const { videoSrc, videoDefaultSrc, videoDuration } = this.state;

    const videoRecorded = videoSrc != videoDefaultSrc;
    const videoTooLong = videoDuration && videoDuration > VIDEO_MAX_DURATION;

    const buttonMarkup = videoRecorded ? videoTooLong ? (

      <Button color="primary" fullWidth component="div">
        {isMobile ? "Redo" : "Upload"} Video
      </Button>
    ) : (

      <Button fullWidth component="div">
        {isMobile ? "Redo" : "Upload"} Video
      </Button>
    ) : (

      <Button color="primary" fullWidth component="div">
        {isMobile ? "Record" : "Upload"} Video
      </Button>
    );

    const bannerMarkup = videoRecorded ? videoTooLong ? (
      <Banner status="danger">Your video is over a minute.</Banner>
    ) : (
      <Banner status="success">Your video is under a minute.</Banner>
    ) : (
      <Banner status="info">Watch this instructional clip before recording your video.</Banner>
    );

    const instructionsMarkup = (
      <>
        <Typography>{videoRecorded ? "Check that your video includes" : "Make sure your video includes"}</Typography>

        <Spacing extraTight />

        <Typography component="ol">
          <li><Strong>Who</Strong> you are,</li>
          <Spacing extraTight />
          <li><Strong>What</Strong> you teach,</li>
          <Spacing extraTight />
          <li><Strong>Why</Strong> you teach,</li>
          <Spacing extraTight />
          <li><Strong>What</Strong> you like to include in your classes,</li>
          <Spacing extraTight />
          <li>The <Strong>feeling</Strong> you want students to get from your class,</li>
        </Typography>
      </>
    );

    return (
      <Formik
        initialValues={{
          newVideo: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          const { newVideo } = values;

          if (newVideo) {
            const uploadTask = firebase.storage.ref(`videos/teachers/${uuid.v1()}`).put(newVideo);

            uploadTask.on("state_changed", (snapshot) => {
              const progress = 100 * snapshot.bytesTransferred / snapshot.totalBytes;
              // Observe state change events such as progress, pause, and resume
              this.setState({
                info: `Uploading new video: ${Math.ceil(progress)}%`,
              });
            }, (error) => {
              logSentryError(error);
              // Handle unsuccessful uploads
              this.setState({
                error,
                info: null,
              });
              setSubmitting(false);
            }, () => {
              this.setState({
                info: null,
              });
              // Handle successful uploads on complete
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                // Save all data, including new video, to Firebase
                firebase.teacher(teacher.id).set(
                  {
                    video: downloadURL,
                  },
                  { merge: true },
                ).then(() => {
                  Router.push(forward.route);
                });
              });
            });
          }
        }}
      >
        {({
          isSubmitting, setFieldValue,
        }) => (
          <Form>
            {this.state.info && (
              <>
                <Banner status="info">{this.state.info}</Banner>
                <Spacing tight />
              </>
            )}
            {this.state.error && (
              <>
                <Banner status="danger">{this.state.error.message}</Banner>
                <Spacing tight />
              </>
            )}

            {!process.env.CYPRESS && (
              <Video
                source={videoSrc}
                autoplay
              />
            )}

            <video ref={this.videoPreview} onLoadedMetadata={this.onLoadedMetadata} preload="metadata" src={videoSrc} style={{ display: "none" }} />
            <input
              disabled={isSubmitting}
              name="newVideo"
              type="file"
              accept="video/*"
              capture="user"
              onChange={this.onChange}
              onChange={(event) => {
                this.onChange(event, setFieldValue);
              }}
              id="upload-button"
              style={{
                display: "none",
              }}
            />

            <Spacing tight />

            {bannerMarkup}

            <Spacing tight />

            <label htmlFor="upload-button">
              {buttonMarkup}
            </label>

            <Spacing tight />

            {instructionsMarkup}

            <Spacing />

            <FormLayout.Footer>
              {back && back.route && (
                <Link href={back.route} unstyled>
                  <Button>{back.label}</Button>
                </Link>
              )}

              <Button
                color="primary"
                disabled={!videoRecorded || videoTooLong}
                loading={isSubmitting}
                type="submit"
              >
                {forward.label}
              </Button>
            </FormLayout.Footer>
          </Form>
        )}
      </Formik>
    );
  }
}

export default FormTeacherVideo;
