const SITE_FONTS = [
  {
    label: "Open Sans",
    value: "Open+Sans",
    weightRegular: 400,
    weightBold: 600,
  },
  {
    label: "Lato",
    value: "Lato",
    weightRegular: 400,
    weightBold: 700,
  },
  {
    label: "Montserrat",
    value: "Montserrat",
    weightRegular: 500,
    weightBold: 600,
  },
  {
    label: "Source Sans Pro",
    value: "Source+Sans+Pro",
    weightRegular: 400,
    weightBold: 600,
  },
  {
    label: "Work Sans",
    value: "Work+Sans",
    weightRegular: 500,
    weightBold: 700,
  },
  {
    label: "Karla",
    value: "Karla",
    weightRegular: 400,
    weightBold: 700,
  },
  {
    label: "IBM Plex Sans",
    value: "IBM+Plex+Sans",
    weightRegular: 400,
    weightBold: 600,
  },
  {
    label: "Roboto",
    value: "Roboto",
    weightRegular: 400,
    weightBold: 500,
  },
  {
    label: "Inter",
    value: "Inter",
    weightRegular: 400,
    weightBold: 600,
  },
  {
    label: "Lora",
    value: "Lora",
    weightRegular: 400,
    weightBold: 600,
  },
  {
    label: "Martel",
    value: "Martel",
    weightRegular: 400,
    weightBold: 600,
  },
  {
    label: "Merriweather",
    value: "Merriweather",
    weightRegular: 400,
    weightBold: 700,
  },
  {
    label: "Playfair Display",
    value: "Playfair+Display",
    weightRegular: 400,
    weightBold: 600,
  },
  {
    label: "PT Serif",
    value: "PT+Serif",
    weightRegular: 400,
    weightBold: 700,
  },
  {
    label: "Bitter",
    value: "Bitter",
    weightRegular: 400,
    weightBold: 700,
  },
  {
    label: "Source Serif Pro",
    value: "Source+Serif+Pro",
    weightRegular: 400,
    weightBold: 600,
  },
  {
    label: "IBM Plex Serif",
    value: "IBM+Plex+Serif",
    weightRegular: 400,
    weightBold: 600,
  },
];

export default SITE_FONTS;
