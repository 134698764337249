import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import * as ROUTES_ADMIN from "~/src/constants/routesAdmin";
import Link from "./Link";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    alignItems: "center",
    "& > * + *": {
      marginLeft: theme.spacing(3),
    },
  },
}));

function InlineNavigation() {
  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <Typography>
        <Link href={ROUTES_ADMIN.JOBS}>
          Jobs
        </Link>
      </Typography>

      <Typography>
        <Link href={ROUTES_ADMIN.TEACHERS}>
          Teachers
        </Link>
      </Typography>

      <Typography>
        <Link href={ROUTES_ADMIN.EXPERIENCES}>
          Experiences
        </Link>
      </Typography>

      <Typography>
        <Link href={ROUTES_ADMIN.STUDIOS}>
          Studios
        </Link>
      </Typography>

      <Typography>
        <Link href={ROUTES_ADMIN.MESSAGES}>
          Messages
        </Link>
      </Typography>

      <Typography>
        <Link href={ROUTES_ADMIN.REFERRALS}>
          Referrals
        </Link>
      </Typography>

      <Typography>
        <Link href={ROUTES_ADMIN.SURVEYS}>
          Surveys
        </Link>
      </Typography>

      <Typography>
        <Link href={ROUTES_ADMIN.VIDEOS}>
          Videos
        </Link>
      </Typography>
    </div>
  );
}

export default InlineNavigation;
