import React from "react";
import { firebase, logSentryError } from "~/src/api";

import {
  Banner,
  Link,
} from "~/src/components/global";

class BannerEmailVerification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSent: false,
      error: null,
    };
  }

  handleSendEmailVerification = () => {
    firebase.doSendEmailVerification()
      .then(() => this.setState({ isSent: true }))
      .catch((err) => {
        logSentryError(err);
        this.setState({
          error: err,
        });
      });
  };

  render() {
    const { isSent, error } = this.state;

    return error ? (
      <Banner status="danger">
        {error.message}
      </Banner>
    ) : isSent ? (
      <Banner status="success">
        Email verification sent. Check your inbox!
      </Banner>
    ) : (
      <Banner status="info">
        You must verify your email address before you can apply for jobs, post jobs, or message anyone. Check your inbox for a confirmation email. If it hasn’t come through yet, you can <Link onClick={this.handleSendEmailVerification}>re-send it</Link>.
      </Banner>
    );
  }
}

export default BannerEmailVerification;
