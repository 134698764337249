// import { IntercomAPI } from "react-intercom";

const hideIntercomMessenger = () => {
  // IntercomAPI("update", {
  //   hide_default_launcher: true,
  // });
};

const showIntercomMessenger = () => {
  // IntercomAPI("update", {
  //   hide_default_launcher: false,
  // });
};

const newIntercomMessage = () => {
  // IntercomAPI("showNewMessage");
};

export { hideIntercomMessenger, showIntercomMessenger, newIntercomMessage };
