import React from "react";
import { Divider as MuiDivider } from "@material-ui/core";
import { Spacing } from "~/src/components/global";

function Divider(props) {
  return (
    <Spacing {...props}>
      <MuiDivider />
    </Spacing>
  );
}

export default Divider;
