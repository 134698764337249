import React from "react";
import {
  InputLabel, FormControl, FormControlLabel, FormHelperText, Radio, Typography,
} from "@material-ui/core";
import { Field } from "formik";
import { RadioGroup } from "formik-material-ui";

const InputRadioButtons = (props) => {
  const {
    label, name, options, isSubmitting, helperText,
  } = props;

  return (
    <FormControl variant="outlined">
      <InputLabel>{label}</InputLabel>

      <FormControl>
        <Field name={name} label="Radio Group" component={RadioGroup}>
          {options.map((option) => (
            <FormControlLabel
              value={option.value}
              control={<Radio disabled={isSubmitting} />}
              label={(
                <>
                  <Typography>{option.label}</Typography>
                  {option.detail && <Typography color="textSecondary">{option.detail}</Typography>}
                </>
              )}
              disabled={isSubmitting}
              key={option.value}
            />
          ))}
        </Field>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </FormControl>
  );
};

export default InputRadioButtons;
