import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import {
  Box,
  Typography,
} from "@material-ui/core";

import InfoIcon from "@material-ui/icons/InfoOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutlined";
import CheckIcon from "@material-ui/icons/CheckCircleOutlined";
import { Flex } from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  banner: {
    display: "inline-block",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(),
    paddingRight: theme.spacing(1.5),
    "& a": {
      textDecoration: "underline",
    },
  },
  default: {
    background: fade(theme.palette.text.primary, 0.1),
  },
  info: {
    background: fade(theme.palette.primary.main, 0.15),
  },
  success: {
    background: fade(theme.palette.success.main, 0.15),
    "& svg": {
      color: theme.palette.success.main,
    },
  },
  warning: {
    background: fade(theme.palette.warning.main, 0.25),
  },
  danger: {
    background: fade(theme.palette.secondary.main, 0.15),
  },
  text: {
    alignSelf: "center",
  },
  onboarding: {
    background: fade(theme.palette.warning.main, 0.2),
    border: `1px solid ${theme.palette.border.base}`,
    borderRadius: theme.shape.borderRadiusLarge,
  },
  emojiIcon: {
    width: 24,
    height: 24,
    lineHeight: "24px",
    fontSize: 20,
  },
}));

function Banner(props) {
  const {
    children, status, emoji, ...otherProps
  } = props;
  const classes = useStyles();

  let iconMarkup = <InfoIcon />;
  if (emoji) {
    iconMarkup = <div className={classes.emojiIcon}>{emoji}</div>;
  } else if (status === "info") {
    iconMarkup = <InfoIcon color="primary" />;
  } else if (status === "danger") {
    iconMarkup = <ErrorIcon color="secondary" />;
  } else if (status === "success") {
    iconMarkup = <CheckIcon />;
  }

  const bannerClasses = classNames(
    classes.banner,
    classes[status],
  );

  return (
    <div className={bannerClasses} data-cy="banner" data-status={status}>
      <Flex justify="start" align="start">
        <Box pr={1}>
          {iconMarkup}
        </Box>
        <Typography className={classes.text} {...otherProps}>
          {children}
        </Typography>
      </Flex>
    </div>
  );
}

Banner.defaultProps = {
  children: undefined,
  status: "default",
};

export default Banner;
