import React from "react";

import { calcReplyTime } from "~/src/utils";

import {
  Banner,
  Spacing,
} from "~/src/components/global";

function BannerReplyTime({
  name, avgReplyTime, marginTop, ...otherProps
}) {
  const calculatedReplyTime = avgReplyTime ? calcReplyTime(avgReplyTime) : null;

  return calculatedReplyTime && calculatedReplyTime.label ? (
    <>
      {marginTop ? <Spacing tight /> : null}

      <Banner status="success" emoji="⏱" {...otherProps}>{name} typically replies within {calculatedReplyTime.label}.</Banner>
    </>
  ) : null;
}

export default BannerReplyTime;
