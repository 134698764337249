/* eslint-disable max-len */
import React from "react";

const QualificationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="currentColor" d="M13.1155262,15.9965012 L10.1880407,14.168907 L12.0326506,11.2521132 L11.6362639,7.82382649 L15.0668006,7.44740938 L17.5,5 L19.9331994,7.44740938 L23.3637361,7.82382649 L22.9673494,11.2521132 L24.8119593,14.168907 L21.8844738,15.9965012 L21,18.547 L21,24 L17.5,22.5 L14,24 L14,18.55 L14.245872,19.2572665 L13.1155262,15.9965012 Z M20,0 C21.1045695,0 22,0.8954305 22,2 L22,5.762 L20.807,5.631 L20,4.82 L20,2 L4,2 L4,20 L11.999,20 L11.999,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,2 C2,0.8954305 2.8954305,0 4,0 L20,0 Z M17.5,18.1079525 L16,18.637 L16,20.966 L17.5,20.3240648 L19,20.967 L19,18.638 L20.754128,19.2572665 L17.5,18.1079525 Z M9.533,16 L11.54,17.253 L11.798,18 L6,18 L6,16 L9.533,16 Z M17.5,7.837 L15.9871009,9.35843269 L13.854,9.592 L14.1005466,11.724097 L12.953,13.537 L14.7738501,14.674032 L15.476,16.701 L17.5,15.9868767 L19.523,16.701 L20.2261499,14.674032 L22.046,13.537 L20.8994534,11.724097 L21.145,9.592 L19.0128991,9.35843269 L17.5,7.837 Z M9.311,12 L8.046,14 L6,14 L6,12 L9.311,12 Z M9.744,8 L9.975,10 L6,10 L6,8 L9.744,8 Z M15.814,4 L14.192,5.631 L10.831,6 L6,6 L6,4 L15.814,4 Z" />
  </svg>
);

export default QualificationIcon;
