import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Loading, Banner, Button } from "~/src/components/global";
import { Filter, FilterText, FilterWrapper } from "~/src/components/shared";
import { getFilteredDocs } from "~/src/api";

import SKILLS from "~/src/constants/skills";
import LOCATIONS from "~/src/constants/locations";
import SAFETY_CHECKS from "~/src/constants/safetyChecks";

const PAGE_SIZE = 12;

const disciplineOptions = SKILLS.map((skill) => ({
  value: skill.name,
  label: skill.label,
  labelEmoji: skill.labelEmoji,
}));

const locationOptions = LOCATIONS.map((location) => ({
  value: location.name,
  label: location.label,
}));

const safetyCheckOptions = SAFETY_CHECKS.map((safetyCheck) => ({
  value: safetyCheck.name,
  label: safetyCheck.label,
  detail: safetyCheck.detail,
}));

class ListingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      moreToLoad: true,
      lastVisible: null,
      results: [],
      error: null,
      filterValueDiscipline: null,
      filterValueLocation: null,
      filterValueSafety: [],
      filterValueName: "",
    };
  }

  loadData = async () => {
    const {
      results, lastVisible, filterValueDiscipline, filterValueLocation, filterValueSafety, filterValueName,
    } = this.state;
    const { query, label, promiseFunction } = this.props;

    this.setState({ loading: true });

    const result = await getFilteredDocs({
      label,
      query,
      promiseFunction,
      results,
      lastVisible,
      filters: {
        discipline: filterValueDiscipline,
        name: filterValueName,
        safety: filterValueSafety,
        location: filterValueLocation,
      },
      pageSize: PAGE_SIZE,
    });

    this.setState({
      loading: false,
      results: result.results,
      lastVisible: result.lastVisible,
      moreToLoad: result.moreToLoad,
    });
  }

  componentDidMount = async () => {
    this.loadData();
  }

  handleChangeDiscipline = async (event) => {
    this.setState({
      filterValueDiscipline: event.target.value,
      moreToLoad: true,
      lastVisible: null,
      results: [],
      error: null,
    },
    function () {
      this.loadData();
    });
  }

  handleChangeSafetyCheck = (event) => {
    const { filterValueSafety } = this.state;
    const { value } = event.currentTarget;

    const newValues = [].concat(filterValueSafety);
    const indexOf = filterValueSafety.indexOf(value);

    if (indexOf >= 0) {
      newValues.splice(indexOf, 1);
    } else {
      newValues.push(value);
    }

    this.setState({
      filterValueSafety: newValues,
      moreToLoad: true,
      lastVisible: null,
      results: [],
      error: null,
    },
    function () {
      this.loadData();
    });
  }

  handleChangeLocation = async (event) => {
    this.setState({
      filterValueLocation: event.target.value,
      moreToLoad: true,
      lastVisible: null,
      results: [],
      error: null,
    },
    function () {
      this.loadData();
    });
  }

  handleChangeName = async (value) => {
    this.setState({
      filterValueName: value,
      moreToLoad: true,
      lastVisible: null,
      results: [],
      error: null,
    },
    function () {
      this.loadData();
    });
  }

  render() {
    const { label } = this.props;
    const {
      results, loading, moreToLoad, error,
    } = this.state;

    return (
      <>
        {error && <Banner status="danger">{error.message}</Banner>}

        <Grid container>
          <Grid item xs={12} sm={4} md={3}>
            <FilterWrapper>
              <Filter
                value={this.state.filterValueDiscipline}
                options={disciplineOptions}
                onChange={this.handleChangeDiscipline}
                title="Discipline"
                titlePlural="disciplines"
                disabled={loading}
              />
              {(label === "jobs" || label === "teachers") && (
                <Filter
                  value={this.state.filterValueLocation}
                  options={locationOptions}
                  onChange={this.handleChangeLocation}
                  title="Location"
                  titlePlural="locations"
                  disabled={loading}
                />
              )}
              {label === "studios" && (
                <FilterText
                  value={this.state.filterValueName}
                  onChange={this.handleChangeName}
                  placeholder="Studio Name..."
                  disabled={loading}
                />
              )}
              {label === "teachers" && (
                <Filter
                  value={this.state.filterValueSafety}
                  options={safetyCheckOptions}
                  onChange={this.handleChangeSafetyCheck}
                  title="Filters"
                  titlePlural="filters"
                  multiple
                  disabled={loading}
                />
              )}
            </FilterWrapper>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            {(loading && !results.length) ? <Loading /> : (
              <>
                {(results && results.length) ? (
                  <Grid container>
                    {results.map((result) => (
                      <Grid item xs={12} md={6} key={result.id}>
                        {this.props.listItem(result)}
                      </Grid>
                    ))}
                    {moreToLoad && (
                      <Grid item xs={12}>
                        <Typography align="center">
                          <Button color="primary" onClick={this.loadData} loading={loading}>Load More</Button>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Banner>No {label} found. Try changing your filters.</Banner>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ListingPage;
