import React from "react";
import { Typography } from "@material-ui/core";
import SKILLS from "~/src/constants/skills";
import { renderLocation } from "~/src/utils";
import { Emoji, Flex } from "~/src/components/global";

function DisplayCategories({ categories, locations, minimal }) {
  if (!categories) return null;

  if (minimal) {
    // Convert skills map to Array
    const skillsArray = [];
    for (const skill of SKILLS) {
      if (categories.includes(skill.name)) {
        skillsArray.push(skill.label);
      }
    }

    // Just text
    return skillsArray.join(", ");
  }

  // Convert skills map to Array
  const skillsArray = [];
  for (const skill of SKILLS) {
    if (categories.includes(skill.name)) {
      skillsArray.push(skill);
    }
  }

  // With emojis and locations
  return (
    <Flex justify="start" spaceChildren wrap>
      {skillsArray.map((skill, index) => (
        <Typography key={index}>
          <Emoji>{skill.emoji}</Emoji>
          {skill.label}
        </Typography>
      ))}
      {locations && locations.map((location, index) => (
        <Typography key={index}>
          <Emoji>🌏</Emoji>
          {renderLocation(location)}
        </Typography>
      ))}
    </Flex>
  );
}

export default DisplayCategories;
