const EXAMPLE_INTERESTS = [
  "eg. Vinyasa Yoga",
  "eg. Reformer Pilates",
  "eg. Travelling",
  "eg. Contemporary Dance",
  "eg. Yin Yoga",
  "eg. Mat Pilates",
  "eg. JFH Dance",
  "eg. Hatha Yoga",
  "eg. Ashtanga Yoga",
  "eg. Iyengar Yoga",
  "eg. Bikram Yoga",
  "eg. Contemporary Pilates",
  "eg. Ballet",
  "eg. Hip Hop Dance",
  "eg. Jazz Dance",
  "eg. JFH Dance",
  "eg. Latin Dance",
  "eg. Surfing",
  "eg. Music",
  "eg. Healthy Eating",
  "eg. Nutrition",
  "eg. Movies",
  "eg. Photography",
  "eg. Art",
  "eg. Cooking",
];

export default EXAMPLE_INTERESTS;
