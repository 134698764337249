import React from "react";
import * as ROUTES from "~/src/constants/routes";
import { Banner, Link, Button } from "~/src/components/global";
import {
  BannerEmailVerification,
  BannerCreateProfile,
  ActionButtonsMessageButton,
} from "~/src/components/shared";

const ActionButtonsTeacher = ({ teacher, authUser }) => {
  if (authUser === null) {
    return <Button loading>Message</Button>;
  } if (authUser === false) {
    return (
      <Banner status="info">
        <Link href={ROUTES.SIGN_UP}>Sign up</Link> to message this teacher.
      </Banner>
    );
  } if (!authUser.emailVerified) {
    return <BannerEmailVerification />;
  } if (!authUser.teacher && !authUser.studio) {
    return <BannerCreateProfile />;
  } if (authUser.authID === teacher.authID) {
    return (
      <Link href={ROUTES.PROFILE_TEACHER} unstyled>
        <Button>
          Edit Profile
        </Button>
      </Link>
    );
  }

  return (
    <ActionButtonsMessageButton
      name={teacher.firstname}
      authID={teacher.authID}
    />
  );
};

export default ActionButtonsTeacher;
