/* eslint-disable no-await-in-loop */
import React from "react";
import Router from "next/router";
import { Formik, Form } from "formik";
import * as yup from "yup";
import LOCATION_INITIAL_VALUES from "~/src/constants/initialValues/location";
import { firebase } from "~/src/api";

import {
  FormLayout,
  Button,
  Link,
  InputLocation,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  location: yup.object().shape({
    label: yup.string().required("Location is required"),
  }),
});

function TeacherFormLocation(props) {
  const {
    teacher, back, forward,
  } = props;

  return (
    <Formik
      initialValues={{
        location: teacher.location || LOCATION_INITIAL_VALUES,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await firebase.GeoFirestore.collection("teachers").doc(teacher.id).update({
          location: values.location,
          coordinates: new firebase.firestore.GeoPoint(values.location.latitude, values.location.longitude),
        });

        if (forward.route) {
          Router.push(forward.route, forward.routeAs);
        } else {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormLayout>
            <InputLocation label="Your home suburb" types={["(regions)"]} disabled={isSubmitting} />

            <FormLayout.Footer>
              {back && back.route && (
                <Link href={back.route} as={back.routeAs} unstyled>
                  <Button>{back.label}</Button>
                </Link>
              )}

              <Button
                color="primary"
                loading={isSubmitting}
                type="submit"
              >
                {forward.label}
              </Button>
            </FormLayout.Footer>
          </FormLayout>
        </Form>
      )}
    </Formik>
  );
}

export default TeacherFormLocation;
