import React from "react";

import {
  FormLabel,
  Typography,
} from "@material-ui/core";

import {
  Card,
  Banner,
  Link,
  Flex,
  Button,
  Spacing,
  Strong,
  Video,
} from "~/src/components/global";

class VideoUploader extends React.Component {
  constructor() {
    super();

    this.state = {
      uploadingNewVideo: false,
    };
  }

  componentDidMount = () => {
    const { oldVideo } = this.props;

    if (!oldVideo) {
      this.setState({
        uploadingNewVideo: true,
      });
    }
  }

  toggleNewVideoUploader = () => {
    this.setState(({ uploadingNewVideo }) => ({
      uploadingNewVideo: !uploadingNewVideo,
    }));
  }

  render() {
    const { uploadingNewVideo } = this.state;
    const {
      label, name, oldVideo, newVideo, setFieldValue, disabled, maxVideoSize,
    } = this.props;

    const newVideoSize = newVideo && Math.ceil(newVideo.size / 1000000);

    const videoSizeWarningMarkup = newVideoSize && newVideoSize > maxVideoSize ? (
      <>
        <Spacing extraTight />

        <Typography color="secondary">Your video is {newVideoSize}MB! Try shortening it, or reducing the resolution.</Typography>

        <Spacing extraTight />

        <Banner status="info">
          If you can’t figure out how to reduce the filesize, <Link href="https://www.dropbox.com/request/pHQKnmrZqoX4NTrh9j5F" external>upload it here</Link> and I will do it for you. The name of the file should be your name, eg. <Strong>morgan-carter.mp4</Strong>
        </Banner>
      </>
    ) : null;

    return uploadingNewVideo ? (
      <Card>
        <FormLabel>New {label}</FormLabel>

        <Spacing extraTight />

        <Flex justify="start" spaceChildren>
          <input
            id={name}
            name={name}
            type="file"
            onChange={(event) => {
              setFieldValue(name, event.currentTarget.files[0]);
            }}
            disabled={disabled}
            accept="video/mp4, video/mov"
          />
        </Flex>

        <Spacing extraTight />

        <Typography color="textSecondary">Your video must be less than 1 minute long, and less than 50mb.</Typography>
        {videoSizeWarningMarkup}

        {oldVideo && (
          <>
            <Spacing extraTight />

            <Button color="primary" variant="text" onClick={this.toggleNewVideoUploader} disabled={disabled}>
              Cancel
            </Button>
          </>
        )}
      </Card>
    ) : (
      <div>
        <FormLabel>
          <Flex spaceChildren align="center">
            <span>Current {label}</span>
            <Button color="primary" variant="text" onClick={this.toggleNewVideoUploader} disabled={disabled}>
              Change
            </Button>
          </Flex>
        </FormLabel>

        <Spacing extraTight />

        <Video source={oldVideo} />
      </div>
    );
  }
}

export default VideoUploader;
