import React from "react";

import {
  Banner,
  Link,
} from "~/src/components/global";

import * as ROUTES from "~/src/constants/routes";

function BannerCreateProfile({ teacherOnly }) {
  return teacherOnly ? (
    <Banner status="info">
      You must first{" "}
      <Link href={ROUTES.PROFILE_TEACHER_ADD}>create a teacher profile</Link>{" "}
      before you can apply for jobs.
    </Banner>
  ) : (
    <Banner status="info">
      You must first{" "}
      <Link href={ROUTES.PROFILE_TEACHER_ADD}>create a teacher profile</Link>, or{" "}
      <Link href={ROUTES.PROFILE_STUDIO_ADD}>create a studio profile</Link>,{" "}
      before you can apply for jobs, post jobs, or message anyone.
    </Banner>
  );
}

export default BannerCreateProfile;
