import React from "react";
import NextHead from "next/head";

import SITE_FONTS from "~/src/constants/siteFonts";

const Head = (props) => {
  const {
    title, fontBody, fontHeader, image, favicon, description, canonicalUrl, structuredData, dontScrape,
  } = props;

  const metaTitle = title || "Bolstr · Connecting Teachers with Studios";
  const metaDescription = description || "Hire Sydney’s best movement teachers.";
  const metaImage = image || `${process.env.URL_PREFIX}/socialimage.png`;
  const metaRobots = dontScrape ? "noindex, nofollow" : "index, follow";
  let fonts = "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Work+Sans:wght@600;800&display=swap";
  if (fontBody || fontHeader) {
    const fontBodyWeights = SITE_FONTS.find((font) => font.value === fontBody);
    if (fontBody === fontHeader) {
      fonts = `https://fonts.googleapis.com/css2?family=${fontBody}:wght@${fontBodyWeights.weightRegular};${fontBodyWeights.weightBold}&display=swap`;
    } else {
      const fontHeaderWeights = SITE_FONTS.find((font) => font.value === fontHeader);
      fonts = `https://fonts.googleapis.com/css2?family=${fontBody}:wght@${fontBodyWeights.weightRegular};${fontBodyWeights.weightBold}&family=${fontHeader}:wght@${fontHeaderWeights.weightRegular}&display=swap`;
    }
  }

  return (
    <NextHead>
      <title>{metaTitle}</title>
      <link rel="shortcut icon" href={favicon || "/favicon.png"} />
      <link rel="stylesheet" href={fonts} />
      <meta property="og:title" content={metaTitle} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta name="twitter:image" content={metaImage} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Bolstr" />
      <meta name="robots" content={metaRobots} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {structuredData && (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
      )}
    </NextHead>
  );
};

export default Head;
