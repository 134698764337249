import React from "react";
import { wrapWithComponent, isElementOfType } from "@shopify/react-utilities";
import { makeStyles } from "@material-ui/core/styles";

import { Item, Group, Footer } from "./components";

const useStyles = makeStyles((theme) => ({
  tight: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
  base: {
    marginTop: theme.spacing(-3),
    marginLeft: theme.spacing(-2),
  },
}));

function FormLayout(props) {
  const { children, tight } = props;
  const classes = useStyles();

  const wrapChildren = function (child, index) {
    if (isElementOfType(child, Group)) {
      return child;
    }
    const props = { key: index, tight };
    return wrapWithComponent(child, Item, props);
  };

  return (
    <div className={tight ? classes.tight : classes.base}>
      {React.Children.map(children, wrapChildren)}
    </div>
  );
}

FormLayout.Group = Group;
FormLayout.Footer = Footer;

FormLayout.defaultProps = {
  children: undefined,
};

export default FormLayout;
