import React from "react";
import { getThumbnailURL } from "~/src/api";
import { Container, Video, Spacing } from "~/src/components/global";
import { TeacherHeroImage } from "~/src/components/teachers";
import Gallery from "./Gallery";

function TeacherGallery({ teacher }) {
  const photos = teacher.photos || [];
  const { video } = teacher;

  let markup;

  if (video) {
    if (photos.length >= 1) {
      markup = (
        <Gallery
          hero={{
            video,
            poster: teacher.photo,
          }}
          photos={photos.slice(0, 4)}
          alt={teacher.firstname}
        />
      );
    } else {
      markup = (
        <Container size="sm" disableMargins disableGutters>
          <Video
            autoplay
            source={video}
            poster={getThumbnailURL({
              url: teacher.photo,
              size: 128,
            })}
          />
        </Container>
      );
    }
  } else if (photos.length > 1) {
    markup = (
      <Gallery
        hero={photos[0]}
        photos={photos.slice(1, 5)}
        alt={teacher.firstname}
      />
    );
  } else if (photos.length === 1) {
    markup = (
      <Container size="sm" disableMargins disableGutters>
        <TeacherHeroImage
          source={getThumbnailURL({
            alt: teacher.firstname,
            url: photos[0],
            size: 1024,
          })}
        />
      </Container>
    );
  } else if (teacher.photo) {
    markup = (
      <Container size="sm" disableMargins disableGutters>
        <TeacherHeroImage
          source={getThumbnailURL({
            alt: teacher.firstname,
            url: teacher.photo,
            size: 1024,
          })}
        />
      </Container>
    );
  } else {
    return null;
  }

  return (
    <>
      {markup}

      <Spacing tight />
    </>
  );
}

export default TeacherGallery;
