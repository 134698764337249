import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  LinearProgress,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckCircleDoneIcon from "@material-ui/icons/CheckCircle";
import { Spacing, Link, Card } from "~/src/components/global";
import { getTeacherProfileSections } from "~/src/utils";

const useStyles = makeStyles((theme) => ({
  iconIncomplete: {
    color: theme.palette.primary.main,

  },
  iconComplete: {
    color: theme.palette.success.main,
  },
  listItem: {
    paddingTop: 0,
  },
}));

const TeacherProfileCompleteness = ({ teacher }) => {
  const classes = useStyles();

  const sections = getTeacherProfileSections(teacher);
  const progress = Math.ceil(sections.filter((section) => section.completed).length / sections.length * 100);

  return progress === 100 ? null : (
    <>
      <Card>
        <Typography variant="h6" component="h2">Profile Completeness: {progress}%</Typography>
        <Spacing extraTight />
        <LinearProgress variant="determinate" value={progress} color="secondary" />

        <Spacing extraLoose />

        <List>
          {sections.map((section) => (section.completed ? (
            <MuiListItem className={classes.listItem} key={section.label}>
              <ListItemIcon>
                <CheckCircleDoneIcon className={classes.iconComplete} />
              </ListItemIcon>
              <ListItemText
                primary={<Typography color="textSecondary">{section.label}</Typography>}
              />
            </MuiListItem>
          ) : (
            <Link href={section.link} key={section.label}>
              <MuiListItem className={classes.listItem}>
                <ListItemIcon>
                  <CheckCircleIcon className={classes.iconIncomplete} />
                </ListItemIcon>
                <ListItemText
                  primary={section.label}
                />
              </MuiListItem>
            </Link>
          )))}
        </List>
      </Card>

      <Spacing />
    </>
  );
};

export default TeacherProfileCompleteness;
