import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { firebase, uploadPhoto } from "~/src/api";
import {
  Banner,
  Dialog,
  Button,
  FormLayout,
  InputSelect,
  InputImageUploader,
} from "~/src/components/global";
import { ConfirmDeleteButton } from "~/src/components/shared";

import MONTHS_OF_THE_YEAR from "~/src/constants/months";
import YEARS from "./years";

const MONTHS = MONTHS_OF_THE_YEAR.slice(0);
MONTHS.unshift({ label: "Month" });

function QualificationDialog(props) {
  const {
    modalOpen, closeModals, teacher, qualification, qualifications, setSnackbarMessage,
  } = props;
  const [info, setInfo] = React.useState(false);
  const [error, setError] = React.useState(false);

  const deleteQualification = async () => {
    await firebase.db.doc(`teachers/${teacher.id}/qualifications/${qualification.id}`).delete();

    // Update existing data
    const foundIndex = qualifications.findIndex((q) => q.id == qualification.id);
    qualifications.splice(foundIndex, 1);
    setSnackbarMessage("Qualification deleted");
    closeModals();
  };

  const addNewQualification = async (data) => {
    const qualificationRef = await firebase.teacherQualifications(teacher.id).doc();
    await qualificationRef.set({
      ...data,
    });

    // Update existing data
    qualifications.push({
      id: qualificationRef.id,
      ...data,
    });
    setSnackbarMessage("New qualification added");
    closeModals();
  };

  const saveQualification = async (data) => {
    await firebase.db.doc(`teachers/${teacher.id}/qualifications/${qualification.id}`).update({
      ...data,
    });

    // Update existing data
    const foundIndex = qualifications.findIndex((q) => q.id == qualification.id);
    qualifications[foundIndex] = {
      id: qualification.id,
      ...data,
    };
    setSnackbarMessage("Qualification saved");
    closeModals();
  };

  return (
    <Dialog
      onClose={closeModals}
      id="add-edit-qualification"
      open={modalOpen}
      title={qualification ? "Edit Qualification" : "Add Qualification"}
    >
      <Formik
        initialValues={{
          name: qualification && qualification.name || "",
          startDateMonth: qualification && qualification.startDateMonth || "",
          startDateYear: qualification && qualification.startDateYear || "",
          endDateMonth: qualification && qualification.endDateMonth || "",
          endDateYear: qualification && qualification.endDateYear || "",
          photo: qualification && qualification.photo || "",
          newPhoto: "",
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required(),
        })}
        onSubmit={async (values) => {
          const { newPhoto, ...otherValues } = values;
          const data = { ...otherValues };

          data.photo = newPhoto ? await uploadPhoto({
            photo: newPhoto,
            subdirectory: "qualifications",
            setInfo,
            setError,
          }) : values.photo;

          if (qualification) {
            saveQualification(data);
          } else {
            addNewQualification(data);
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <FormLayout>
              {info && <Banner status="info">{info}</Banner>}
              {error && <Banner status="danger">{error.message}</Banner>}

              <Field
                label="Qualification"
                name="name"
                component={TextField}
                multiline
                autoFocus
              />

              <FormLayout.Group>
                <Field
                  label="Start Date (Optional)"
                  name="startDateMonth"
                  options={MONTHS}
                  component={InputSelect}
                />
                <Field
                  label="&nbsp;"
                  name="startDateYear"
                  options={YEARS}
                  component={InputSelect}
                />
              </FormLayout.Group>

              <FormLayout.Group>
                <Field
                  label="End Date (Optional)"
                  name="endDateMonth"
                  options={MONTHS}
                  component={InputSelect}
                />
                <Field
                  label="&nbsp;"
                  name="endDateYear"
                  options={YEARS}
                  component={InputSelect}
                />
              </FormLayout.Group>

              <InputImageUploader
                oldImage={values.photo}
                newImage={values.newPhoto}
                setFieldValue={setFieldValue}
                label="Certificate (Optional)"
                name="newPhoto"
                variant="rounded"
                outlined
                disabled={isSubmitting}
              />

              <FormLayout.Footer>
                {qualification && <ConfirmDeleteButton deleteFunction={deleteQualification} isSubmitting={isSubmitting} label="qualification" />}
                <Button onClick={closeModals} loading={isSubmitting}>Cancel</Button>
                <Button
                  color="primary"
                  loading={isSubmitting}
                  type="submit"
                >
                  {qualification ? "Save Changes" : "Save Qualification"}
                </Button>
              </FormLayout.Footer>
            </FormLayout>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default QualificationDialog;
