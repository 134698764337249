import React from "react";

import {
  Box,
  Card as MuiCard,
  CardContent,
  Typography,
} from "@material-ui/core";

import { Flex, Link, Spacing } from "~/src/components/global";

function Card(props) {
  const {
    title, h1, subtitle, action, children, well, ...otherProps
  } = props;

  let actionMarkup = null;

  if (action) {
    const { label, href, onClick } = action;

    actionMarkup = href ? (
      <Typography>
        <Link href={href}>{label}</Link>
      </Typography>
    ) : (
      <Typography>
        <Link onClick={onClick}>{label}</Link>
      </Typography>
    );
  }

  const subtitleMarkup = subtitle ? (
    <>
      <Spacing extraTight />
      <Typography>{subtitle}</Typography>
    </>
  ) : null;

  const titleMarkup = title ? (
    <Box pb={2}>
      <Flex>
        <Typography variant={h1 ? "h5" : "h6"} component={h1 ? "h1" : "h2"}>{title}</Typography>
        {actionMarkup}
      </Flex>

      {subtitleMarkup}
    </Box>
  ) : null;

  const markup = (
    <CardContent>
      {titleMarkup}

      {children}
    </CardContent>
  );

  return well ? (
    <Box bgcolor="background.dark" borderRadius="borderRadiusLarge">
      {markup}
    </Box>
  ) : (
    <MuiCard {...otherProps}>
      {markup}
    </MuiCard>
  );
}

export default Card;
