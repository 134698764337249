import React from "react";
import NextLink from "next/link";
import classNames from "classnames";
import { useRouter } from "next/router";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    alignItems: "center",
    "& > * + *": {
      marginLeft: theme.spacing(3),
    },
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function Link(props) {
  const { href, children } = props;
  const classes = useStyles();
  const router = useRouter();

  const className = classNames(classes.link, router.pathname === href && classes.active);

  return (
    <NextLink href={href}>
      <a className={className}>{children}</a>
    </NextLink>
  );
}

export default Link;
