const THRESHOLDS = [
  { label: "1 hour", value: 3600 },
  { label: "2 hours", value: 7200 },
  { label: "4 hours", value: 14400 },
  { label: "8 hours", value: 28800 },
  { label: "24 hours", value: 86400 },
  { label: "48 hours", value: 172800 },
];

// Quick is defined as <24 hours.
const QUICK_THRESHOLD = 86400;

// Turn avgReplyTime into something human readable.
const calcReplyTime = (avgReplyTime) => {
  const match = THRESHOLDS.find((threshold) => threshold.value >= avgReplyTime);

  return ({
    label: match ? match.label : null,
    quick: avgReplyTime < QUICK_THRESHOLD,
  });
};

export default calcReplyTime;
