import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { Hidden } from "@material-ui/core";
import { InputLocation, Card } from "~/src/components/global";
import LOCATION_INITIAL_VALUES from "~/src/constants/initialValues/location";

const MyOnChangeComponent = ({ onChange }) => {
  const { values } = useFormikContext();
  React.useEffect(() => {
    onChange(values.location);
  }, [values]);

  return null;
};

const Filter = ({ onChange, disabled, initialValues }) => {
  const formikInitialValues = LOCATION_INITIAL_VALUES;
  if (initialValues) {
    formikInitialValues.label = initialValues.label;
    formikInitialValues.latitude = initialValues.latitude;
    formikInitialValues.longitude = initialValues.longitude;
  }

  const markup = (
    <Formik initialValues={{ location: formikInitialValues }}>
      {() => (
        <Form>
          <MyOnChangeComponent onChange={onChange} />
          <InputLocation label={null} disabled={disabled} />
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Hidden xsDown>
        <Card title="Location">
          {markup}
        </Card>
      </Hidden>
      <Hidden smUp>
        {markup}
      </Hidden>
    </div>
  );
};

export default Filter;
