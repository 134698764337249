import React from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/PlaceOutlined";
import { renderLocation } from "~/src/utils";

function TeacherDisplayLocation({ location }) {
  if (!location) return null;

  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <PlaceIcon />
        </ListItemIcon>
        <ListItemText
          primary={renderLocation(location)}
        />
      </ListItem>
    </List>
  );
}

export default TeacherDisplayLocation;
