import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  Avatar, Flex, Dialog, Link,
} from "~/src/components/global";
import { getMonthAndYear } from "~/src/components/shared";
import { getThumbnailURL } from "~/src/api";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "block",
    marginTop: 2,
  },
  photoWrapper: {
    margin: theme.spacing(-2),
  },
  photo: {
    display: "block",
    width: "100%",
  },
}));

function TeacherDisplaySafetyCheck({ safetyCheck }) {
  if (!safetyCheck) return null;

  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const dateMarkup = safetyCheck.expiryDateYear ? `Valid until ${getMonthAndYear({
    dateYear: safetyCheck.expiryDateYear,
    dateMonth: safetyCheck.expiryDateMonth,
  })}` : safetyCheck.detail;
  const secondaryMarkup = safetyCheck.photo ? (
    <>
      {dateMarkup}

      <div className={classes.link}>
        <Link onClick={toggleModal}>
          <Flex spaceChildren="tight" justify="start">
            <Avatar
              alt={safetyCheck.label}
              src={getThumbnailURL({
                url: safetyCheck.photo,
                size: 128,
              })}
              variant="rounded"
              size="small"
              outlined
            />
            <div>View certificate</div>
          </Flex>
        </Link>
      </div>
    </>
  ) : dateMarkup;

  return (
    <>
      <ListItem>
        <ListItemIcon>
          {safetyCheck.icon}
        </ListItemIcon>
        <ListItemText
          primary={safetyCheck.label}
          secondary={secondaryMarkup}
          secondaryTypographyProps={{
            component: "div",
          }}
        />
      </ListItem>


      {safetyCheck.photo && (
        <Dialog
          onClose={toggleModal}
          id="view-safety-check"
          open={modalOpen}
          title={safetyCheck.label}
          subtitle={dateMarkup}
          maxWidth="sm"
        >
          <div className={classes.photoWrapper}>
            <img className={classes.photo} src={safetyCheck.photo} alt={safetyCheck.label} />
          </div>
        </Dialog>
      )}
    </>
  );
}

export default TeacherDisplaySafetyCheck;
