import MONTHS_OF_THE_YEAR from "~/src/constants/months";

function getMonthAndYear(props) {
  const {
    dateMonth, dateYear,
  } = props;
  if (!dateYear) return null;

  if (!dateMonth) return dateYear;

  const dateMonthText = MONTHS_OF_THE_YEAR.find((month) => month.value === dateMonth).label;
  return `${dateMonthText} ${dateYear}`;
}

export default getMonthAndYear;
