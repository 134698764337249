/* eslint-disable no-await-in-loop */
import React from "react";
import { List, Snackbar } from "@material-ui/core";
import {
  Flex, FormLayout, Button, Link,
} from "~/src/components/global";
import { TeacherDisplayExperience } from "~/src/components/teachers";
import ExperienceDialog from "./ExperienceDialog";

function TeacherFormExperience(props) {
  const {
    teacher, studios, back, forward,
  } = props;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [experience, setExperience] = React.useState(null);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const openModal = (experience) => {
    setModalOpen(true);
    setExperience(experience);
  };

  const closeModals = () => {
    setModalOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage("");
  };

  return (
    <FormLayout>
      {teacher.experiences && teacher.experiences.map((experience) => (
        <Flex key={experience.id}>
          <List>
            <TeacherDisplayExperience experience={experience} />
          </List>
          <Button onClick={() => openModal(experience)}>Edit</Button>
        </Flex>
      ))}

      <Button variant="text" color="primary" onClick={() => openModal()}>Add Experience</Button>

      <ExperienceDialog
        teacher={teacher}
        studios={studios}
        modalOpen={modalOpen}
        closeModals={closeModals}
        experience={experience}
        experiences={teacher.experiences}
        setSnackbarMessage={setSnackbarMessage}
      />

      <Snackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />

      <FormLayout.Footer>
        {back && back.route && (
          <Link href={back.route} as={back.routeAs} unstyled>
            <Button>{back.label}</Button>
          </Link>
        )}

        {forward && forward.route && (
          <Link href={forward.route} as={forward.routeAs} unstyled>
            <Button color="primary">{forward.label}</Button>
          </Link>
        )}
      </FormLayout.Footer>
    </FormLayout>
  );
}

export default TeacherFormExperience;
