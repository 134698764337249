import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { getThumbnailURL } from "~/src/api";

import { Video } from "~/src/components/global";
import { TeacherHeroImage } from "~/src/components/teachers";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    overflowX: "auto",
    scrollSnapType: "x mandatory",
    scrollBehaviour: "smooth",
    margin: [[0, theme.spacing(-2)]],
    [theme.breakpoints.up("sm")]: {
      margin: [[0, theme.spacing(-3)]],
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(-0.5),
    },
  },
  containerInner: {
    display: "flex",
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    "& $item": {
      marginLeft: theme.spacing(-1),
    },
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(3),
      "& $item": {
        marginLeft: theme.spacing(-2),
      },
    },
    [theme.breakpoints.up("md")]: {
      flexWrap: "wrap",
      paddingRight: 0,
      "& $item": {
        marginLeft: 0,
      },
    },
  },
  item: {
    paddingLeft: theme.spacing(2),
    flexShrink: 0,
    scrollSnapAlign: "center",
    [theme.breakpoints.up("sm")]: {
      scrollSnapAlign: "start",
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0.5),
      width: "50%",
    },
  },
  inner: {
    width: "80vw",
    [theme.breakpoints.up("sm")]: {
      width: `calc(50vw - ${theme.spacing(3.5)}px)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  itemMd: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0.5),
      width: "50%",
    },
  },
  itemLg: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0.5),
      width: "100%",
    },
  },
}));

const renderItem = ({
  photo, index, alt, photos,
}) => {
  const classes = useStyles();
  let itemClassnames = classes.item;
  let size;

  if (photos.length === 1) {
    itemClassnames = classNames(
      classes.item,
      classes.itemLg,
    );
  } if (photos.length === 2) {
    itemClassnames = classNames(
      classes.item,
      classes.itemMd,
    );
    size = "tall";
  }

  return (
    <div className={itemClassnames} key={index}>
      <div className={classes.inner}>
        <TeacherHeroImage
          source={getThumbnailURL({
            alt,
            url: photo,
            size: 1024,
          })}
          size={size}
        />
      </div>
    </div>
  );
};

const Gallery = ({ hero, photos, alt }) => {
  const classes = useStyles();

  const renderVideo = (video, poster) => (
    <Video
      autoplay
      source={video}
      poster={getThumbnailURL({
        url: poster,
        size: 128,
      })}
    />
  );

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <div className={classes.inner}>
          {hero.video ? renderVideo(hero.video, hero.poster) : (
            <TeacherHeroImage
              source={getThumbnailURL({
                alt,
                url: hero,
                size: 1024,
              })}
            />
          )}
        </div>
      </div>
      <div className={classes.containerInner}>
        {photos && photos.map((photo, index) => (
          renderItem({
            photo, index, alt, photos,
          })
        ))}
      </div>
    </div>
  );
};

export default Gallery;
