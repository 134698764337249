import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import PlaceholderIcon from "@material-ui/icons/Mood";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingBottom: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
  },
  tall: {
    [theme.breakpoints.up("md")]: {
      paddingBottom: `calc(200% + ${theme.spacing()}px)`,
    },
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: "cover",
    display: "block",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
  },
  emptyState: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    background: fade(theme.palette.text.primary, 0.05),
    borderRadius: theme.shape.borderRadius,
  },
  emptyStateIcon: {
    width: "100%",
    height: "auto",
    color: theme.palette.text.disabled,
    maxWidth: theme.spacing(8),
    margin: "auto",
  },
}));

function TeacherHeroImage({ source, alt, size }) {
  const classes = useStyles();

  const wrapperClasses = classNames(
    classes.wrapper,
    size === "tall" && classes.tall,
  );

  return (
    <div className={wrapperClasses}>
      {source
        ? <img src={source} className={classes.image} alt={`${alt}`} />
        : (
          <div className={classes.emptyState}>
            <PlaceholderIcon className={classes.emptyStateIcon} />
          </div>
        )}
    </div>
  );
}

export default TeacherHeroImage;
