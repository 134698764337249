import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  carousel: {
    display: "flex",
    scrollSnapType: "x mandatory",
    overflowX: "auto",
    margin: theme.spacing(-2),
  },
  item: {
    flexShrink: 0,
    border: `solid transparent ${theme.spacing(2)}px`,
    borderRightWidth: 0,
    width: "80vw",
    scrollSnapAlign: "start",
    "&:last-child": {
      borderRightWidth: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.up("md")]: {
      width: "calc(33.3% - 4px)",
    },
  },
}));

function Carousel({ items, listItem }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.carousel}>
        {items.map((result) => (
          <div className={classes.item} key={result.id}>
            {listItem(result)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
