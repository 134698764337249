import React from "react";
import { connect } from "react-redux";

import { firebase, setUserTracking } from "~/src/api";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount = async () => {
      const { onSetAuthUser } = this.props;

      this.listener = firebase.onAuthUserListener(
        (authUser) => {
          setUserTracking(authUser);
          onSetAuthUser(authUser);
        },
        () => {
          onSetAuthUser(false);
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <Component {...this.props} />
      );
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch({ type: "AUTH_USER_SET", authUser }),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
