import React from "react";
import Router from "next/router";
import { Grid } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import * as ROUTES_ADMIN from "~/src/constants/routesAdmin";
import { firebase } from "~/src/api";
import SKILLS from "~/src/constants/skills";
import JOB_SOURCES from "~/src/constants/jobSources";
import LOCATION_INITIAL_VALUES from "~/src/constants/initialValues/location";

import {
  Banner,
  Button,
  Card,
  Divider,
  Link,
  FormLayout,
  InputSelect,
  InputRadioButtons,
} from "~/src/components/global";
import { StudioGridItem } from "~/src/components/studios";
import LocationInput from "./LocationInput";

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().required(),
  description: yup.string().required(),
  studio: yup.string(),
  source: yup.object().shape({
    type: yup.string().required(),
  }),
});

function AdminJobForm(props) {
  const {
    back, forward, job, studios,
  } = props;

  return (
    <Formik
      initialValues={{
        name: job && job.name || "",
        type: job && job.type || "yoga",
        description: job && job.description || "",
        studio: job && job.studio ? job.studio.id : "",
        useStudioLocation: "no",
        location: job && job.location || LOCATION_INITIAL_VALUES,
        source: job && job.source || {
          type: "bolstr",
          link: "",
          name: "",
        },
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const {
          name, type, description, studio, source, location, useStudioLocation, // eslint-disable-line camelcase
        } = values;

        let locationToUse;
        if (useStudioLocation === "no") {
          locationToUse = location;
        } else {
          const studioData = studios.find((_studio) => _studio.id === studio);
          locationToUse = studioData.locations.find((_location) => _location.id === useStudioLocation);
        }

        const data = {
          name,
          type,
          description,
          studio,
          source,
          location: locationToUse,
        };

        if (job) {
          await firebase.GeoFirestore.collection("jobs").doc(job.id).update({
            ...data,
            coordinates: new firebase.firestore.GeoPoint(data.location.latitude, data.location.longitude),
          });
          Router.push("/jobs/[id]", `/jobs/${job.id}`);
        } else {
          const jobRef = firebase.jobs().doc();
          await jobRef.set({
            ...data,
            timestamp: firebase.fieldValue.serverTimestamp(),
            closed: false,
          });
          Router.push("/jobs/[id]", `/jobs/${jobRef.id}`);
        }
      }}
    >
      {({
        isSubmitting, values, setFieldValue,
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div>
                {values.studio ? <StudioGridItem studio={studios.find((_studio) => _studio.id === values.studio)} /> : <Banner status="info">Select a studio to begin.</Banner>}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                title="Studio"
                action={{
                  label: "Add Studio",
                  href: ROUTES_ADMIN.STUDIOS_ADD,
                }}
              >
                <FormLayout>
                  <Field
                    label="Studio"
                    name="studio"
                    component={InputSelect}
                    options={studios.map((studio) => ({
                      value: studio.id,
                      label: studio.name,
                    }))}
                  />
                </FormLayout>
              </Card>

              <Divider />

              <Card title="Job">
                <FormLayout>
                  <FormLayout.Group>
                    <Field
                      label="Job Name"
                      name="name"
                      component={TextField}
                    />

                    <Field
                      label="Job Type"
                      name="type"
                      component={InputSelect}
                      options={SKILLS.map((skill) => ({
                        value: skill.name,
                        label: skill.labelEmoji,
                      }))}
                    />
                  </FormLayout.Group>

                  <Field
                    label="Description"
                    name="description"
                    component={TextField}
                    multiline
                  />
                </FormLayout>
              </Card>

              <Divider />

              <Card title="Source">
                <FormLayout>
                  <InputRadioButtons
                    label="Source Type"
                    name="source.type"
                    options={JOB_SOURCES.map((source) => ({
                      value: source.name,
                      label: source.label,
                    }))}
                    isSubmitting={isSubmitting}
                  />

                  {values.source.type !== "bolstr" && (
                    <Field
                      label="Source Link"
                      name="source.link"
                      component={TextField}
                      helperText="URL or Email"
                    />
                  )}

                  {values.source.type !== "bolstr" && (
                    <Field
                      label="Source’s Name"
                      placeholder="Jane"
                      name="source.name"
                      component={TextField}
                      helperText="Optional"
                    />
                  )}
                </FormLayout>
              </Card>

              <Divider />

              <Card title="Location">
                <LocationInput
                  studios={studios}
                  values={values}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                />
              </Card>

              <FormLayout>
                <FormLayout.Footer>
                  {back && back.route && (
                    <Link href={back.route} as={back.routeAs} unstyled>
                      <Button>{back.label}</Button>
                    </Link>
                  )}

                  <Button
                    color="primary"
                    loading={isSubmitting}
                    type="submit"
                  >
                    {forward.label}
                  </Button>
                </FormLayout.Footer>
              </FormLayout>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default AdminJobForm;
