import React from "react";
import InsuranceIcon from "@material-ui/icons/VerifiedUserOutlined";
import { FirstAidIcon, CprIcon } from "~/src/icons";

const SAFETY_CHECKS = [
  {
    label: "Insurance",
    detail: "Up-to-date",
    name: "insurance",
    icon: <InsuranceIcon />,
  },
  {
    label: "First Aid",
    detail: "Completed in the last 3 years",
    name: "firstAid",
    icon: <FirstAidIcon />,
  },
  {
    label: "CPR",
    detail: "Completed in the last 12 months",
    name: "cpr",
    icon: <CprIcon />,
  },
];

export default SAFETY_CHECKS;
