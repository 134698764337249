import React from "react";
import { Typography } from "@material-ui/core";
import { renderLocation } from "~/src/utils";
import { Emoji, Flex } from "~/src/components/global";
import SKILLS from "~/src/constants/skills";

function TeacherDisplaySkills({ teacher, minimal, includeLocation }) {
  if (!teacher) return null;

  if (minimal) {
    // Convert skills map to Array
    const skillsArray = [];
    for (const skill of SKILLS) {
      if (teacher.skills && teacher.skills[skill.name]) {
        skillsArray.push(skill.label);
      }
    }

    // Just text
    return skillsArray.join(", ");
  }

  // Convert skills map to Array
  const skillsArray = [];
  for (const skill of SKILLS) {
    if (teacher.skills && teacher.skills[skill.name]) {
      skillsArray.push(skill);
    }
  }

  const locationMarkup = includeLocation && teacher.location && (teacher.location.label || teacher.location.name) ? (
    <Typography>
      <Emoji>🌏</Emoji>
      {renderLocation(teacher.location)}
    </Typography>
  ) : null;

  // With emojis
  return (
    <Flex justify="start" spaceChildren wrap>
      {skillsArray.map((skill, index) => (
        <Typography key={index}>
          <Emoji>{skill.emoji}</Emoji>
          {skill.label}
        </Typography>
      ))}
      {locationMarkup}
    </Flex>
  );
}

export default TeacherDisplaySkills;
