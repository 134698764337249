import React from "react";
import classNames from "classnames";
import {
  Hidden,
  Button,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  Popover,
  CardContent,
  Checkbox,
  Radio,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";


import {
  Banner,
  Card,
  Spacing,
} from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  filter: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.body1.fontSize,
    height: theme.shape.actionHeightSmall,
    display: "inline-flex",
    alignItems: "center",
    boxShadow: `0 0 0 1px ${theme.palette.border.base}`,
    background: theme.palette.background.paper,
    borderRadius: 100,
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
      background: theme.palette.background.paper,
      boxShadow: `0 0 0 1px ${theme.palette.border.dark}`,
    },
  },
  active: {
    background: theme.palette.background.dark,
    fontWeight: theme.typography.fontWeightBold,
    boxShadow: [[`0 0 0 1px ${theme.palette.text.primary}, inset 0 0 0 0.5px ${theme.palette.text.primary}`], "!important"],
    borderWidth: 2,
    "& $icon": {
      color: "inherit",
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(-1),
  },
  popover: {
    marginTop: theme.spacing(),
  },
}));

const Filter = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    value, options, onChange, multiple, title, titlePlural, info, disabled,
  } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (event) => {
    if (!multiple) handleClose();
    onChange(event);
  };

  const filterClasses = classNames(
    classes.filter,
    value && value.length && classes.active,
  );

  const buttonMarkup = () => {
    if (value && value.length) {
      if (multiple) {
        if (value.length === 1) {
          return options.find((option) => option.value === value[0]).label;
        }
        return `${value.length} ${titlePlural}`;
      }
      return options.find((option) => option.value === value).label;
    }
    return title;
  };

  const menuMarkup = multiple ? (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          control={(
            <Checkbox
              checked={Boolean(value.find((_value) => _value == option.value))}
              value={option.value}
            />
          )}
          onChange={handleOnChange}
          label={(
            <div>
              <Typography>{option.label}</Typography>
              <Typography color="textSecondary">{option.detail}</Typography>
            </div>
          )}
        />
      ))}
    </FormGroup>
  ) : (
    <RadioGroup>
      <FormControlLabel
        control={(
          <Radio
            checked={!value}
          />
        )}
        onChange={handleOnChange}
        label={`All ${titlePlural}`}
        data-cy={`all-${titlePlural}`}
      />
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          control={(
            <Radio
              checked={option.value === value}
              value={option.value}
            />
          )}
          onChange={handleOnChange}
          label={(
            <div>
              <Typography>{option.labelEmoji || option.label}</Typography>
              <Typography color="textSecondary">{option.detail}</Typography>
            </div>
          )}
        />
      ))}
    </RadioGroup>
  );

  return (
    <div>
      <Hidden xsDown>
        <Card title={title}>
          {menuMarkup}
        </Card>
      </Hidden>
      <Hidden smUp>
        <Button
          classes={{
            endIcon: classes.icon,
          }}
          endIcon={<ArrowDropDownIcon />}
          className={filterClasses}
          onClick={handleClick}
          data-cy={`filter-${title}`}
          disabled={disabled}
        >
          {buttonMarkup()}
        </Button>
        <Popover
          classes={{
            root: classes.popover,
          }}
          id={title}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          variant="menu"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transitionDuration={0}
        >
          <CardContent>
            {info && (
              <>
              <Banner status="info">{info}</Banner>
              <Spacing tight />
              </>
            )}
            {menuMarkup}
          </CardContent>
        </Popover>
      </Hidden>
    </div>
  );
};

export default Filter;
