import React from "react";
import { Formik, Form, Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import * as yup from "yup";
import { firebase, uploadPhoto } from "~/src/api";

import {
  Banner,
  Dialog,
  Button,
  FormLayout,
  InputSelect,
  InputImageUploader,
} from "~/src/components/global";

import MONTHS_OF_THE_YEAR from "~/src/constants/months";
import YEARS_FUTURE from "./yearsFuture";

const MONTHS = MONTHS_OF_THE_YEAR.slice(0);
MONTHS.unshift({ label: "Month" });

function SafetyCheckDialog(props) {
  const {
    modalOpen, closeModals, teacher, safetyCheck, safetyChecks, setSnackbarMessage,
  } = props;
  const [info, setInfo] = React.useState(false);
  const [error, setError] = React.useState(false);

  const saveSafetyCheck = async (data) => {
    // Update existing data
    safetyChecks[safetyCheck.name] = data;

    firebase.teacher(teacher.authID).update({
      safetyChecks,
    });

    setSnackbarMessage(`${safetyCheck.label} saved`);
    closeModals();
  };

  return (
    <Dialog
      onClose={closeModals}
      id="add-edit-safety-check"
      open={modalOpen}
      title={`Edit ${safetyCheck && safetyCheck.label}`}
    >
      <Formik
        initialValues={{
          active: safetyCheck && safetyCheck.active || false,
          expiryDateMonth: safetyCheck && safetyCheck.expiryDateMonth || "",
          expiryDateYear: safetyCheck && safetyCheck.expiryDateYear || "",
          photo: safetyCheck && safetyCheck.photo || "",
          newPhoto: "",
        }}
        validationSchema={yup.object().shape({
          active: yup.boolean(),
          expiryDateMonth: yup.number(),
          expiryDateYear: yup.number(),
          photo: yup.string(),
        })}
        onSubmit={async (values) => {
          const { newPhoto, ...otherValues } = values;
          const data = { ...otherValues };

          data.photo = newPhoto ? await uploadPhoto({
            photo: newPhoto,
            subdirectory: "qualifications",
            setInfo,
            setError,
          }) : values.photo;

          saveSafetyCheck(data);
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <FormLayout>
              {info && <Banner status="info">{info}</Banner>}
              {error && <Banner status="danger">{error.message}</Banner>}

              <Field
                Label={{
                  label: "Active",
                }}
                name="active"
                component={CheckboxWithLabel}
              />

              {values.active && (
                <FormLayout.Group>
                  <Field
                    label="Expiry Date (Optional)"
                    name="expiryDateMonth"
                    options={MONTHS}
                    component={InputSelect}
                  />
                  <Field
                    label="&nbsp;"
                    name="expiryDateYear"
                    options={YEARS_FUTURE}
                    component={InputSelect}
                  />
                </FormLayout.Group>
              )}

              {values.active && (
                <InputImageUploader
                  oldImage={values.photo}
                  newImage={values.newPhoto}
                  setFieldValue={setFieldValue}
                  label="Certificate (Optional)"
                  name="newPhoto"
                  variant="rounded"
                  outlined
                  disabled={isSubmitting}
                />
              )}

              <FormLayout.Footer>
                <Button onClick={closeModals} loading={isSubmitting}>Cancel</Button>
                <Button
                  color="primary"
                  loading={isSubmitting}
                  type="submit"
                >
                  Save Changes
                </Button>
              </FormLayout.Footer>
            </FormLayout>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default SafetyCheckDialog;
