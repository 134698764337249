import React from "react";
import { getTeachers, getTeacherData } from "~/src/api";
import { showIntercomMessenger, hideIntercomMessenger } from "~/src/api/intercom";
import {
  Card, Loading, Spacing, Dialog,
} from "~/src/components/global";
import { TeacherGrid, TeacherDetail } from "~/src/components/teachers";

class JobCandidates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      suggestedTeachers: null,
      displayTeacher: null,
    };

    this.handleClickTeacher = this.handleClickTeacher.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  handleCloseModal() {
    showIntercomMessenger();
    this.setState({
      displayTeacher: null,
    });
  }

  handleClickTeacher(teacher) {
    hideIntercomMessenger();
    this.setState({
      displayTeacher: teacher,
    });
  }

  loadData = async () => {
    this.setState({
      loading: true,
    });

    const { job } = this.props;

    // Load Suggested Teachers
    const suggestedTeachers = await getTeachers({ discipline: job.type });
    const suggestedPromises = suggestedTeachers.docs.map((teacher) => getTeacherData({ teacher }));

    this.setState({
      loading: false,
      suggestedTeachers: await Promise.all(suggestedPromises),
    });
  }

  renderSections() {
    const { job, authUser } = this.props;
    const { suggestedTeachers } = this.state;

    // Exclude the self
    const excludeTeachers = [];
    if (authUser) {
      excludeTeachers.push(authUser.authID);
    }

    return (
      <TeacherGrid
        teachers={suggestedTeachers}
        excludeTeachers={excludeTeachers}
        skillValue={job.type}
        TeacherGridItemProps={{
          onClick: this.handleClickTeacher,
        }}
      />
    );
  }

  render() {
    const { authUser } = this.props;
    const { loading, displayTeacher } = this.state;

    return (
      <>
        <Spacing />

        <Card title="Job Candidates" well>
          {loading ? <Loading /> : this.renderSections()}

          {displayTeacher && (
            <Dialog onClose={this.handleCloseModal} id="teacher-detail" open title={displayTeacher.firstname} noPadding maxWidth="sm">
              <TeacherDetail
                hideTitle
                authUser={authUser}
                teacher={displayTeacher}
              />
            </Dialog>
          )}
        </Card>
      </>
    );
  }
}

export default JobCandidates;
