import React from "react";
import {
  InputLabel, FormControl, FormGroup, FormHelperText, Typography,
} from "@material-ui/core";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";

const InputRadioButtons = (props) => {
  const {
    label, name, options, isSubmitting, helperText,
  } = props;

  return (
    <FormControl variant="outlined">
      <InputLabel>{label}</InputLabel>

      <FormControl>
        <FormGroup>
          {options.map((option) => (
            <Field
              Label={{
                label: (
                  <>
                    <Typography>{option.label}</Typography>
                    {option.detail && <Typography color="textSecondary">{option.detail}</Typography>}
                  </>
                ),
              }}
              name={`${name}.${option.value}`}
              component={CheckboxWithLabel}
              disabled={isSubmitting}
              key={option.value}
            />
          ))}
        </FormGroup>
      </FormControl>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default InputRadioButtons;
