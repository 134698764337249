import React from "react";

import { Flex } from "~/src/components/global";

export default function Footer(props) {
  const { children } = props;

  return (
    <Flex justify="end" spaceChildren>
      {children}
    </Flex>
  );
}
