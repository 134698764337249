import React from "react";
import {
  CircularProgress,
  Button as MuiButton,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  button: {
    position: "relative",
  },
  loadingLabel: {
    color: "transparent",
  },
  buttonProgress: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 20,
    height: 20,
    margin: "auto",
  },
}));

const Button = React.forwardRef((props, ref) => {
  const {
    children, disabled, loading, ...otherProps
  } = props;
  const classes = useStyles();

  return (
    <MuiButton
      classes={{
        root: classes.button,
      }}
      disabled={disabled || loading}
      ref={ref}
      {...otherProps}
    >
      <span className={loading ? classes.loadingLabel : undefined}>{children}</span>
      {loading && <div className={classes.buttonProgress}><CircularProgress color="inherit" size={20} /></div>}
    </MuiButton>
  );
});

export default Button;
