export const LOCATIONS = [
  {
    name: "New South Wales",
    label: "NSW",
  },
  {
    name: "Victoria",
    label: "Victoria",
  },
  {
    name: "Australian Capital Territory",
    label: "ACT",
  },
  {
    name: "Queensland",
    label: "Queensland",
  },
  {
    name: "South Australia",
    label: "South Australia",
  },
  {
    name: "Northern Territory",
    label: "Northern Territory",
  },
  {
    name: "Western Australia",
    label: "Western Australia",
  },
  {
    name: "Tasmania",
    label: "Tasmania",
  },
];

export default LOCATIONS;
