import React from "react";
import classNames from "classnames";
import { elementChildren, wrapWithComponent } from "@shopify/react-utilities";
import { makeStyles } from "@material-ui/core/styles";

import { Item } from "./components";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    margin: 0,
    padding: 0,
    flexGrow: 1,
    listStyle: "none",
  },
  spaceChildren: {
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
  spaceChildrenTight: {
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
  spaceChildrenLoose: {
    marginTop: theme.spacing(-3),
    marginLeft: theme.spacing(-3),
  },
  align_start: {
    alignItems: "flex-start",
  },
  align_center: {
    alignItems: "center",
  },
  align_end: {
    alignItems: "flex-end",
  },
  justify_spaceBetween: {
    justifyContent: "space-between",
  },
  justify_start: {
    justifyContent: "flex-start",
  },
  justify_center: {
    justifyContent: "center",
  },
  justify_end: {
    justifyContent: "flex-end",
  },
  vertical: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  wrap: {
    flexWrap: "wrap",
  },
}));

function Flex(props) {
  const {
    children, align, justify, vertical, spaceChildren, wrap,
  } = props;
  const classes = useStyles();

  const flexClasses = classNames(
    classes.flex,
    classes[`align_${align}`],
    classes[`justify_${justify}`],
    vertical && classes.vertical,
    spaceChildren === true && classes.spaceChildren,
    spaceChildren === "tight" && classes.spaceChildrenTight,
    spaceChildren === "loose" && classes.spaceChildrenLoose,
    wrap && classes.wrap,
  );

  const itemMarkup = spaceChildren ? elementChildren(children).map((child, index) => {
    const props = { key: index, spaceChildren };
    return wrapWithComponent(child, Item, props);
  }) : children;

  return (
    <div className={flexClasses}>
      {itemMarkup}
    </div>
  );
}

Flex.Item = Item;

Flex.defaultProps = {
  children: undefined,
  align: "center",
  justify: "spaceBetween",
  vertical: false,
};

export default Flex;
