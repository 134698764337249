import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Typography } from "@material-ui/core";
import JOB_PLACEHOLDERS from "~/src/constants/jobPlaceholders";
import LOCATION_INITIAL_VALUES from "~/src/constants/initialValues/location";
import {
  Banner, Button, FormLayout, Spacing,
} from "~/src/components/global";
import {
  JobCard,
  StudioCard,
  ContactCard,
  SubmitDialog,
} from "./components";

const initialValues = {
  name: "",
  description: "",
  type: "",
  studio: null,
  newStudioName: "",
  newStudioWebsite: "",
  newStudioDescription: "",
  newStudioPhoto: "",
  selectedLocation: "0",
  location: LOCATION_INITIAL_VALUES,
  source: {
    type: "email",
    link: "",
    firstname: "",
  },
};

export default function FormJob({
  authUser, studios,
}) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const placeholderIndex = Math.floor(Math.random() * JOB_PLACEHOLDERS.NAME.length);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getStudioLocation = (selectedLocation, studio) => {
    const selectedStudio = studios.find((_studio) => _studio.id === studio);

    if (selectedStudio && selectedStudio.locations && selectedStudio.locations.length && parseInt(selectedLocation) < selectedStudio.locations.length) {
      return selectedStudio.locations[parseInt(selectedLocation)];
    }
    return null;
  };

  const getLocationToUse = (values) => {
    const { selectedLocation, studio, location } = values;

    return getStudioLocation(selectedLocation, studio) || location;
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Job name is required"),
    description: yup.string().required("Job description is required"),
    type: yup.string().required("Job skillset is required"),
    studio: yup.string().nullable().required("Studio is required"),
    newStudioName: yup.string().when("studio", {
      is: (studio) => studio === "new",
      then: yup.string().required("Studio name is required"),
      otherwise: yup.string(),
    }),
    newStudioWebsite: yup.string(),
    newStudioDescription: yup.string(),
    newStudioPhoto: yup.string(),
    location: yup.mixed().when(["selectedLocation", "studio"], {
      is: (selectedLocation, studio) => getStudioLocation(selectedLocation, studio),
      then: yup.object(),
      otherwise: yup.object().shape({
        label: yup.string().required("Location is required"),
      }),
    }),
    source: yup.object().shape({
      type: yup.string(),
      firstname: yup.string(),
      link: yup.string().required("Email address is required"),
    }),
  });

  return (
    <>
      <Typography variant="h1">Post Job to Bolstr</Typography>

      <Spacing />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          toggleModal();
        }}
      >
        {({
          isSubmitting, values, setFieldValue, errors,
        }) => (
          <Form>
            <FormLayout>
              <StudioCard values={values} studios={studios} setFieldValue={setFieldValue} isSubmitting={isSubmitting} />

              <JobCard isSubmitting={isSubmitting} placeholderIndex={placeholderIndex} errors={errors} />

              <ContactCard authUser={authUser} isSubmitting={isSubmitting} values={values} />

              <SubmitDialog
                values={values}
                studios={studios}
                modalOpen={modalOpen}
                toggleModal={toggleModal}
                getStudioLocation={getStudioLocation}
                getLocationToUse={getLocationToUse}
              />

              <Button
                color="primary"
                type="submit"
                fullWidth
              >
                Preview Job
              </Button>

              {Object.keys(errors).length ? <Banner status="danger">Fix all errors to preview this job.</Banner> : null}
            </FormLayout>
          </Form>
        )}
      </Formik>
    </>
  );
}
