// !! SHARED ACROSS APP AND CLOUD FUNCTIONS

const renderLocation = (location) => {
  if (!location || (!location.name && !location.suburb)) return null;

  let { state } = location;
  if (state === "New South Wales") state = "NSW";
  if (state === "Queensland") state = "QLD";
  if (state === "Victoria") state = "VIC";

  return `${location.suburb || location.name}, ${state}`;
};

export default renderLocation;
