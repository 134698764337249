import React from "react";
import { analytics, addJobApplication } from "~/src/api";
import { Button } from "~/src/components/global";
import ApplyViaEmail from "./ApplyViaEmail";
import ApplyViaMessage from "./ApplyViaMessage";
import ApplyViaFacebookJob from "./ApplyViaFacebookJob";

function ApplyExternalWrapper(props) {
  const {
    job, selfTeacher,
  } = props;

  if (!job.source) return null;

  const excitedLineSuffix = job.studio ? ` at ${job.studio && job.studio.name}` : "";
  const excitedLine = `I’m so excited to apply for the ${job.name} job${excitedLineSuffix}`;
  const pitchUrl = `/application/${selfTeacher.username}/${job.id}`;

  const textareaRef = React.useRef(null);
  const [applied, setApplied] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [messageCopied, setMessageCopied] = React.useState(false);
  const [message, setMessage] = React.useState(`Hi ${job.source.name || "there"}!\n
${excitedLine}! You can view my visual resume here: ${process.env.URL_PREFIX}${pitchUrl}\n
If you like, I’d love to set up an interview.\n
Looking forward to hearing back from you!\n
Cheers,
${selfTeacher.firstname} ${selfTeacher.lastname}`);

  const toggleModal = () => {
    analytics.event("job_apply_external");
    if (!applied) {
      addJobApplication({ teacherID: selfTeacher.id, jobID: job.id });
      setApplied(true);
    }
    setModalOpen(!modalOpen);
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const copyToClipboard = () => {
    analytics.event("job_copy_message");
    textareaRef.current.select();
    document.execCommand("copy");
    setMessageCopied(true);
    textareaRef.current.blur();
  };

  const dialogProps = {
    selfTeacher,
    pitchUrl,
    textareaRef,
    message,
    messageCopied,
    modalOpen,
    toggleModal,
    handleChangeMessage,
    copyToClipboard,
    job,
  };

  const dialogMarkup = job.source.type === "facebookPost" || job.source.type === "website" ? (
    <ApplyViaMessage
      {...dialogProps}
    />
  ) : job.source.type === "facebookJob" ? (
    <ApplyViaFacebookJob
      {...dialogProps}
    />
  ) : job.source.type === "email" ? (
    <ApplyViaEmail
      {...dialogProps}
    />
  ) : null;

  return (
    <>
      <Button color="primary" onClick={toggleModal} data-cy="apply-for-job">Apply for Job...</Button>
      {dialogMarkup}
    </>
  );
}

export default ApplyExternalWrapper;
