const LOCATION_INITIAL_VALUES = {
  name: "",
  streetNumber: "",
  streetName: "",
  suburb: "",
  state: "",
  country: "",
  postCode: "",
  label: "",
  latitude: "",
  longitude: "",
};

export default LOCATION_INITIAL_VALUES;
