import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar as MuiAvatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    "&$outlined:after": {
      border: `1px solid ${theme.palette.border.dark}`,
    },
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  auto: {
    width: "100%",
    height: "100%",
  },
  outlined: {
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: `1px solid ${theme.palette.border.base}`,
      borderRadius: theme.shape.borderRadius,
      zIndex: 1,
    },
  },
}));

function Avatar({ size, outlined, ...otherProps }) {
  const classes = useStyles();

  const avatarClasses = classNames(
    classes[size],
    outlined && classes.outlined,
  );

  return (
    <MuiAvatar
      className={avatarClasses}
      {...otherProps}
    />
  );
}

Avatar.defaultProps = {
  size: "medium",
};

export default Avatar;
