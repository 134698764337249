import React, { Fragment } from "react";

import {
  List,
  Divider,
} from "@material-ui/core";

import { JobListItem } from "~/src/components/jobs";

function ChooseJobDialog(props) {
  const {
    authUser, jobs, chats, buttonLoading, handleAddJobToChat, teacher,
  } = props;

  const renderListItem = (job) => {
    // Am I requesting as a studio or as a teacher?
    const self = job.studio && job.studio.id === authUser.studio ? {
      type: "studio",
      id: authUser.studio,
    } : {
      type: "teacher",
      id: authUser.authID,
    };

    // Check if there exists a chat between teacher and jobOwner
    const chat = chats && chats.filter((chat) => chat.members.filter((member) => member === self.id).length);

    // Then check if this job exists in that chat.
    const applied = chat[0] && chat[0].jobs && chat[0].jobs.includes(job.id);

    return (
      <JobListItem
        job={job}
        onClick={() => handleAddJobToChat({ job, self, teacherID: teacher.id })}
        disabled={buttonLoading || applied}
        dataCy="request-for-job-in-modal"
      />
    );
  };

  return (
    <List>
      {jobs && jobs.filter((job) => !job.closed).map((job, index) => (
        <Fragment key={index}>
          {index > 0 && <Divider variant="inset" component="li" />}

          {renderListItem(job)}
        </Fragment>
      ))}
    </List>
  );
}

export default ChooseJobDialog;
