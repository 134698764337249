import React from "react";

import {
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";


function Loading() {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <CircularProgress />
      </Grid>
      <Grid item>
        <Typography>
          Loading...
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Loading;
