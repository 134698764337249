import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { createStudio, uploadPhoto } from "~/src/api";

import {
  Banner,
  FormLayout,
  Button,
  Link,
  InputImageUploader,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  name: yup.string().required("What’s your studio’s name?"),
  photo: yup.string(),
});

class FormStudioCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  saveStudio = async (values) => {
    const { authUser, forward } = this.props;
    const { name } = values;

    const photo = values.newPhoto ? await uploadPhoto({
      photo: values.newPhoto,
      subdirectory: "studios",
      setInfo: this.setInfo,
      setError: this.setError,
    }) : null;

    await createStudio({
      authUser,
      name,
      photo,
    });

    Router.push(forward.route);
  }

  setInfo = (value) => {
    this.setState({ info: value });
  }

  setError = (value) => {
    this.setState({ error: value });
  }

  render() {
    const { back, forward } = this.props;

    return (
      <Formik
        initialValues={{
          name: "",
          photo: "",
          newPhoto: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          this.setState({ error: null });
          this.saveStudio(values);
        }}
      >
        {({
          isSubmitting, values, setFieldValue,
        }) => (
          <Form>
            <FormLayout>
              {this.state.info && <Banner status="info">{this.state.info}</Banner>}
              {this.state.error && <Banner status="danger">{this.state.error.message}</Banner>}

              <Field
                label="Studio Name"
                name="name"
                component={TextField}
                autoFocus
              />

              <InputImageUploader
                oldImage={values.photo}
                newImage={values.newPhoto}
                setFieldValue={setFieldValue}
                label="Logo"
                name="newPhoto"
                disabled={isSubmitting}
              />

              <FormLayout.Footer>
                {back && back.route && (
                <Link href={back.route} unstyled>
                  <Button>{back.label}</Button>
                </Link>
                )}

                <Button
                  color="primary"
                  loading={isSubmitting}
                  type="submit"
                >
                  {forward.label}
                </Button>
              </FormLayout.Footer>
            </FormLayout>
          </Form>
        )}
      </Formik>
    );
  }
}

export default FormStudioCreate;
