import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Truncate from "react-truncate";

const NUM_LINES = 2;

const useStyles = makeStyles((theme) => ({
  truncate: {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": NUM_LINES,
    boxOrient: "vertical",
    lineClamp: NUM_LINES,
  },
  paragraph: {
    margin: 0,
    "& + $paragraph": {
      marginTop: theme.spacing(),
    },
  },
}));

const DescriptionShort = (props) => {
  const classes = useStyles();
  const { color, text } = props;

  if (!text || !text.length) return null;

  return (
    <Typography variant="body1" component="div" color={color} className={classes.truncate}>
      <Truncate lines={NUM_LINES}>
        {text.split("\n").map((paragraph, i) => (
          <span className={classes.paragraph} key={i}>
            {" "}{paragraph}
          </span>
        ))}
      </Truncate>
    </Typography>
  );
};

export default DescriptionShort;
