import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { getTeacherPhoto } from "~/src/api";
import { renderLocation } from "~/src/utils";
import { GridItem } from "~/src/components/global";
import { TeacherDisplaySkills, TeacherHeroImage } from "~/src/components/teachers";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 40,
    [theme.breakpoints.up("sm")]: {
      width: 142,
      margin: theme.spacing(-1),
      marginRight: 0,
    },
  },
}));

function TeacherGridItem(props) {
  const classes = useStyles();
  const {
    teacher,
    onClick,
    disabled,
    GridItemOverrides,
  } = props;

  if (!teacher) return null;

  const handleClick = () => {
    onClick(teacher);
  };

  const teacherName = teacher.pro ? `${teacher.firstname} ${teacher.lastname}` : teacher.firstname;

  return (
    <GridItem
      image={(
        <div className={classes.image}>
          <TeacherHeroImage
            alt={teacher.firstname}
            source={getTeacherPhoto({
              teacher,
              size: 256,
            })}
          />
        </div>
      )}
      title={teacherName}
      badge={teacher.pro && <Chip label="Pro" />}
      tertiary={<TeacherDisplaySkills teacher={teacher} minimal />}
      description={teacher.description}
      location={renderLocation(teacher.location)}
      href="/teachers/[username]"
      as={`/teachers/${teacher.username}`}
      onClick={onClick ? handleClick : null}
      disabled={disabled}
      GridItemOverrides={GridItemOverrides}
      dataCy="grid-item-teacher"
      teacher
    />
  );
}

export default TeacherGridItem;
