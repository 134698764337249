/* eslint-disable max-len */
import React from "react";

const CprIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M16.5,3 C19.4112329,3 21.7328129,5.16206042 21.9784918,7.99993301 L19.9679783,8.00050278 C19.740876,6.25538244 18.3296684,5 16.5,5 C14.96,5 13.46,5.99 12.94,7.36 L12.94,7.36 L11.07,7.36 C10.54,5.99 9.04,5 7.5,5 C5.5,5 4,6.5 4,8.5 C4,11.39 7.14,14.24 11.9,18.55 L11.9,18.55 L12,18.65 L12.1,18.55 C13.9957576,16.8334632 15.6345544,15.3485083 16.9203685,14.0003766 L19.5996236,14.0001619 C18.1087014,15.806265 15.9926366,17.724347 13.45,20.03 L13.45,20.03 L12,21.35 L10.55,20.04 C5.4,15.36 2,12.28 2,8.5 C2,5.42 4.42,3 7.5,3 C9.24,3 10.91,3.81 12,5.09 C13.09,3.81 14.76,3 16.5,3 Z M15.8824447,6.75227484 L18.48,10 L21,10 L21,12 L17.5193752,12 L16.117,10.247 L13.1175553,15.2477252 L10.52,12 L8,12 L8,10 L11.4806248,10 L12.882,11.752 L15.8824447,6.75227484 Z"
    />
  </svg>
);

export default CprIcon;
