export const HOME = "/admin";

export const VIDEOS = "/admin/videos";

export const REFERRALS = "/admin/referrals";

export const SURVEYS = "/admin/surveys";

export const MESSAGES = "/admin/messages";
export const MESSAGES_DETAIL = "/admin/messages/:id";

export const JOBS = "/admin/jobs";
export const JOBS_WEEKLY_ROUNDUP = "/admin/jobs/round-up";
export const JOBS_ADD = "/admin/jobs/add";
export const JOBS_DETAIL_EDIT = "/admin/jobs/:id/edit";

export const STUDIOS = "/admin/studios";
export const STUDIOS_ADD = "/admin/studios/add";
export const STUDIOS_DETAIL = "/admin/studios/:id";
export const STUDIOS_DETAIL_EDIT = "/admin/studios/:id/edit";
export const STUDIOS_DETAIL_EDIT_LOCATIONS = "/admin/studios/:id/edit-locations";
export const STUDIOS_DETAIL_EDIT_STYLES = "/admin/studios/:id/edit-styles";

export const EXPERIENCES = "/admin/experiences";

export const TEACHERS = "/admin/teachers";
export const TEACHERS_ADD = "/admin/teachers/add";
export const TEACHERS_DETAIL = "/admin/teachers/:id";
export const TEACHERS_DETAIL_EDIT = "/admin/teachers/:id/edit";
export const TEACHERS_DETAIL_EDIT_DESCRIPTION = "/admin/teachers/:id/edit-description";
export const TEACHERS_DETAIL_EDIT_PHOTOS = "/admin/teachers/:id/edit-photos";
export const TEACHERS_DETAIL_EDIT_EXPERIENCES = "/admin/teachers/:id/edit-experiences";
export const TEACHERS_DETAIL_EDIT_QUALIFICATIONS = "/admin/teachers/:id/edit-qualifications";
export const TEACHERS_DETAIL_EDIT_LOCATION = "/admin/teachers/:id/edit-location";
export const TEACHERS_DETAIL_EDIT_SKILLS = "/admin/teachers/:id/edit-skills";

export const CLAIM_TEACHER = "/claim/:id";
export const CLAIM_TEACHER_SIGN_UP = "/claim/:id/sign-up";

export const CLAIM_JOB = "/job/:id/claim/:claimID";
export const CLAIM_JOB_SIGN_UP = "/job/:id/claim/:claimID/sign-up";
