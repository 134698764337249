import React from "react";
import Router from "next/router";
import {
  createChat, addTeacherToJob,
} from "~/src/api";


import {
  Dialog,
  Button,
} from "~/src/components/global";

import {
  ChooseJobDialog,
} from "./components";

class ActionButtonsJobButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonLoading: false,
      sentLoadRequest: false,
      menuOpen: false,
    };
  }

  handleToggleDialog = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen });
  }

  handleAddJobToChat = async ({ job, self, teacherID }) => {
    this.setState({ buttonLoading: true });

    const { chats } = this.props;

    // Find the relevant chat, either my teacher to their teacher, or my studio to their teacher.
    const relevantChat = chats && chats.find((chat) => chat.members.includes(self.id));

    // Either use the existing chat, or make a new one.
    const chatRef = relevantChat || await this.handleNewMessageFrom(self);

    // Add the teacher to the job
    await addTeacherToJob({
      job,
      chatRef,
      author: self.id,
      teacherID,
    });

    Router.push("/messages/[id]", `/messages/${chatRef.id}`);
  }

  handleNewMessageFrom = async (self) => {
    const {
      teacher, studio, job,
    } = this.props;

    const chatRef = await createChat({
      self,
      teacher,
      studio,
      job,
    });

    return chatRef;
  }

  render() {
    const {
      authUser, teacher, job, jobs,
    } = this.props;
    const { buttonLoading, menuOpen } = this.state;

    if (job) {
      // Teachers apply for jobs
      if (authUser.teacher) {
        const self = {
          type: "teacher",
          id: authUser.authID,
        };

        return (
          <Button
            color="primary"
            onClick={() => this.handleAddJobToChat({ job, self, teacherID: authUser.authID })}
            loading={buttonLoading}
            data-cy="apply-for-job"
          >
            One-Click Apply
          </Button>
        );
      }
    } else if (teacher) {
      // Teachers or studios request jobs from teachers
      const bolstrJobs = jobs.filter((job) => job.source && job.source.type === "bolstr");

      if (bolstrJobs && bolstrJobs.length) {
        return (
          <>
            <Button color="primary" onClick={this.handleToggleDialog} data-cy="request-for-job">
              Request for Job…
            </Button>

            <Dialog onClose={this.handleToggleDialog} id="request-for-job" open={menuOpen} title="Request for Job" subtitle="Choose which job you want to request this teacher for.">
              <ChooseJobDialog
                {...this.props}
                {...this.state}
                jobs={bolstrJobs}
                handleAddJobToChat={this.handleAddJobToChat}
              />
            </Dialog>
          </>
        );
      }
    }

    // You may get here
    // eg. studio-only on Job Detail page.
    // or if you have no jobs
    return null;
  }
}

export default ActionButtonsJobButton;
