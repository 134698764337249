import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tight: {
    flexBasis: theme.spacing(12),
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  base: {
    flexBasis: theme.spacing(12),
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
  },
}));

export default function Item(props) {
  const { children, tight } = props;
  const classes = useStyles();

  return <div className={tight ? classes.tight : classes.base}>{children}</div>;
}
