import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import {
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  longformContent: {
    "& h1, & h2, & h3, & strong": {
      fontWeight: theme.typography.fontWeightBold,
    },
    "& h1": {
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
    "& h2": {
      fontSize: "1.25rem",
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
    "& h3": {
      fontSize: "1.125rem",
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
    "& p": {
      "&:empty": {
        paddingTop: theme.spacing(2),
        "& + p:empty": {
          marginTop: theme.spacing(-2),
          borderTop: `1px solid ${theme.palette.border.base}`,
        },
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
    "& blockquote": {
      padding: [[theme.spacing(), theme.spacing(1.5)]],
      borderRadius: theme.shape.borderRadius,
      background: fade(theme.palette.primary.main, 0.15),
      marginLeft: 0,
      marginRight: 0,
    },
    "& ol, & ul": {
      paddingLeft: theme.spacing(3),
    },
    "& li": {
      marginTop: theme.spacing(),
    },
    "& a": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& figure": {
      margin: 0,
    },
    "& img": {
      display: "block",
      maxWidth: "100%",
      width: "100%",
    },
    "& hr": {
      borderStyle: "solid",
      borderColor: theme.palette.border.base,
      borderBottom: "none",
      margin: [[theme.spacing(4), 0]],
    },
  },
}));

function LongformContent(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <Typography component="section" className={classes.longformContent}>
      {children}
    </Typography>
  );
}

export default LongformContent;
