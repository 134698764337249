import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import * as ROUTES from "~/src/constants/routes";
import Link from "./Link";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    alignItems: "center",
    "& > * + *": {
      marginLeft: theme.spacing(3),
    },
  },
}));

function InlineNavigation() {
  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <Typography>
        <Link href={ROUTES.JOBS}>
          Jobs
        </Link>
      </Typography>

      <Typography>
        <Link href={ROUTES.TEACHERS}>
          Teachers
        </Link>
      </Typography>

      <Typography>
        <Link href={ROUTES.STUDIOS}>
          Studios
        </Link>
      </Typography>
    </div>
  );
}

export default InlineNavigation;
