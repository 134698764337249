import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, TableRow, Popover } from "@material-ui/core";
import { Flex, Button } from "~/src/components/global";

const useStyles = makeStyles(() => ({
  cell: {
    maxWidth: 320,
  },
}));

export default function AdminTableRow({ row }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      {row.columns && row.columns.map((column, index) => (
        <TableCell key={index} className={classes.cell}>{column}</TableCell>
      ))}
      <TableCell>
        <Flex spaceChildren justify="end">
          {row.primaryAction}
          {row.secondaryAction}
          {row.secondaryActions && (
            <div>
              <Button onClick={handleClick}>Edit...</Button>
              <Popover
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="menu"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transitionDuration={0}
              >
                {row.secondaryActions}
              </Popover>
            </div>
          )}
        </Flex>
      </TableCell>
    </TableRow>
  );
}
