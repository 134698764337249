import React from "react";
import TimeAgo from "react-timeago";

import { Chip, Typography } from "@material-ui/core";
import SKILLS from "~/src/constants/skills";
import { firebase, getJobData } from "~/src/api";
import { renderLocation } from "~/src/utils";

import {
  Button,
  Emoji,
  Flex,
  Spacing,
  Divider,
  Description,
} from "~/src/components/global";

import { DetailHero } from "~/src/components/shared";
import { TeacherGridItem } from "~/src/components/teachers";
import { StudioGridItem } from "~/src/components/studios";
import { JobActionButtons } from "~/src/components/jobs";

class JobDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showJobDetails: false,
      ...props,
    };
  }

  refreshJobData = async () => {
    const { job } = this.props;

    this.setState({
      job: await getJobData({ job: await firebase.job(job.id).get() }),
    });
  }

  showJobDetails = () => {
    this.setState({
      showJobDetails: true,
    });
  }

  renderJobDetails() {
    const { job } = this.state;
    const { teacher, studio } = job;

    const ownerMarkup = studio ? (
      <StudioGridItem
        studio={studio}
      />
    ) : (
      <TeacherGridItem
        teacher={teacher}
      />
    );

    const categoryMarkup = job.type ? (
      <Typography>
        <Emoji>{SKILLS.find((skill) => skill.name === job.type).emoji}</Emoji>
        {SKILLS.find((skill) => skill.name === job.type).label}
      </Typography>
    ) : null;

    const timeMarkup = job.timestamp && job.timestamp.seconds ? (
      <Typography>
        <Emoji>🕑</Emoji>
        <TimeAgo date={job.timestamp && job.timestamp.seconds && new Date(job.timestamp.seconds * 1000)} />
      </Typography>
    ) : null;

    const locationMarkup = job.location && job.location.label ? (
      <Typography>
        <Emoji>🌏</Emoji>
        {renderLocation(job.location)}
      </Typography>
    ) : null;

    const descriptionMarkup = job.description ? (
      <>
        <Divider />

        <Description text={job.description} />
      </>
    ) : null;

    return (
      <>
        <Flex justify="start" spaceChildren wrap>
          {categoryMarkup}
          {locationMarkup}
          {timeMarkup}
        </Flex>

        {descriptionMarkup}

        <Divider />

        {ownerMarkup}
      </>

    );
  }

  render() {
    const { authUser, ActionButtonsOverride } = this.props;
    const { job, showJobDetails } = this.state;

    const selfOwnsJob = authUser && authUser.authID === job.authID;

    const jobDetails = selfOwnsJob ? showJobDetails
      ? this.renderJobDetails()
      : (
        <Button variant="text" color="primary" onClick={this.showJobDetails}>
          Show Job Details
        </Button>
      )
      : this.renderJobDetails();

    return (
      <>
        <DetailHero
          title={job.name}
          badge={job.closed && <Chip label="Filled" />}
        />

        {ActionButtonsOverride || (
          <JobActionButtons
            job={job}
            authUser={authUser}
            refreshJobData={this.refreshJobData}
          />
        )}

        <Spacing tight />

        {jobDetails}
      </>
    );
  }
}

export default JobDetail;
