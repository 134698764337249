/* eslint-disable max-len */
import React from "react";

const FirstAidIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M16,2 L16,8 L22,8 L22,16 L16,16 L16,22 L8,22 L8,16 L2,16 L2,8 L8,8 L8,2 L16,2 Z M14,4 L10,4 L10,10 L4,10 L4,14 L10,14 L10,20 L14,20 L14,14 L20,14 L20,10 L14,10 L14,4 Z"
    />
  </svg>
);

export default FirstAidIcon;
