const JOB_SOURCES = [
  {
    label: "Bolstr",
    name: "bolstr",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Facebook Job",
    name: "facebookJob",
  },
  {
    label: "Facebook Post",
    name: "facebookPost",
  },
  {
    label: "Website",
    name: "website",
  },
];

export default JOB_SOURCES;
