import React from "react";
import NextLink from "next/link";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  unstyled: {
    cursor: "pointer",
    color: "inherit",
    textDecoration: "none",
  },
}));

function Link(props) {
  const {
    children, href, as, onClick, unstyled, external, ...otherProps
  } = props;
  const classes = useStyles();

  return external ? (
    <a className={unstyled ? classes.unstyled : classes.link} href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : onClick ? (
    <a className={unstyled ? classes.unstyled : classes.link} onClick={onClick}>
      {children}
    </a>
  ) : (
    <NextLink href={href} as={as}>
      <a className={unstyled ? classes.unstyled : classes.link} {...otherProps}>
        {children}
      </a>
    </NextLink>
  );
}

export default Link;
