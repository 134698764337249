import React from "react";

import {
  FormLabel,
  Typography,
} from "@material-ui/core";
import { getThumbnailURL } from "~/src/api";


import {
  Avatar,
  Button,
  Card,
  Flex,
  Spacing,
} from "~/src/components/global";

import { Preview } from "./components";

const MAX_IMAGE_SIZE = 3;

class InputImageUploader extends React.Component {
  constructor() {
    super();

    this.state = {
      uploadingNewImage: false,
    };
  }

  componentDidMount = () => {
    const { oldImage } = this.props;

    if (!oldImage) {
      this.setState({
        uploadingNewImage: true,
      });
    }
  }

  toggleNewImageUploader = () => {
    this.setState(({ uploadingNewImage }) => ({
      uploadingNewImage: !uploadingNewImage,
    }));
  }

  render() {
    const { uploadingNewImage } = this.state;
    const {
      label, name, oldImage, newImage, setFieldValue, disabled, ...avatarProps
    } = this.props;

    const newImageSize = newImage && Math.ceil(newImage.size / 1000000);

    const imageSizeWarningMarkup = newImageSize && newImageSize > MAX_IMAGE_SIZE ? (
      <>
        <Spacing extraTight />

        <Typography color="secondary">Your image is {newImageSize}MB. It must be under {MAX_IMAGE_SIZE}MB. Try resizing it, or picking another.</Typography>
      </>
    ) : null;

    return uploadingNewImage ? (
      <Card>
        <FormLabel>New {label}</FormLabel>

        <Spacing extraTight />

        <Flex justify="start" spaceChildren>
          <Preview image={newImage} {...avatarProps} />

          <input
            id={name}
            name={name}
            type="file"
            onChange={(event) => {
              setFieldValue(name, event.currentTarget.files[0]);
            }}
            disabled={disabled}
            accept="image/png, image/jpeg, image/jpg"
            style={{ width: "100%" }}
          />
        </Flex>

        {imageSizeWarningMarkup}

        {oldImage && (
          <>
            <Spacing extraTight />

            <Button color="primary" variant="text" onClick={this.toggleNewImageUploader} disabled={disabled}>
              Cancel
            </Button>
          </>
        )}
      </Card>
    ) : (
      <div>
        <FormLabel>{label}</FormLabel>

        <Spacing extraTight />

        <Flex justify="start" spaceChildren>
          <Avatar size="large" src={getThumbnailURL({ url: oldImage })} {...avatarProps} />

          <Button color="primary" variant="text" onClick={this.toggleNewImageUploader} disabled={disabled}>
            Change
          </Button>
        </Flex>
      </div>
    );
  }
}

export default InputImageUploader;
