import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import * as yup from "yup";
import { Divider } from "@material-ui/core";
import { firebase, uploadPhoto, createStudio } from "~/src/api";
import {
  Banner,
  Button,
  Dialog,
  FormLayout,
  InputSelect,
} from "~/src/components/global";
import { InputStudioAutocomplete } from "~/src/components/studios";
import { ConfirmDeleteButton } from "~/src/components/shared";

import LOCATION_INITIAL_VALUES from "~/src/constants/initialValues/location";
import MONTHS_OF_THE_YEAR from "~/src/constants/months";
import YEARS from "./years";

const MONTHS = MONTHS_OF_THE_YEAR.slice(0);
MONTHS.unshift({ label: "Month" });

function ExperienceDialog(props) {
  const {
    modalOpen, closeModals, teacher, studios, experience, experiences, setSnackbarMessage,
  } = props;
  const [info, setInfo] = React.useState(false);
  const [error, setError] = React.useState(false);

  const deleteExperience = async () => {
    await firebase.db.doc(`teachers/${teacher.id}/experiences/${experience.id}`).delete();

    // Update existing data
    const foundIndex = experiences.findIndex((q) => q.id == experience.id);
    experiences.splice(foundIndex, 1);
    setSnackbarMessage("Experience deleted");
    closeModals();
  };

  const addNewExperience = async (data, selectedStudio) => {
    const experienceRef = await firebase.teacherExperiences(teacher.id).doc();
    await experienceRef.set({
      ...data,
      studio: selectedStudio.id,
    });

    // Update existing data
    experiences.push({
      id: experienceRef.id,
      studio: selectedStudio,
      ...data,
    });
    setSnackbarMessage("New experience added");
    closeModals();
  };

  const saveExistingExperience = async (data, selectedStudio) => {
    await firebase.db.doc(`teachers/${teacher.id}/experiences/${experience.id}`).update({
      ...data,
      studio: selectedStudio.id,
    });

    // Update existing data
    const foundIndex = experiences.findIndex((q) => q.id == experience.id);
    experiences[foundIndex] = {
      id: experience.id,
      studio: selectedStudio,
      ...data,
    };
    setSnackbarMessage("Experience saved");
    closeModals();
  };

  const saveExperience = async (values, studioID) => {
    const data = {
      title: values.title,
      startDateMonth: values.startDateMonth,
      startDateYear: values.startDateYear,
      currentlyWorking: values.currentlyWorking,
      endDateMonth: values.endDateMonth,
      endDateYear: values.endDateYear,
    };

    const selectedStudio = studios.find((_studio) => _studio.id === studioID);

    // Then save the experience with this new studio
    if (experience) {
      saveExistingExperience(data, selectedStudio);
    } else {
      addNewExperience(data, selectedStudio);
    }
  };

  const saveStudio = async (values) => {
    const {
      newStudioName, newStudioWebsite, newStudioDescription, newStudioPhoto,
    } = values;

    // First upload photo
    const photo = newStudioPhoto ? await uploadPhoto({
      photo: newStudioPhoto,
      subdirectory: "studios",
      setInfo,
      setError,
    }) : null;

    // Then create studio
    let studio;
    const studioData = {
      name: newStudioName,
      website: newStudioWebsite,
      description: newStudioDescription,
      photo,
    };
    try {
      studio = await createStudio(studioData);
    } catch (e) {
      setError(e);
    }

    // Then add location
    try {
      const locationRef = await firebase.studioLocations(studio.id).doc();
      await locationRef.set({
        ...values.location,
        primary: true,
      });
    } catch (e) {
      setError(e);
    }

    // Update existing data
    studios.push({
      id: studio.id,
      ...studioData,
    });

    // Then save the experience with this new studio
    saveExperience(values, studio.id);
  };

  return (
    <Dialog
      onClose={closeModals}
      id="add-edit-experience"
      open={modalOpen}
      title={experience ? "Edit Experience" : "Add Experience"}
    >
      <Formik
        initialValues={{
          title: experience && experience.title || "",
          startDateMonth: experience && experience.startDateMonth || "",
          startDateYear: experience && experience.startDateYear || "",
          currentlyWorking: experience ? experience.currentlyWorking : true,
          endDateMonth: experience && experience.endDateMonth || "",
          endDateYear: experience && experience.endDateYear || "",
          studio: experience && experience.studio && experience.studio.id || "",
          newStudioName: "",
          newStudioWebsite: "",
          newStudioDescription: "",
          newStudioPhoto: "",
          location: LOCATION_INITIAL_VALUES,
        }}
        validationSchema={yup.object().shape({
          title: yup.string().required("Title is required"),
          startDateMonth: yup.string(),
          startDateYear: yup.string(),
          currentlyWorking: yup.boolean(),
          endDateMonth: yup.string(),
          endDateYear: yup.string(),
          studio: yup.string().nullable().required("Studio is required"),
          newStudioName: yup.string().when("studio", {
            is: (studio) => studio === "new",
            then: yup.string().required("Studio name is required"),
            otherwise: yup.string(),
          }),
          newStudioWebsite: yup.string(),
          newStudioDescription: yup.string(),
          newStudioPhoto: yup.string(),
          location: yup.mixed().when("studio", {
            is: (studio) => studio === "new",
            then: yup.object().shape({
              label: yup.string().required("Location is required"),
            }),
            otherwise: yup.object(),
          }),
        })}
        onSubmit={async (values) => {
          const { studio } = values;
          if (studio === "new") {
            saveStudio(values);
          } else {
            // TODO: Update existing studios,
            // Adding new experience type to the studio's styles.
            // And new location to studio's locations.
            saveExperience(values, studio);
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <FormLayout>
              {info && <Banner status="info">{info}</Banner>}
              {error && <Banner status="danger">{error.message}</Banner>}

              <InputStudioAutocomplete values={values} studios={studios} setFieldValue={setFieldValue} isSubmitting={isSubmitting} />

              <Divider />

              <Field
                label="Title"
                name="title"
                component={TextField}
                placeholder="eg. Senior Yoga Instructor"
              />

              <FormLayout.Group>
                <Field
                  label="Start Date (Optional)"
                  name="startDateMonth"
                  options={MONTHS}
                  component={InputSelect}
                />
                <Field
                  label="&nbsp;"
                  name="startDateYear"
                  options={YEARS}
                  component={InputSelect}
                />
              </FormLayout.Group>

              <Field
                Label={{
                  label: "I still work here",
                }}
                name="currentlyWorking"
                component={CheckboxWithLabel}
              />

              {!values.currentlyWorking && (
                <FormLayout.Group>
                  <Field
                    label="End Date (Optional)"
                    name="endDateMonth"
                    options={MONTHS}
                    component={InputSelect}
                  />
                  <Field
                    label="&nbsp;"
                    name="endDateYear"
                    options={YEARS}
                    component={InputSelect}
                  />
                </FormLayout.Group>
              )}

              <FormLayout.Footer>
                {experience && <ConfirmDeleteButton deleteFunction={deleteExperience} isSubmitting={isSubmitting} label="experience" />}
                <Button onClick={closeModals} loading={isSubmitting}>Cancel</Button>
                <Button
                  color="primary"
                  loading={isSubmitting}
                  type="submit"
                >
                  {experience ? "Save Changes" : "Save Experience"}
                </Button>
              </FormLayout.Footer>
            </FormLayout>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default ExperienceDialog;
